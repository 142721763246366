.presentation {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: end;
  gap: ac(128px, 25px);

  margin: 0 auto;

  max-width: 1600px;

  @mixin media 1025 {
    flex-direction: column;
  }
}

.presentation-section__bg-wrap {
  position: absolute;
  top: 15%;
  right: 0;
  z-index: -1;

  width: calc(100% + 187px);

  opacity: 0.5;
  transform: scaleX(-1);
  filter: blur(10px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;

  @mixin media 769 {
    top: unset;
    right: unset;
    left: -70%;
    bottom: -41%;
    width: 100%;
    min-width: 1000px;
    filter: blur(3px);
    transform: scaleX(-1) rotate(-10deg);
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;

    background: linear-gradient(
      to bottom,
      #08090a 0%,
      rgba(8, 9, 10, 0) 29.8%,
      rgba(8, 9, 10, 0) 69.6%,
      #08090a 100%
    );
  }
}

.presentation__blur-logo {
  position: absolute;
  z-index: -1;
  top: 39%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 100%;

  filter: blur(10px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  opacity: 0.25;

  @mixin media 769 {
    filter: blur(4px);
  }

  @mixin media 551 {
    filter: blur(3px);
    z-index: 1;
  }
}

.presentation__text-wrap {
  display: flex;
  flex-direction: column;
  gap: ac(40px, 20px);
}

.presentation__text {
  max-width: ac(667px, 500px);

  font-size: ac(20px, 14px);
  text-align: justify;
  line-height: 1.219;
}

.presentation__button {
  position: relative;
  z-index: 1;
  max-width: ac(578px, 253px);

  background: transparent;
  border: none;

  .presentation__button-img {
    transition: 0.3s ease;
  }

  &:hover {
    .presentation__button-img {
      filter: drop-shadow(12px 12px 120px rgba(42, 248, 149, 0.5));
    }
  }
}

#presentation-popup {
  .popup__content-wrap {
    height: unset;
    aspect-ratio: 16 / 9;

    filter: drop-shadow(12px 12px 120px rgba(42, 248, 149, 0.2));
  }
}

.presentation-popup {
  height: 100%;
}
