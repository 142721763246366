.studets-stats-section__title {
  text-align: center;
}

.studets-stats {
  display: flex;
  align-items: center;
  gap: 20px;

  @mixin media 769 {
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px 48px;
  }
}

.studets-stat {
  position: relative;

  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */

  aspect-ratio: 682 / 543;

  border-radius: ac(20px, 10px, 1024);
  padding: ac(30px, 15px, 1024);

  background: linear-gradient(
    248.33deg,
    #ffffff 25.97%,
    rgba(255, 255, 255, 0) 36.63%,
    rgba(255, 255, 255, 0) 67.82%,
    #ffffff 78.13%
  );

  &::before {
    content: '';
    position: absolute;
    inset: ac(2px, 1px, 1280);

    border-radius: inherit;

    background: linear-gradient(180deg, #26292d 0%, #08090a 100%);
  }
}

.studets-stat__info {
  position: relative;
  z-index: 1;

  display: flex;
  gap: ac(16px, 8px, 1024);

  /* margin-bottom: 20px; */
}

.studets-stat__img {
  width: ac(54px, 32px, 1024);
  height: ac(54px, 32px, 1024);
  border-radius: 50%;
}

.studets-stat__name {
  margin-bottom: ac(4px, 2px, 1024);

  font-size: ac(20px, 14px, 1024);
  font-weight: 500;
  line-height: 1.2;
}

.studets-stat__text-wrap {
  /* min-height: 88px; */
}

.studets-stat__text {
  font-size: ac(12px, 10px, 1024);
  line-height: 1.2;
}

.studets-stat__screen {
  position: relative;
  z-index: 1;

  width: 100%;

  margin: auto 0;
}

.studets-stat__screen-img {
  width: 100%;
  height: 100%;
}

.studets-stats-slider__nav-btn {
  flex-shrink: 0;

  @mixin media 769 {
    /* max-width: 100%; */
    order: 2;
  }
}

.studets-stats-slider {
  flex: 1;
  width: 100%;
  max-width: ac(682px, 450px);

  margin: 0 auto;

  @mixin media 769 {
    max-width: 100%;
  }

  .swiper-slide {
    overflow: visible;

    &.swiper-slide-active {
      .participants-deal {
        display: block;
        box-shadow: 0 0 150px 50px #000000;
      }
    }
  }
}
