.socials-section {
  z-index: 1;
}

.socials-section__bg-line {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -25%);
  z-index: -1;

  display: block;

  width: 100%;
  min-width: 1100px;
  height: 100%;
  object-fit: fill;

  pointer-events: none;

  @mixin media 1025 {
    transform: translate(-50%, -50%);
  }
}

.socials-section__title {
  margin-bottom: ac(48px, 12px);

  text-align: center;
}

.socials {
  --columns: 3;
  --gap: ac(16px, 12px);

  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--gap);
  flex-wrap: wrap;

  @mixin media 1025 {
    --columns: 2;
  }

  @mixin media 769 {
    --columns: 1;
  }
}

.social-card {
  --width: calc((100% - (var(--columns) - 1) * var(--gap)) / var(--columns));

  position: relative;
  overflow: hidden;
  gap: 7px;

  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;

  width: var(--width);
  aspect-ratio: 556 / 138;

  border-radius: 16.61px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  padding: 14px 14px 12px 20px;

  color: var(--cl-white);
  background: rgba(0, 0, 0, 0.5);

  @mixin media 769 {
    max-width: 400px;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;

    background: linear-gradient(
      264.82deg,
      #16171a 0%,
      #212427 64.87%,
      #2d3135 100%
    );

    opacity: 0;

    transition: opacity 0.3s ease-in-out;
  }

  &:hover {
    &::before {
      opacity: 1;
    }

    .social-card__subscribers {
      color: var(--cl-white);
    }

    .social-card__icon {
      filter: brightness(1);
    }
  }
}

.social-card__title-wrap {
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;

  font-weight: 400;
}

.social-card__info {
  position: relative;
  z-index: 1;

  display: flex;
  justify-content: space-between;
  align-items: end;

  @mixin media 1440 {
    flex-shrink: 0;
  }
}

.social-card__subscribers {
  /* flex-shrink: 0; */
  margin-bottom: -2.6em;
  color: rgba(108, 114, 121, 1);

  transition: color 0.3s ease-in-out;

  .count-up {
    display: inline-block;

    font-family: var(--font-title);
    font-size: ac(140px, 86px, 1440);
    font-weight: 400;
    line-height: 1;
  }

  .plus {
    display: inline-block;
    transform: translateY(-0.4em);

    font-size: ac(60px, 37px);
    font-weight: 800;
    line-height: 1.219;
  }
}

.social-card__title {
  max-width: ac(187px, 100px);

  color: rgba(255, 255, 255, 0.5);

  font-size: ac(16px, 12px, 1440);
  line-height: 1.219;
  text-align: right;
}

.social-card__icon {
  transition: filter 0.3s ease-in-out;

  filter: brightness(0) invert(1);

  &.instagram {
    --hover-color: #ff543e;
    /* background: radial-gradient(
        92.18% 99.11% at 26.56% 107.7%,
        #ffdd55 0%,
        #ffdd55 10%,
        #ff543e 50%,
        #c837ab 100%
      ),
      radial-gradient(
        179.06% 43.44% at -16.75% 7.2%,
        #3771c8 0%,
        #3771c8 12.8%,
        rgba(102, 0, 255, 0) 100%
      ); */
  }

  &.telegram {
    --hover-color: #2aabee;
  }

  &.discord {
    --hover-color: #5865f2;
  }

  &.youtube {
    --hover-color: #ef3737;
  }
}
