.input {
  border-radius: ac(12px, 8px);
  padding: ac(20px, 12px);
  border: 1px solid rgba(255, 255, 255, 0.3);

  color: var(--cl-white);
  background: rgba(8, 9, 10, 1);

  font-size: ac(16px, 14px);
  line-height: 1.219;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 10px;

  font-size: ac(16px, 14px);
  line-height: 1.219;

  color: var(--cl-white);

  &.red {
    .checkbox__label {
      a,
      button {
        color: var(--cl-red);
      }
    }
  }
}

.checkbox__input-wrap {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;
  border: 1px solid rgba(255, 255, 255, 0.3);

  .checkbox__input {
    display: none;

    &:checked + .checkbox__check {
      scale: 1;
      opacity: 1;
    }
  }
}

.checkbox__check {
  opacity: 0;
  scale: 0.3;

  transition: all 0.3s ease;
}

.checkbox__label {
  font-size: ac(16px, 14px);

  color: rgba(255, 255, 255, 0.7);

  a,
  button {
    font-size: ac(16px, 14px);
    color: var(--cl-green);

    padding: 0;
    background: transparent;
    border: none;

    text-decoration: underline;
    font-weight: 600;
  }
}
