.btn {
  --border-color: #2edc80;
  --bg-color: #094023;
  --hover-bg: radial-gradient(
    154.23% 100% at 50.15% 100%,
    #094023 0%,
    #188049 59.92%,
    #2fdd81 100%
  );

  &.red {
    --border-color: #b50027;
    --bg-color: #3f000e;
    --hover-bg: radial-gradient(
      118.22% 100% at 50.15% 100%,
      #650016 0%,
      #85001d 60.31%,
      #b60128 100%
    );
  }

  &.purple {
    --border-color: #f375f2;
    --bg-color: #4f114e;
    --hover-bg: radial-gradient(
      118.22% 100% at 50.15% 100%,
      #8f1f7e 0%,
      1 #b01f8b 60.31%,
      #f375f2 100%
    );
  }

  &.teal {
    --border-color: #00ffcb;
    --bg-color: #004e3e;
    --hover-bg: radial-gradient(
      118.22% 100% at 50.15% 100%,
      #004e3e 0%,
      #00765e 60.28%,
      #00b28d 100%
    );
  }

  &.w-full {
    width: 100%;
  }

  &.no-border {
    width: fit-content;

    background: radial-gradient(
      100% 350.07% at 100% 50%,
      #07331c 0%,
      #041b0f 100%
    );

    border: none;

    &::after {
      inset: 0;

      background: #094023;

      border: none;
    }

    &.red {
      background: radial-gradient(
        100% 722.87% at 100% 50%,
        #3f000e 0%,
        #24040b 100%
      );

      &::after {
        background: #650016;
      }
    }
  }

  &.is-loading {
    pointer-events: none;
    cursor: not-allowed;

    .btn__loader {
      width: 20px;
      padding: 3px;
      aspect-ratio: 1;
      border-radius: 50%;
      background: #ffffff;
      --mask: conic-gradient(#0000 10%, #000),
        linear-gradient(#000 0 0) content-box;
      -webkit-mask: var(--mask);
      mask: var(--mask);
      -webkit-mask-composite: source-out;
      mask-composite: subtract;
      animation: l3 1s infinite linear;
    }

    .btn__text {
      display: none;
    }
  }

  &.sm {
    padding: ac(14px, 8px) 25px;
    border-radius: ac(8px, 6px);

    font-size: ac(16px, 12px);

    &::before {
      inset: 1px;
    }
  }

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  width: fit-content;
  min-width: ac(200px, 150px);

  padding: ac(16px, 14px) 32px;
  border: none;
  border-radius: ac(12px, 8px);

  background: var(--bg-color);
  border: 2px solid var(--border-color);

  color: var(--cl-white);

  font-size: ac(16px, 12px);
  font-weight: 600;
  line-height: 1.219;
  text-transform: uppercase;

  transition: 0.5s ease;

  @mixin media 551 {
    width: 100%;
  }

  &:not(:disabled):hover {
    &::after {
      opacity: 1;
    }
  }

  &::after {
    content: '';
    position: absolute;
    inset: -2px;

    border-radius: inherit;
    background: var(--hover-bg);

    opacity: 0;

    transition: 0.5s ease;
  }

  &:disabled {
    opacity: 0.5;
    filter: grayscale(1);
    cursor: not-allowed;
  }
}

.btn__text {
  position: relative;
  z-index: 2;

  font-weight: 600;
}

.arrow-btn {
  position: relative;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  width: ac(72px, 48px, 1024);
  aspect-ratio: 1;

  border-radius: 999px;
  border: none;

  background: linear-gradient(
      -270deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 94.82%
    ),
    linear-gradient(-270deg, #ffffff 0%, rgba(255, 255, 255, 0) 94.82%);

  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 1;
    opacity: 0;

    border-radius: 999px;

    background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 94.82%
      ),
      linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 94.82%);

    transition: 0.5s ease;
  }

  &::after {
    content: '';
    position: absolute;
    inset: 1px;
    z-index: 1;

    border-radius: 999px;
    background: linear-gradient(-264.82deg, #16171a 0%, #2d3135 100%);
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }

  &.revert {
    background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 94.82%
      ),
      linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 94.82%);

    &::before {
      background: linear-gradient(
          -270deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0) 94.82%
        ),
        linear-gradient(-270deg, #ffffff 0%, rgba(255, 255, 255, 0) 94.82%);
    }
    &::after {
      background: linear-gradient(-264.82deg, #16171a 0%, #2d3135 100%);
    }

    .arrow-btn__dot {
      right: unset;
      left: -20%;
      transform: translate(50%, -50%);
    }

    .arrow-btn__icon {
      transform: translateX(10%);
    }

    &:hover {
      .arrow-btn__icon {
        transform: translateX(5px);
      }

      .arrow-btn__dot {
        left: calc(50% - 15px);
      }
    }
  }

  .arrow-btn__icon {
    transform: translateX(-10%);
  }

  &:hover {
    .arrow-btn__icon {
      transform: translateX(-5px);
    }

    .arrow-btn__dot {
      right: calc(50% - 15px);
    }
  }
}

.arrow-btn__icon {
  position: relative;
  z-index: 2;
  color: var(--cl-white);

  width: ac(28px, 18px);

  transition: 0.5s ease;
}

.arrow-btn__dot {
  position: absolute;
  right: -20%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--cl-white);

  transition: 0.5s ease;
}

.circle-btn {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: ac(96px, 54px);
  aspect-ratio: 1;

  border-radius: 999px;

  border: none;

  background: linear-gradient(270deg, #ff494b 0%, rgba(255, 73, 75, 0) 100%);

  transition: 0.5s ease;

  &:disabled {
    opacity: 0.5 !important;
    cursor: not-allowed;
  }

  &.is-loading {
    cursor: not-allowed;
    pointer-events: none;

    .circle-btn__loader {
      display: block;
    }

    .circle-btn__icon {
      display: none;
    }
  }

  &.purple {
    background: linear-gradient(
      270deg,
      #f475f3 0%,
      rgba(244, 117, 243, 0) 100%
    );

    .circle-btn__hover-bg {
      background: linear-gradient(-264.82deg, #f475f3 0%, #f475f3 100%);
    }
  }

  &.white {
    background: linear-gradient(
      270deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    );

    .circle-btn__hover-bg {
      background: linear-gradient(-264.82deg, #ffffff 0%, #ffffff 100%);
    }
  }

  &:not(:disabled):hover {
    scale: 1.08;

    .circle-btn__hover-bg {
      opacity: 1;
    }
  }

  &::after {
    content: '';
    position: absolute;
    inset: 2px;
    z-index: 1;

    border-radius: 999px;
    background: linear-gradient(-264.82deg, #16171a 0%, #2d3135 100%);
  }
}

.circle-btn__hover-bg {
  position: absolute;
  inset: 1px;
  border-radius: 999px;
  z-index: 2;
  opacity: 0;

  border: 2px solid rgba(255, 73, 75, 1);
  background: linear-gradient(225deg, #16171a 0%, #7d2425 100%);

  transition: 0.5s ease;
}

.circle-btn__loader {
  display: none;

  position: relative;
  z-index: 2;
  width: 20px;
  padding: 3px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #ffffff;
  --mask: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--mask);
  mask: var(--mask);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

.circle-btn__icon {
  position: relative;
  z-index: 3;
  color: var(--cl-white);

  transition: 0.5s ease;

  width: ac(37px, 15px);
}

.simple-btn {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 8px;
  padding: 12px;
  background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 94.82%
    ),
    linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 94.82%);

  transition: 0.3s ease;

  &:not(:disabled):hover {
    &::after {
      opacity: 1;
    }
  }

  &:disabled {
    opacity: 0.5;
    filter: grayscale(1);
    cursor: not-allowed;
  }

  @mixin media 769 {
    width: 100%;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 1px;
    z-index: 1;

    border-radius: 8px;
    background: linear-gradient(264.82deg, #16171a 0%, #2d3135 100%);
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;

    border-radius: 8px;
    background: #ffffff;

    opacity: 0;

    transition: 0.3s ease;
  }

  &.is-loading {
    pointer-events: none;
    cursor: not-allowed;

    .simple-btn__loader {
      position: relative;
      z-index: 2;
      width: 20px;
      padding: 3px;
      aspect-ratio: 1;
      border-radius: 50%;
      background: #ffffff;
      --mask: conic-gradient(#0000 10%, #000),
        linear-gradient(#000 0 0) content-box;
      -webkit-mask: var(--mask);
      mask: var(--mask);
      -webkit-mask-composite: source-out;
      mask-composite: subtract;
      animation: l3 1s infinite linear;
    }

    .simple-btn__text {
      display: none;
    }
  }
}

.simple-btn__text {
  position: relative;
  z-index: 2;
  font-weight: 600;

  img {
    width: 40px;
    height: 40px;

    @mixin media 769 {
      width: 25px;
      height: 25px;
    }
  }
}

@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
