.live-trading-section__left-bg {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: -1;

  max-width: ac(624px, 278px);

  @mixin media 1025 {
    left: -57px;
  }

  @mixin media 769 {
    top: 30%;
    /* transform: none; */
  }
}
.live-trading-section__right-bg {
  position: absolute;
  top: -60%;
  right: 0;
  z-index: -1;

  max-width: ac(957px, 350px);

  opacity: 0.6;
  mix-blend-mode: lighten;

  @mixin media 769 {
    top: -35%;
    right: -30px;
  }
}

.live-trading__title {
  margin-bottom: 78px;
}

.live-trading {
  display: grid;
  grid-template-columns: 388fr 769fr 388fr;
  place-items: center;
  gap: ac(30px, 10px);

  @mixin media 1441 {
    grid-template-columns: 1fr 2fr 1fr;
  }

  @mixin media 1281 {
    grid-template-columns: 2fr 1fr;
  }

  @mixin media 1025 {
    grid-template-columns: 1fr 1fr;
  }

  @mixin media 769 {
    max-width: 500px;
    margin: 0 auto;
    gap: 30px;
    grid-template-columns: 1fr;
  }
}

.live-trading__video-wrap {
  position: relative;

  grid-column: 2 / 3;
  aspect-ratio: 769 / 546;

  padding: ac(13px, 8px) ac(27px, 13px);
  border-radius: ac(20px, 6px);

  background: linear-gradient(
    56deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 59.75%,
    rgba(255, 255, 255, 0) 75.3%,
    #ffffff 100%
  );

  &::before {
    content: ' ';
    position: absolute;
    z-index: 1;
    inset: 3px;
    border-radius: ac(20px, 6px);

    background: #000;
  }

  @mixin media 1281 {
    grid-column: unset;
    aspect-ratio: 68 / 45;
  }
}

.live-trading__video-cover {
  position: relative;
  z-index: 1;

  border-radius: ac(20px, 6px);
}

.live-trading__info-wrap {
  display: flex;
  flex-direction: column;
  gap: ac(60px, 20px);

  @mixin media 769 {
    width: 100%;
  }
}

.live-trading__info-item-title {
  margin-bottom: ac(12px, 8px);

  color: #00ffcb;

  font-size: ac(18px, 16px);
  line-height: 1.22;
}

.live-trading__info-item-text {
  font-size: ac(16px, 14px);
  line-height: 1.22;
}

.live-trading__btn {
  margin: ac(80px, 40px) auto 0;

  @mixin media 769 {
    .btn__text {
      font-size: 20px;
    }
  }
}

.live-trading__video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  &:hover {
    transform: translate(-50%, -50%) scale(1.08);
  }
}
