.participants-deals {
  display: flex;
  align-items: center;
  gap: 20px;

  @mixin media 769 {
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px 48px;
  }
}

.participants-deals-slider__nav-btn {
  flex-shrink: 0;

  @mixin media 769 {
    /* max-width: 100%; */
    order: 2;
  }
}

.participants-deals__title {
  position: relative;
  z-index: 1;

  margin-bottom: ac(80px, 12px);

  text-align: center;
}

.participants-deals-slider {
  flex: 1;
  width: 100%;
  max-width: ac(1000px, 550px);

  margin: 0 auto;

  @mixin media 769 {
    max-width: 100%;
  }

  .swiper-slide {
    overflow: visible;

    &.swiper-slide-active {
      .participants-deal {
        display: block;
        box-shadow: 0 0 150px 50px #000000;
      }
    }
  }
}

.participants-deal {
  width: 100%;
  height: 100%;

  transition: all ease 0.6s;
}
