.scalping-section {
  backdrop-filter: blur(75px);
  -webkit-backdrop-filter: blur(75px) !important;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

.scalping__title {
  margin-bottom: 30px;

  text-align: center;
}

.scalping__text {
  max-width: 1412px;

  margin: 0 auto ac(95px, 30px);

  color: rgba(255, 255, 255, 0.7);

  font-size: ac(16px, 14px);
  line-height: 1.219;
  text-align: center;

  @mixin media 501 {
    text-align: justify;
  }
}

.scalping__video-wrap {
  position: relative;

  width: 100%;
  max-width: 1477px;

  aspect-ratio: 3125 / 1400;

  margin: 0 auto 50px;

  @mixin media 551 {
    aspect-ratio: 3125 / 2500;
  }
}

.scalping__video-pc {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 100%;
  aspect-ratio: 1477 / 787; */
  /* width: fit-content; */
  max-width: unset;
  max-height: 100%;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 3224 / 983;

  @mixin media 551 {
    top: 59%;
  }
}

.scalping__play-btn {
  position: absolute;
  left: 50%;
  top: 20.5%;
  transform: translateX(-50%);

  /* width: ; */

  @mixin media 551 {
    top: 28%;
  }
}

.scalping__more-btn {
  margin: 0 auto;
}

.section-wrap {
  position: relative;
  overflow: hidden;
  clip-path: inset(3px 0px 2px 0px);
}

.glow-svg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  line,
  circle {
    stroke: #c54a4c;
    fill: #c54a4c;
    stroke-width: 200;
    stroke-linecap: round;
  }

  @media (pointer: coarse) {
    display: none;
  }
}

.glow {
  position: absolute;
  z-index: -1;

  display: inline-block;

  color: #fff;
  font-size: 20px;
  line-height: 1.219;
  border-radius: 50%;

  transition: opacity 0.4s 0.2s, scale 0.4s 0.2s;
}

.glow-sm {
  width: 150px;
  height: 150px;

  background: linear-gradient(90deg, #ff8082 0%, #c54a4c 100%);

  /* transition-delay: 0.025s; */
}

.glow-xl {
  width: 300px;
  height: 300px;

  background: linear-gradient(90deg, #ff494b 0%, #b5191b 100%);
}

.scalping-videos-slider__wrap {
  display: flex;
  align-items: center;
  gap: ac(20px, 10px);

  height: 100%;

  @mixin media 769 {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px 48px;
  }
}

.scalping-videos-slider {
  overflow: hidden;

  width: 100%;
  height: 100%;

  @mixin media 769 {
    order: -1;
    height: auto;
    aspect-ratio: 5 / 4;
  }
}

.scalping-videos-slider__nav-btn {
  flex-shrink: 0;
}

.scalping__video-wrap-text {
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, 0);

  color: #fd494b;

  font-size: ac(24px, 10px);
  text-align: center;
  line-height: 1;
  text-transform: uppercase;

  @mixin media 550 {
    top: 67%;

    width: 100%;
  }

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: calc(100% + ac(16px, 6px));
    transform: translateX(-50%);
    z-index: 1;

    display: block;
    width: ac(24px, 14px);
    height: ac(81px, 30px);

    background: url('../../assets/images/fade-arrow.svg') no-repeat center;
    background-size: contain;

    @mixin media 769 {
      display: none;
    }
  }
}
