.instruments-section__title {
  text-align: center;

  @mixin media 551 {
    margin-bottom: 12px;
  }
}

.instruments {
  display: grid;
  grid-template-columns: 1fr 1.2fr 1fr;
  gap: ac(30px, 12px);

  @mixin media 1025 {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .instrument-card {
    &:nth-child(5n + 1) {
      grid-column: 2;
      grid-row: 1 / span 2;

      aspect-ratio: unset;

      @mixin media 1025 {
        grid-column: 1;
        grid-row: unset;

        min-height: 426px;
      }

      .instrument-card__inner-wrap {
        background: #000;
      }

      .instrument-card__bg-wrap {
        mask-image: none;

        &::before {
          content: '';
          position: absolute;
          inset: 0;
          background: linear-gradient(
            180deg,
            #08090a 0%,
            rgba(8, 9, 10, 0.765529) 19.65%,
            rgba(8, 9, 10, 0) 42.65%,
            rgba(8, 9, 10, 0) 69.94%,
            #08090a 100%
          );
        }
      }

      .instrument-card__num {
        position: absolute;
        top: 12px;
        left: 12px;
      }

      .instrument-card__title {
        margin-top: 0;
        text-align: center;
      }

      .instrument-card__descr {
        text-align: center;
        min-height: unset;
      }

      /* @mixin media 451 {
        .instrument-card__bg {
          min-width: 760px;
          height: auto;
          object-position: -205px -30px;
        }
      } */
    }
    &:nth-child(5n + 2) {
      grid-column: 1 / 2;
      grid-row: span 1;

      background: linear-gradient(
        300deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 59.75%,
        rgba(255, 255, 255, 0) 75.3%,
        #ffffff 100%
      );

      @mixin media 1025 {
        grid-column: 1;
        grid-row: unset;
      }
    }
    &:nth-child(5n + 3) {
      grid-column: 1 / 2;
      grid-row: span 1;

      background: linear-gradient(
        62.68deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 59.75%,
        rgba(255, 255, 255, 0) 75.3%,
        #ffffff 100%
      );

      @mixin media 1025 {
        grid-column: 1;
        grid-row: unset;
      }

      @mixin media 451 {
        .instrument-card__bg {
          object-position: 90px -40px;
          object-fit: contain;
          height: auto;
          max-width: unset;
          min-width: 380px;
        }
      }
    }
    &:nth-child(5n + 4) {
      grid-column: 3 / 3;
      grid-row: 1;

      background: linear-gradient(
        240deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 59.75%,
        rgba(255, 255, 255, 0) 75.3%,
        #ffffff 100%
      );

      @mixin media 1025 {
        grid-column: 1;
        grid-row: unset;
      }
    }
    &:nth-child(5n + 5) {
      grid-column: 3 / 3;
      grid-row: span 1;

      background: linear-gradient(
        140deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 59.75%,
        rgba(255, 255, 255, 0) 75.3%,
        #ffffff 100%
      );

      @mixin media 1025 {
        grid-column: 1;
        grid-row: unset;
      }

      @mixin media 451 {
        .instrument-card__bg {
          object-position: 50px -65px;
          object-fit: contain;
          max-width: unset;
          min-width: 440px;
          height: auto;
        }
      }
    }
  }
}

.instrument-card {
  position: relative;

  aspect-ratio: 512 / 368;

  padding: ac(20px, 12px);
  border-radius: 12px;

  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 59.75%,
    rgba(255, 255, 255, 0) 75.3%,
    #ffffff 100%
  );

  &::before {
    content: '';
    position: absolute;
    inset: 1px;

    border-radius: 12px;

    background: #000;
  }

  @mixin media 1281 {
    aspect-ratio: 512 / 512;
  }

  @mixin media 1025 {
    aspect-ratio: unset;

    width: 100%;
    max-width: 600px;
    min-height: 258px;
  }
}

.instrument-card__inner-wrap {
  position: relative;
  overflow: hidden;

  width: 100%;
  height: 100%;

  border-radius: 12px;

  background: rgba(255, 255, 255, 0.1);

  @mixin media 769 {
    background: rgba(255, 255, 255, 0.05);
  }
}

.instrument-card__bg-wrap {
  position: absolute;
  inset: 0;

  width: 100%;
  height: 100%;

  mask-image: radial-gradient(
    circle at 60% 20%,
    black 0%,
    transparent 65%,
    transparent 100%
  );
}

.instrument-card__bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.instrument-card__content {
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  padding: 12px 12px 20px;
}

.instrument-card__num {
  width: fit-content;

  padding: ac(8px, 4px) ac(12px, 6px);
  border-radius: 8px;
  border: 1px solid #ffffff33;
  color: #ffffff99;

  font-size: ac(16px, 12px);
  line-height: 1.2;
}

.instrument-card__title {
  margin-top: auto;
  margin-bottom: 12px;

  font-family: var(--font-title);
  font-size: ac(48px, 32px);
  font-weight: 400;
  line-height: 1;
  /* text-align: center; */
}

.instrument-card__descr {
  min-height: 5em;

  font-size: ac(16px, 14px);
  line-height: 1.2;

  color: #ffffffb2;

  @mixin media 1025 {
    min-height: unset;
  }
}
