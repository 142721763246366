.section-about-us {
  padding-top: ac(225px, 115px);
  padding-bottom: ac(355px, 100px);

  &::before {
    content: '';
    position: absolute;
    bottom: -225px;
    left: 0;
    right: 0;
    z-index: -1;

    aspect-ratio: 1920 / 671;
    min-width: 926px;

    background: url('../../assets/images/wave-bg.webp') no-repeat center center;
    background-size: cover;

    @mixin min-media 1920 {
      bottom: -350px;
    }

    @mixin media 769 {
      left: -150px;
    }
  }
}

.about-us {
  display: flex;
  justify-content: space-between;
  gap: ac(100px, 23px);

  @mixin media 1024 {
    flex-direction: column;
    gap: ac(50px, 23px);
  }
}

.about-us__content {
  max-width: 805px;
}

.about-us__text {
  color: rgba(255, 255, 255, 0.7);

  font-size: ac(16px, 14px);
  line-height: 1.219;
  text-align: justify;
}

.about-us__counters {
  display: flex;
  flex-direction: column;
  gap: ac(30px, 12px);

  width: 50%;
  max-width: 570px;

  @mixin media 1025 {
    width: 100%;
    max-width: 500px;
  }
}

.about-us__counter {
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    padding-bottom: ac(30px, 12px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
}

.about-us__counter-content {
  min-width: ac(523px, 300px);
}

.about-us__counter-value {
  margin-bottom: 8px;

  font-family: var(--font-title);
  font-size: ac(96px, 48px, 1280);
  font-weight: 400;
  line-height: 1;

  .count-up {
    font-family: var(--font-title);
    font-size: ac(96px, 48px, 1280);
    font-weight: 400;
    line-height: 1;
  }

  .plus {
    font-family: var(--font-main);
    font-weight: 600;
    font-size: ac(80px, 48px, 1280);
    line-height: 96px;
  }
}

.about-us__counter-text {
  color: rgba(255, 255, 255, 0.7);

  font-size: ac(16px, 14px);
  line-height: 1.219;
}

.about-us__counter-num {
  color: rgba(255, 255, 255, 0.2);

  font-size: ac(16px, 14px);
  line-height: 1.219;
  letter-spacing: 3px;
}
