.advantages-section___title {
  text-align: center;

  @mixin media 551 {
    margin-bottom: 12px;
  }
}

.advantages__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ac(30px, 12px);

  @mixin media 1281 {
    grid-template-columns: repeat(2, 1fr);
  }

  @mixin media 769 {
    grid-template-columns: 1fr;
  }
}

.advantages__item {
  padding: ac(40px, 16px) ac(40px, 16px) ac(69px, 24px);
  border-radius: 12px;
  border: 1px solid #ffffff4d;

  background-color: rgba(0, 0, 0, 0.5);
  background-size: cover;
  background-position: center center;

  background-image: url(../../assets/images/adv-bg-1.webp);

  &:nth-child(2) {
    background-image: url(../../assets/images/adv-bg-2.webp);
  }
  &:nth-child(3) {
    background-image: url(../../assets/images/adv-bg-3.webp);
  }
  &:nth-child(4) {
    background-image: url(../../assets/images/adv-bg-4.webp);
  }
  &:nth-child(5) {
    background-image: url(../../assets/images/adv-bg-5.webp);
  }
  &:nth-child(6) {
    background-image: url(../../assets/images/adv-bg-6.webp);
  }

  @mixin media 769 {
    max-width: 400px;

    margin: 0 auto;
  }
}

.advantages__item-icon {
  display: block;

  width: ac(177px, 101px);
  height: ac(170px, 89px);
  object-fit: contain;

  margin: 0 auto;
}

.advantages__item-text {
  margin-top: ac(40px, 16px);
  padding-top: ac(30px, 16px);
  border-top: 1px solid #ffffff4d;
}

.advantages__item-title {
  margin-bottom: ac(20px, 8px);

  font-family: var(--font-title);
  font-size: ac(37px, 32px);
  line-height: 1;
  text-align: center;
}

.advantages__item-desc {
  color: #ffffffb2;

  font-size: ac(18px, 14px);
  line-height: 1.219;
  text-align: center;
  text-wrap: balance;
}
