.landing-hero-section {
  padding-top: 190px;
}

.landing-hero__content-wrap {
  display: grid;
  grid-template-columns: 1fr 0.61fr;
}

.landing-hero__text-info {
  padding-top: 100px;
}

.landing-hero__info-blocks {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(3, 1fr);
  gap: 41px;

  margin-bottom: 67px;
}

.landing-hero__descr {
  max-width: 868px;

  margin-bottom: 80px;

  color: rgba(255, 255, 255, 0.7);

  font-size: 24px;
  line-height: 1.2;
}

.landing-info-block {
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 20px 20px 0 0;
  padding: 26px 0;

  background: linear-gradient(180deg, #01ffcb 0%, rgba(1, 255, 203, 0) 85%);

  &::before {
    content: '';
    position: absolute;
    inset: 2.6px;

    border-radius: 18px 18px 0 0;
    background: linear-gradient(180deg, #15352e 0%, #08090a 85%);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 74%;
    height: 70px;
    filter: blur(40px);
    border-radius: 100%;
    background: #01ffcb4d;
  }
}

.landing-info-block__title {
  position: relative;
  z-index: 1;

  font-size: 23px;
  font-weight: 500;
  text-transform: uppercase;

  color: rgba(255, 255, 255, 0.7);
}

.landing-info-block__info {
  position: relative;
  z-index: 1;

  font-size: 31px;
  font-weight: 600;
}

.landing-hero__title {
  margin-bottom: 24px;

  font-size: 72px;
  line-height: 1;
  font-family: var(--font-title);

  .highlight {
    color: #01ffcb;
  }
}

.landing-hero__form-img-wrap {
  position: relative;

  margin-bottom: -125px;

  .hand {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;

    width: 22%;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);

    width: 50%;
    height: 50%;

    background: #01ffcb4d;
    filter: blur(100px);
  }
}

.landing-hero__form {
  position: relative;
  z-index: 1;
  right: 70px;

  padding: 40px 30px;
  border-radius: 30px;
  border: 2px solid #ffffff33;

  background: #212223;
}

.landing-hero__form-arrow {
  position: absolute;
  top: 15%;
  right: 92%;
}

.landing-hero__form-title {
  margin-bottom: 12px;

  font-size: 24px;
  text-align: center;

  .highlight {
    color: #ff61fb;

    font-weight: 600;
  }
}

.landing-hero__form-accent-text {
  margin-bottom: 30px;

  font-size: 36px;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
}

.landing-hero__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 23px 30px;

  max-width: 688px;
}

.landing-hero__list-item {
  display: flex;
  align-items: center;
  gap: 12px;

  font-size: 24px;
  font-weight: 500;
  line-height: 1;

  &::before {
    content: '';
    display: inline-block;

    width: 32px;
    height: 32px;

    background: url('../../assets/images/landing-hero-check.png') no-repeat;
  }
}
