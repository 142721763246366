.our-partners {
  position: relative;

  padding: ac(50px, 20px) ac(70px, 30px);
  border-radius: ac(24px, 12px);

  background: linear-gradient(0deg, #272a2e 0%, rgba(39, 42, 46, 0) 100%);

  &::before {
    content: '';
    position: absolute;
    inset: 1px;
    z-index: 1;

    display: block;

    border-radius: inherit;

    background: linear-gradient(
      180deg,
      #1e2024 0%,
      #15171a 49.83%,
      #0e1011 100%
    );
  }
}

.our-partners__title {
  position: relative;
  z-index: 1;

  margin-bottom: ac(20px, 10px);

  color: rgba(255, 255, 255, 0.7);

  font-size: ac(42px, 24px);
  letter-spacing: 2%;
  text-align: center;
}

.our-partners__list {
  position: relative;
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;

  width: 100%;

  margin-top: auto;

  @mixin media 1440 {
    gap: 12px ac(8px, 4px, 769, 1280);
  }
}

.our-partners__item {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: ac(12px, 6px);

  background: linear-gradient(
    150deg,
    #ffffff 26.09%,
    rgba(255, 255, 255, 0) 44.42%,
    rgba(255, 255, 255, 0) 56.94%,
    #ffffff 72.14%
  );

  /* width: ac(237px, 111px); */
  flex: 1;
  min-width: 111px;
  height: ac(71px, 36px);

  @mixin media 1280 {
    padding: 9px 24px;
  }

  @mixin media 551 {
    padding: 9px 12px;

    &.okx {
      img {
        max-height: 12px;
      }
    }
  }

  &:nth-child(n + 4) {
    background: linear-gradient(
      -150deg,
      #ffffff 26.09%,
      rgba(255, 255, 255, 0) 44.42%,
      rgba(255, 255, 255, 0) 56.94%,
      #ffffff 72.14%
    );
  }

  &::before {
    content: '';
    position: absolute;
    inset: 1px;

    display: block;
    border-radius: ac(12px, 6px);

    background: rgba(29, 32, 35, 1);
  }

  img {
    position: relative;
    z-index: 1;

    @mixin media 1440 {
      max-width: 80%;
    }

    @mixin media 1280 {
      width: 100%;
      max-width: 100%;

      max-height: ac(30px, 20px, 769, 1280);
    }

    @mixin media 551 {
      max-height: 100%;
    }
  }
}
