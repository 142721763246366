.tariffs-section__title {
  margin-bottom: 109px;

  text-align: center;

  @mixin media 551 {
    margin-bottom: 75px;
  }
}

.tariffs-section__bg {
  position: absolute;
  top: -40%;
  left: 0;
  pointer-events: none;

  mix-blend-mode: lighten;
  opacity: 0.6;

  @mixin media 901 {
    top: -10%;
  }

  @mixin media 551 {
    top: -7%;
  }
}

.tariffs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ac(30px, 24px);

  @mixin media 901 {
    grid-template-columns: 1fr;
    gap: 90px;
  }
}

.tariff {
  position: relative;

  padding: 0 ac(12px, 6px, 1024) ac(12px, 6px, 1024);
  border-radius: ac(12px, 8px);

  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(153, 153, 153, 0) 17.29%,
    #ffffff 51.97%
  );

  @mixin media 901 {
    width: 100%;
    max-width: 547px;
    margin: 0 auto;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 1px;
    border-radius: ac(12px, 8px);
    background: rgba(0, 0, 0, 1);
  }
}

.tariff__title,
.tariff__descr,
.tariff__price,
.tariff__program,
.tariff__icon,
.tariff__loan-info {
  position: relative;
  z-index: 1;
}

.tariff__decor {
  position: absolute;
  top: 0;
  left: 0;

  border-radius: ac(24px, 16px);

  width: 100%;
}

.tariff__icon {
  display: block;

  width: ac(215px, 160px, 1024);

  margin: ac(-79px, -50px) auto ac(16px, 8px, 1024);
}

.tariff__title {
  margin-bottom: ac(38px, 8px, 1024);

  font-family: var(--font-title);
  font-size: ac(96px, 60px, 1024);
  line-height: 1;
  text-align: center;
}

.tariff__descr {
  min-height: 6rem;

  margin-bottom: ac(42px, 20px, 1024);

  font-size: ac(16px, 14px, 1024);
  text-align: center;

  color: rgba(255, 255, 255, 0.7);
}

.tariff__price {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ac(10px, 6px, 1024);

  min-width: ac(226px, 140px, 1024);
  width: fit-content;

  margin: 0 auto ac(20px, 15px, 1024);

  background: rgba(255, 255, 255, 0.12);
  border-radius: 12px;
  padding: ac(12px, 8px, 1024) ac(25px, 15px, 1024);
}

.tariff__price-value {
  font-family: var(--font-title);
  font-size: ac(80px, 50px, 1024);
  line-height: 1;
}

.tariff__price-currency {
  color: #2af895;
  font-family: var(--font-title);
  font-size: ac(40px, 24px, 1024);
  line-height: 1;
}

.tariff__additional-text {
  position: relative;
  z-index: 1;

  color: #ffffff80;
  margin-bottom: ac(15px, 8px);

  font-size: ac(14px, 12px, 1024);
  text-align: center;
  font-style: italic;
  line-height: 1.219;
}

.tariff__program-title {
  margin-bottom: ac(20px, 10px, 1024);

  font-size: ac(20px, 14px, 1024);
  font-weight: 600;
  line-height: 1.219;
}

.tariff__program-list {
  display: flex;
  flex-direction: column;
  gap: ac(20px, 10px, 1024);

  margin-bottom: ac(40px, 24px, 1024);
}

.tariff__program-item {
  &.disabled {
    opacity: 0.6;

    .tariff__program-item-main {
      &::before {
        background: url('../../assets/images/close.svg') center no-repeat;
        background-size: contain;
      }
    }
  }
}

.tariff__program-item-main {
  display: flex;
  align-items: center;

  font-size: ac(16px, 14px, 1024);
  line-height: 1.219;

  &::before {
    content: '';
    position: relative;
    top: 0.2em;

    display: block;
    flex-shrink: 0;
    align-self: start;

    width: ac(17px, 13px, 1024);
    height: ac(17px, 13px, 1024);

    margin-right: ac(10px, 7px);

    background: url('../../assets/images/check.svg') center no-repeat;
    background-size: contain;
  }

  &:not(:last-child) {
    margin-bottom: ac(20px, 10px, 1024);
  }
}

.tariff__program-item-main-icon {
  margin-right: 3px;
}

.tariff__program-item-additional {
  color: #2af895;

  font-size: ac(16px, 14px, 1024);
}

.tariff__loan-info {
  margin-top: ac(12px, 8px, 1024);

  color: #ffffffb2;

  font-size: ac(16px, 12px, 1024);
  line-height: 1.219;
  font-style: italic;
  text-align: center;
}

.tariff__additional-info {
  position: relative;
  z-index: 1;

  display: flex;
  justify-content: space-between;
  gap: ac(20px, 10px, 1024);

  margin-top: ac(25px, 10px, 1024);
}

.tariff__additional-info-item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0 8px;

  font-size: ac(16px, 14px, 1440);
  line-height: 1.219;

  &:first-child:last-child {
    width: 100%;
    justify-content: center;
  }
}

.tariff__additional-info-item-title {
  color: rgba(255, 255, 255, 0.7);
}

.tariff__additional-info-item-text {
  span {
    font-weight: 600;
  }
}

.payment-info__wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ac(30px, 24px);

  margin-top: ac(30px, 24px);

  @mixin media 1281 {
    grid-template-columns: 1fr;
  }
}

.payment-info {
  display: flex;
  align-items: center;
  gap: 15px ac(78px, 24px, 1440);

  font-size: ac(16px, 14px, 1024);
  line-height: 1.219;

  grid-column: 2 / span 2;

  padding: ac(35px, 10px, 1024) ac(45px, 10px, 1024) ac(35px, 10px, 1024)
    ac(35px, 10px, 1024);
  border: 1px solid var(--cl-white);
  border-radius: ac(24px, 16px);

  @mixin media 1281 {
    grid-column: 1;
  }

  @mixin media 701 {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.payment-info__title {
  margin-right: auto;

  color: rgba(255, 255, 255, 0.7);

  font-size: ac(20px, 14px, 1024);

  strong {
    font-weight: 600;
    color: var(--cl-white);
  }

  @mixin media 701 {
    width: 100%;
    font-size: 18px;
    text-align: center;
  }
}

.payment-info__item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.payment-info__item-title {
  color: rgba(255, 255, 255, 0.7);

  font-size: 16px;
  font-style: italic;
}

.payment-info__item-logo {
  width: ac(248px, 150px);
  height: ac(37px, 27px);
  object-fit: contain;
}

.tariff__bf-label {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(15%, -15%);
  z-index: 2;

  width: ac(222px, 150px, 1024);
  height: ac(222px, 150px, 1024);

  @mixin media 901 {
    width: 222px;
    height: 222px;
  }

  @mixin media 501 {
    width: 170px;
    height: 170px;
  }
}

.tariff__old-price {
  font-family: var(--font-title);
  font-size: ac(40px, 24px, 1024);
  text-decoration: line-through;

  color: rgba(255, 255, 255, 0.5);
}

.tariff__corner-asset {
  position: absolute;
  top: 1px;
  right: 1px;
  z-index: 1;
  pointer-events: none;

  width: 33%;
  overflow: hidden;

  border-radius: 0 12px 0 0;
}
