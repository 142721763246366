.blog-section {
  overflow: visible;
}

.blog-section__bg-wrap {
  position: absolute;
  z-index: -1;
  top: 32%;
  left: 31%;
  width: 170%;
  transform: translate(-50%, -50%);
  aspect-ratio: 3264 / 2307;

  mask-image: linear-gradient(
    to bottom,
    transparent 0%,
    black 30%,
    black 100%,
    transparent 100%
  );
  clip-path: inset(0 0 3px 0);
}

.blog-section__title {
  text-align: center;
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* grid-template-rows: repeat(2, 1fr); */

  gap: ac(40px, 12px, 1440);

  @mixin media 1440 {
    grid-template-columns: repeat(3, 1fr);
  }

  @mixin media 1024 {
    grid-template-columns: repeat(2, 1fr);
  }

  @mixin media 768 {
    grid-template-columns: 1fr;
  }

  .blog-card {
    &.hidden {
      display: none;
    }

    @mixin min-media 1440 {
      &:nth-child(6n + 1),
      &:nth-child(6n + 6) {
        grid-column: span 2;
        grid-row: span 1;
      }

      &:nth-child(6n + 2),
      &:nth-child(6n + 3),
      &:nth-child(6n + 4),
      &:nth-child(6n + 5) {
        grid-column: span 1;
        grid-row: span 2;

        min-height: ac(544px, 200px, 1650);

        .blog-card__main-info {
          flex-direction: column;
          gap: 16px;
        }

        .blog-card__preview-wrap {
          aspect-ratio: 355 / 198;
        }

        .blog-card__date {
          margin-top: auto;
        }
      }
    }

    @mixin media 1440 {
      @mixin min-media 768 {
        .blog-card__main-info {
          flex-direction: column;
          gap: 16px;
        }

        .blog-card__preview-wrap {
          aspect-ratio: 355 / 198;
        }

        .blog-card__date {
          margin-top: auto;
        }
      }
    }
  }
}

.blog-card {
  display: flex;
  flex-direction: column;

  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: ac(24px, 12px);
  padding: ac(20px, 12px);

  color: var(--cl-white);
  background: rgba(0, 0, 0, 0.32);

  transition: border-color 0.3s;

  &:hover {
    border-color: var(--cl-white);
  }

  &.vertical {
    .blog-card__main-info {
      flex-direction: column;
      gap: 16px;
    }

    .blog-card__preview-wrap {
      aspect-ratio: 355 / 198;
    }
  }
}

.blog-card__main-info {
  display: flex;
  gap: ac(20px, 12px);

  margin-bottom: 20px;

  @mixin media 501 {
    display: grid;
    grid-template-columns: 1.35fr minmax(0, 1fr);
  }
}

.blog-card__preview-wrap {
  width: 100%;
  max-width: 420px;
  aspect-ratio: 420 / 257;

  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  overflow: hidden;

  @mixin media 501 {
    /* grid-row: span 2; */
  }
}

.blog-card__preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.blog-card__info {
  width: 100%;
  max-width: 350px;
}

.blog-card__logo-wrap {
  display: flex;
  align-items: center;
  gap: ac(10px, 6px);

  margin-bottom: ac(20px, 8px);

  @mixin media 551 {
    gap: 2px;
  }
}

.blog-card__logo {
  max-width: ac(141px, 58px);

  @mixin media 551 {
    &:not(:last-child) {
      width: 20px;
      height: 20px;
    }
  }
}

.blog-card__logo-text {
  font-size: 16px;
  font-weight: 500;

  @mixin media 551 {
    font-family: 'Inter', sans-serif;
    font-size: 10px;
  }
}

.blog-card__title {
  margin-bottom: 4px;

  font-family: var(--font-title);
  font-size: ac(48px, 26px);
  font-weight: 400;
  line-height: 1;
}

.blog-card__descr {
  color: rgba(255, 255, 255, 0.7);

  font-size: ac(16px, 12px);
  line-height: 1.22;

  @mixin media 501 {
    display: none;
  }
}

.blog-card__descr-mob {
  display: none;
  grid-column: span 2;

  font-size: 12px;
  font-weight: 400;
  line-height: 1.22;
  color: rgba(255, 255, 255, 0.7);

  @mixin media 501 {
    display: block;
  }
}

.blog-card__date {
  margin-top: auto;
  padding-top: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);

  font-size: ac(14px, 12px);
  line-height: 1.21;
  text-align: right;

  color: rgba(255, 255, 255, 0.5);
}

.blog-section__btn {
  display: flex;
  justify-content: center;

  margin: ac(50px, 24px) auto 0;
  border: none;

  background: transparent;
  color: rgba(255, 255, 255, 0.7);

  font-size: ac(16px, 14px);
  font-weight: 400;
  line-height: 1.219;
  text-transform: uppercase;
  text-decoration: underline;

  &.hidden {
    display: none;
  }
}
