html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  vertical-align: baseline;
  box-sizing: border-box;
  background: none;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

article, aside, details, figcaption, figure, footer, header, menu, nav, section {
  display: block;
}

:focus {
  outline: 0;
}

ol, ul {
  list-style: none;
}

a {
  text-decoration: none;
  display: block;
}

a:hover {
  text-decoration: none;
}

input, textarea {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}

label, button, input[type="submit"], input[type="button"] {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

body {
  -webkit-tap-highlight-color: #0000;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

button, input[type="submit"] {
  -webkit-appearance: none;
  -moz-appearance: none;
}

button {
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: Bebas Neue Cyrillic;
  src: url("../fonts/bebasneuecyrillic.ttf");
}

:root {
  --cl-black: #000;
  --cl-black-rgb: 0 0 0;
  --cl-white: #fff;
  --cl-green: #2edc80;
  --cl-red: #fd494b;
  --font-main: Montserrat, sans-serif;
  --font-title: "Bebas Neue Cyrillic", sans-serif;
  --vh: calc(100vh / 100);
}

.icon {
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  display: inline-block;
}

h1, h2, h3, h4 {
  color: var(--cl-grey);
  font-weight: 500;
  line-height: 130%;
}

h1 {
  font-size: clamp(37px, 2.86458vw + 15px, 70px);
  line-height: normal;
}

@media not all and (width >= 640px) {
  h1 {
    line-height: 129%;
  }
}

h2 {
  font-size: clamp(27px, 1.99653vw + 11.6667px, 50px);
  line-height: normal;
}

@media not all and (width >= 640px) {
  h2 {
    line-height: 140%;
  }
}

h2.subtitle {
  font-size: clamp(22px, 1.12847vw + 13.3333px, 35px);
  line-height: 140%;
}

h3 {
  font-size: clamp(22px, .260417vw + 20px, 25px);
  font-weight: 600;
  line-height: normal;
}

h4 {
  font-size: clamp(18px, .173611vw + 16.6667px, 20px);
  font-weight: 400;
  line-height: 140%;
}

@media not all and (width >= 640px) {
  h4 {
    line-height: 155%;
  }
}

h5 {
  font-size: clamp(20px, .694445vw + 14.6667px, 28px);
  font-weight: 500;
  line-height: 1.5;
}

h6 {
  font-size: clamp(18px, .520833vw + 14px, 24px);
  font-weight: 500;
  line-height: 2;
}

p {
  color: var(--cl-medium-grey);
  font-size: clamp(16px, .173611vw + 14.6667px, 18px);
  font-weight: 400;
  line-height: 144%;
}

@media not all and (width >= 640px) {
  p {
    line-height: 162%;
  }
}

li {
  color: var(--cl-medium-grey);
  font-size: clamp(16px, .173611vw + 14.6667px, 18px);
  font-weight: 400;
  line-height: 144%;
}

@media not all and (width >= 640px) {
  li {
    line-height: 162%;
  }
}

a {
  font-size: 16px;
  line-height: 100%;
  display: inline-block;
}

.content-element h4 {
  color: var(--cl-medium-grey);
  font-weight: 500;
}

.content-element h4:not(:last-child) {
  margin-bottom: clamp(14px, .173611vw + 12.6667px, 16px);
}

.content-element ol li, .content-element ul li {
  list-style-position: inside;
}

.content-element ol {
  list-style: decimal;
}

.content-element ul {
  list-style: disc;
}

.content-element li:not(:last-child), .content-element p:not(:last-child) {
  margin-bottom: clamp(14px, .173611vw + 12.6667px, 16px);
}

.dark-bg h1, .dark-bg h2, .dark-bg h3, .dark-bg h4, .dark-bg h5, .dark-bg h6, .dark-bg p, .dark-bg li {
  color: var(--cl-white);
}

.fp-watermark {
  display: none;
}

html {
  scrollbar-width: thin;
  scrollbar-color: var(--cl-red) transparent;
  scroll-behavior: smooth;
}

body {
  color: var(--cl-white);
  font-family: var(--font-main);
  min-height: calc(100 * var(--vh));
  background: #08090a;
  min-width: 320px;
  margin: auto;
  display: flex;
  position: relative;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-track {
  background: var(--cl-light-grey);
  border-radius: 0;
}

body::-webkit-scrollbar-thumb {
  background: var(--cl-red);
  border-radius: 0;
}

body.no-scroll {
  overflow: hidden;
}

.swiper-wrapper {
  left: 0 !important;
}

#site {
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  display: flex;
  position: relative;
  overflow-x: hidden;
}

.main {
  flex-grow: 1;
}

.section {
  padding-top: clamp(15px, 12.1528vw - 78.3333px, 155px);
  padding-bottom: clamp(100px, 4.34028vw + 66.6667px, 150px);
  position: relative;
}

.section__title {
  font-family: var(--font-title);
  letter-spacing: .04rem;
  margin-bottom: 30px;
  font-size: clamp(48px, 4.51389vw + 13.3333px, 100px);
  line-height: 1;
}

.section__title.center {
  text-align: center;
}

@media not all and (width >= 769px) {
  .section__title {
    text-align: center;
  }
}

.container {
  width: 88.5417%;
  max-width: 1700px;
  margin: 0 auto;
}

@media not all and (width >= 769px) {
  .container {
    width: 91.4667%;
  }
}

.logo {
  transition: all .5s;
}

.logo img {
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.logo:hover {
  transform: scale(1.06);
}

#site.cookie-terms-page {
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  min-height: 100vh;
  display: flex;
}

#site.cookie-terms-page .main {
  flex-grow: 1;
  padding: 0;
}

.cookie-terms-section {
  padding: clamp(140px, 9.54861vw + 66.6667px, 250px) 0 clamp(50px, 8.68056vw - 16.6667px, 150px);
}

.cookie-terms-section p {
  margin-bottom: 10px;
  padding: 0;
}

.cookie-terms-section ul, .cookie-terms-section ol {
  padding-left: 20px;
}

.cookie-terms-section ul li p, .cookie-terms-section ol li p {
  margin-bottom: 0;
}

.cookie-terms-section ul {
  list-style: circle;
}

.cookie-terms-section ol {
  list-style: decimal;
}

.cookie-terms-section a {
  text-decoration: underline;
  transition: all .2s;
  display: inline;
}

.cookie-terms-section a:hover {
  opacity: .7;
}

.single-page-404 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  display: flex;
}

.single-page-404 h1, .single-page-404 p {
  margin-bottom: 15px;
  padding: 0;
}

.single-page-404 a {
  margin-top: 20px;
  display: inline-block;
}

.info-block {
  backdrop-filter: blur(10px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  background: #00000080;
  border: 1px solid #ffffff4d;
  border-radius: clamp(8px, .347222vw + 5.33333px, 12px);
  max-width: clamp(350px, 17.3611vw + 216.667px, 550px);
  padding: clamp(16px, 2.1875vw - 12px, 30px) clamp(16px, 2.5vw - 16px, 32px);
  -webkit-backdrop-filter: blur(10px) !important;
}

.info-block__title {
  font-family: var(--font-title);
  margin-bottom: 4px;
  font-size: clamp(36px, 2.43056vw + 17.3333px, 64px);
  line-height: 1;
}

.info-block__descr {
  margin-bottom: clamp(16px, .416667vw + 10px, 18px);
  font-size: clamp(14px, .347222vw + 11.3333px, 18px);
  line-height: 1.222;
}

.info-block__list {
  color: #ffffffb3;
  border-top: 1px solid #ffffff4d;
  padding-top: 13px;
  padding-left: 2ch;
  list-style-type: disc;
}

.info-block__list-item {
  font-size: clamp(12px, .347222vw + 9.33333px, 16px);
  line-height: 1.22;
}

.form__title {
  font-family: var(--font-title);
  text-align: center;
  margin-bottom: 8px;
  font-size: clamp(48px, 2.08333vw + 32px, 72px);
  line-height: 1;
}

.form__description {
  color: #ffffffb3;
  text-align: center;
  text-wrap: balance;
  max-width: 800px;
  margin: 0 auto clamp(24px, 3.125vw, 60px);
  font-size: clamp(15px, .0868056vw + 14.3333px, 16px);
  line-height: 1.219;
}

.form__fields {
  gap: clamp(12px, .347222vw + 9.33333px, 16px);
  display: grid;
}

.form__fields:last-of-type:not(:last-child) {
  margin-bottom: clamp(32px, 2.43056vw + 13.3333px, 60px);
}

.form__row {
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  gap: clamp(12px, .347222vw + 9.33333px, 16px);
  display: grid;
}

@media not all and (width >= 769px) {
  .form__row {
    grid-template-columns: 1fr;
  }
}

[data-plyr] {
  --plyr-video-control-background-hover: var(--cl-red);
  --plyr-range-fill-background: var(--cl-red);
  max-width: 100%;
  max-height: 100%;
}

.plyr.plyr--stopped .plyr__controls {
  display: none;
}

#call-me-popup .popup__content-wrap {
  padding-left: 12px;
  padding-right: 12px;
}

#call-me-popup .form__description {
  color: var(--cl-white);
  font-size: 18px;
  font-weight: 500;
}

.contact-fixed {
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  display: flex;
  position: fixed;
  bottom: 20px;
  right: 40px;
}

@media not all and (width >= 769px) {
  .contact-fixed {
    align-items: center;
    bottom: 15px;
    right: 15px;
  }
}

.telegram-btn {
  background: #111;
  border: 1px solid #039be5;
  border-radius: 999px;
  justify-content: center;
  align-items: center;
  width: 92px;
  height: 92px;
  transition: all .3s;
  display: flex;
}

@media not all and (width >= 769px) {
  .telegram-btn {
    width: 56px;
    height: 56px;
  }
}

.telegram-btn:hover {
  background: #039be5;
}

.telegram-btn:hover .telegram-btn__icon {
  filter: brightness(0) invert();
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

.telegram-btn__icon {
  width: 50px;
  transition: all .3s;
}

@media not all and (width >= 769px) {
  .telegram-btn__icon {
    width: 28px;
    height: 28px;
  }
}

.call-me {
  border-radius: 999px;
  align-items: center;
  width: 120px;
  padding: 27px;
  transition: all .3s .3s;
  display: flex;
}

@media not all and (width >= 769px) {
  .call-me {
    width: fit-content;
    padding: 10px;
  }
}

.call-me.active {
  background: #08090a;
  width: 592px;
  transition: all .3s;
}

.call-me.active .call-me__btn-close, .call-me.active .call-me__form, .call-me.active .call-me__submit-btn {
  opacity: 1;
  transition: all .3s .3s;
}

.call-me.en textPath {
  font-size: 11.5px;
}

.call-me.ru textPath {
  font-size: 8.4px;
}

.call-me__btn {
  background: #111;
  border: 1px solid #fff;
  border-radius: 50%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 92px;
  height: 92px;
  transition: all .3s;
  display: flex;
  position: relative;
}

.call-me__btn:hover {
  background: #25282b;
}

@media not all and (width >= 769px) {
  .call-me__btn {
    width: 56px;
    height: 56px;
  }
}

.call-me__btn-icon {
  width: 56px;
  height: 56px;
}

@media not all and (width >= 769px) {
  .call-me__btn-icon {
    width: 28px;
    height: 28px;
  }
}

.call-me__btn-text {
  width: 124px;
  height: 124px;
  animation: 30s linear infinite rotate;
  position: absolute;
  top: 50%;
  left: 50%;
}

@media not all and (width >= 769px) {
  .call-me__btn-text {
    width: 80px;
    height: 80px;
  }
}

.call-me__btn-text path {
  fill: #0000;
}

.call-me__btn-text textPath {
  text-transform: uppercase;
  fill: #ffffff4d;
  font-family: Inter;
  font-size: 9.9px;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%)rotate(0);
  }

  100% {
    transform: translate(-50%, -50%)rotate(360deg);
  }
}

.call-me__btn-close {
  opacity: 0;
  background: #25282b;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  transition: all .3s;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.call-me__btn-close:before, .call-me__btn-close:after {
  content: "";
  background: #fff;
  width: 40%;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.call-me__btn-close:before {
  transform: translate(-50%, -50%)rotate(45deg);
}

.call-me__btn-close:after {
  transform: translate(-50%, -50%)rotate(-45deg);
}

.call-me__form {
  opacity: 0;
  margin-left: 44px;
  font-size: 16px;
  transition: all .3s;
}

@media not all and (width >= 769px) {
  .call-me__form {
    display: none;
  }
}

.call-me__form .iti .iti__selected-dial-code {
  font-size: 16px;
}

.circle-btn.call-me__submit-btn {
  opacity: 0;
  flex-shrink: 0;
  width: 92px;
  height: 92px;
  margin-left: 29px;
}

@media not all and (width >= 769px) {
  .circle-btn.call-me__submit-btn {
    display: none;
  }
}

.call-me__form-descr {
  height: 2rem;
  margin-bottom: 12px;
}

.call-me__form-input {
  color: #fff;
  background: none;
  border: 1px solid #ffffff4d;
  width: 100%;
  max-width: 278px;
  padding: 6px;
}

.call-me__form-input:not(:last-child) {
  margin-bottom: 6px;
}

[data-aiv^="fade"] {
  transition: all .4s;
  opacity: 0 !important;
}

[data-aiv^="fade"][data-aiv$="top"] {
  translate: 0 50% !important;
}

[data-aiv^="fade"][data-aiv$="bottom"] {
  translate: 0 -50% !important;
}

[data-aiv^="fade"][data-aiv$="left"] {
  translate: 50% !important;
}

[data-aiv^="fade"][data-aiv$="right"] {
  translate: -50% !important;
}

[data-aiv^="fade"].in-view {
  opacity: 1 !important;
}

[data-aiv^="fade"].in-view[data-aiv$="top"], [data-aiv^="fade"].in-view[data-aiv$="bottom"], [data-aiv^="fade"].in-view[data-aiv$="left"], [data-aiv^="fade"].in-view[data-aiv$="right"] {
  translate: 0 !important;
}

:root {
  --iti-border-gray: #ccc;
  --iti-text-gray: #999;
  --iti-spacer-horizontal: 8px;
  --iti-flag-height: 15px;
  --iti-flag-width: 20px;
  --iti-border-width: 1px;
  --iti-arrow-height: 4px;
  --iti-arrow-width: 6px;
  --iti-triangle-border: calc(var(--iti-arrow-width) / 2);
  --iti-arrow-padding: 6px;
  --iti-input-padding: 6px;
  --iti-right-hand-selected-country-padding: calc(var(--iti-spacer-horizontal)  + var(--iti-spacer-horizontal)  + var(--iti-flag-width));
  --iti-selected-country-arrow-padding: calc(var(--iti-arrow-padding)  + var(--iti-arrow-padding)  + var(--iti-flag-width)  + var(--iti-spacer-horizontal)  + var(--iti-arrow-width)  + var(--iti-input-padding));
  --iti-flag-sprite-width: 5762px;
  --iti-flag-sprite-height: 15px;
  --iti-mobile-popup-margin: 30px;
  --iti-border-color: #5b5b5b;
  --iti-dialcode-color: #999;
  --iti-dropdown-bg: #0d1117;
  --iti-arrow-color: #aaa;
  --iti-hover-color: #30363d;
}

.iti {
  display: inline-block;
  position: relative;
}

.iti * {
  box-sizing: border-box;
}

.iti__hide {
  display: none;
}

.iti__v-hide {
  visibility: hidden;
}

.iti__a11y-text {
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.iti input.iti__tel-input, .iti input.iti__tel-input[type="text"], .iti input.iti__tel-input[type="tel"] {
  z-index: 0;
  padding-right: var(--iti-right-hand-selected-country-padding);
  width: 100%;
  margin-right: 0;
  position: relative;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.iti__country-container {
  padding: var(--iti-border-width);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.iti__selected-country {
  z-index: 1;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  line-height: inherit;
  background: none;
  border: 0;
  border-radius: 0;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
  text-decoration: none;
  display: flex;
  position: relative;
}

.iti__selected-country-primary {
  padding: 0 var(--iti-arrow-padding) 0 var(--iti-spacer-horizontal);
  align-items: center;
  height: 100%;
  display: flex;
}

.iti__arrow {
  margin-left: var(--iti-arrow-padding);
  border-left: var(--iti-triangle-border) solid transparent;
  border-right: var(--iti-triangle-border) solid transparent;
  border-top: var(--iti-arrow-height) solid var(--iti-arrow-color);
  width: 0;
  height: 0;
}

[dir="rtl"] .iti__arrow {
  margin-right: var(--iti-arrow-padding);
  margin-left: 0;
}

.iti__arrow--up {
  border-top: none;
  border-bottom: var(--iti-arrow-height) solid var(--iti-arrow-color);
}

.iti__dropdown-content {
  background-color: var(--iti-dropdown-bg);
  border-radius: 3px;
}

.iti--inline-dropdown .iti__dropdown-content {
  z-index: 3;
  margin-top: 3px;
  margin-left: calc(var(--iti-border-width) * -1);
  border: var(--iti-border-width) solid var(--iti-border-color);
  position: absolute;
  box-shadow: 1px 1px 4px #0003;
}

.iti__search-input {
  border-width: 0;
  border-bottom: 1px solid var(--iti-border-color);
  color: #fff;
  background: none;
  border-radius: 3px;
  width: 100%;
  padding: 9px 12px;
}

.iti__search-input + .iti__country-list {
  border-top: 1px solid var(--iti-border-color);
}

.iti__country-list {
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-y: scroll;
}

.iti--inline-dropdown .iti__country-list {
  max-height: 185px;
}

.iti--flexible-dropdown-width .iti__country-list {
  white-space: nowrap;
}

@media (width <= 500px) {
  .iti--flexible-dropdown-width .iti__country-list {
    white-space: normal;
  }
}

.iti__flag-box {
  width: var(--iti-flag-width);
  display: inline-block;
}

.iti__country {
  padding: 8px var(--iti-spacer-horizontal);
  outline: none;
  align-items: center;
  display: flex;
}

.iti__dial-code {
  color: var(--iti-dialcode-color);
}

.iti__country.iti__highlight {
  background-color: var(--iti-hover-color);
}

.iti__flag-box, .iti__country-name {
  margin-right: var(--iti-spacer-horizontal);
}

[dir="rtl"] .iti__flag-box, [dir="rtl"] .iti__country-name {
  margin-right: 0;
  margin-left: var(--iti-spacer-horizontal);
}

.iti--allow-dropdown input.iti__tel-input, .iti--allow-dropdown input.iti__tel-input[type="text"], .iti--allow-dropdown input.iti__tel-input[type="tel"] {
  padding-right: var(--iti-input-padding);
  padding-left: var(--iti-selected-country-arrow-padding);
  margin-left: 0;
}

[dir="rtl"] .iti--allow-dropdown input.iti__tel-input, [dir="rtl"] .iti--allow-dropdown input.iti__tel-input[type="text"], [dir="rtl"] .iti--allow-dropdown input.iti__tel-input[type="tel"] {
  padding-right: var(--iti-selected-country-arrow-padding);
  padding-left: var(--iti-input-padding);
  margin-right: 0;
}

.iti--allow-dropdown .iti__country-container {
  left: 0;
  right: auto;
}

[dir="rtl"] .iti--allow-dropdown .iti__country-container {
  left: auto;
  right: 0;
}

.iti--allow-dropdown .iti__country-container:not(:has( + input[disabled])):not(:has( + input[readonly])):hover, .iti--allow-dropdown .iti__country-container:not(:has( + input[disabled])):not(:has( + input[readonly])):hover button {
  cursor: pointer;
}

.iti .iti__selected-dial-code {
  margin-left: 4px;
  font-size: clamp(14px, .173611vw + 12.6667px, 16px);
}

[dir="rtl"] .iti .iti__selected-dial-code {
  margin-left: 0;
  margin-right: 4px;
}

.iti--container {
  z-index: 1060;
  padding: var(--iti-border-width);
  position: fixed;
  top: -1000px;
  left: -1000px;
}

.iti--container:hover {
  cursor: pointer;
}

.iti--fullscreen-popup.iti--container {
  padding: var(--iti-mobile-popup-margin);
  background-color: #00000080;
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
  position: fixed;
  inset: 0;
}

.iti--fullscreen-popup .iti__dropdown-content {
  flex-direction: column;
  max-height: 100%;
  display: flex;
  position: relative;
}

.iti--fullscreen-popup .iti__country {
  padding: 10px;
  line-height: 1.5em;
}

.iti__flag {
  --iti-flag-offset: 0px;
  --iti-flag-width: 20px;
  --iti-flag-height: 14px;
  height: var(--iti-flag-height);
  width: var(--iti-flag-width);
  background-image: url("../img/flags.webp");
  background-repeat: no-repeat;
  background-position: var(--iti-flag-offset) 0;
  background-size: var(--iti-flag-sprite-width) var(--iti-flag-sprite-height);
  box-shadow: 0 0 1px #888;
}

.iti__np {
  --iti-flag-width: 13px;
}

.iti__va, .iti__ch {
  --iti-flag-width: 15px;
}

.iti__ne, .iti__be {
  --iti-flag-width: 18px;
}

.iti__mc {
  --iti-flag-width: 19px;
}

.iti__zw, .iti__ws, .iti__vg, .iti__uz, .iti__uk, .iti__tv, .iti__to, .iti__tl, .iti__tk, .iti__tj, .iti__tc, .iti__ta, .iti__su, .iti__st, .iti__ss, .iti__si, .iti__sh, .iti__sd, .iti__sc, .iti__sb, .iti__ps, .iti__pn, .iti__ph, .iti__om, .iti__nz, .iti__nu, .iti__nr, .iti__ng, .iti__nf, .iti__nc, .iti__my, .iti__ms, .iti__mp, .iti__mn, .iti__mk, .iti__me, .iti__md, .iti__ly, .iti__lv, .iti__lk, .iti__lc, .iti__kz, .iti__ky, .iti__kw, .iti__kp, .iti__ki, .iti__jo, .iti__jm, .iti__io, .iti__im, .iti__ie, .iti__hu, .iti__hr, .iti__hn, .iti__hm, .iti__gw, .iti__gs, .iti__gi, .iti__gb, .iti__fk, .iti__fj, .iti__et, .iti__er, .iti__eh, .iti__dm, .iti__dg, .iti__cx, .iti__cu, .iti__ck, .iti__cc, .iti__ca, .iti__by, .iti__bs, .iti__bn, .iti__bm, .iti__ba, .iti__az, .iti__au, .iti__as, .iti__am, .iti__ai, .iti__ae, .iti__ac {
  --iti-flag-height: 10px;
}

.iti__us, .iti__um, .iti__py, .iti__mh, .iti__lr, .iti__gu, .iti__fm {
  --iti-flag-height: 11px;
}

.iti__vu, .iti__tt, .iti__sv, .iti__ni, .iti__mx, .iti__lu, .iti__lt, .iti__li, .iti__km, .iti__kg, .iti__je, .iti__ir, .iti__ht, .iti__gy, .iti__gd, .iti__fi, .iti__de, .iti__cv, .iti__cr, .iti__cq, .iti__bz, .iti__bi, .iti__bh, .iti__bg, .iti__bd {
  --iti-flag-height: 12px;
}

.iti__tg, .iti__se, .iti__pw, .iti__pl, .iti__kh, .iti__gt, .iti__ee, .iti__ax, .iti__ar {
  --iti-flag-height: 13px;
}

.iti__xk, .iti__va, .iti__sm, .iti__sj, .iti__pg, .iti__np, .iti__no, .iti__ne, .iti__mc, .iti__is, .iti__il, .iti__ga, .iti__fo, .iti__dk, .iti__ch, .iti__cd, .iti__bv, .iti__be, .iti__al {
  --iti-flag-height: 15px;
}

.iti__qa {
  --iti-flag-height: 8px;
}

.iti__ac {
  --iti-flag-offset: 0px;
}

.iti__ad {
  --iti-flag-offset: -22px;
}

.iti__ae {
  --iti-flag-offset: -44px;
}

.iti__af {
  --iti-flag-offset: -66px;
}

.iti__ag {
  --iti-flag-offset: -88px;
}

.iti__ai {
  --iti-flag-offset: -110px;
}

.iti__al {
  --iti-flag-offset: -132px;
}

.iti__am {
  --iti-flag-offset: -154px;
}

.iti__ao {
  --iti-flag-offset: -176px;
}

.iti__aq {
  --iti-flag-offset: -198px;
}

.iti__ar {
  --iti-flag-offset: -220px;
}

.iti__as {
  --iti-flag-offset: -242px;
}

.iti__at {
  --iti-flag-offset: -264px;
}

.iti__au {
  --iti-flag-offset: -286px;
}

.iti__aw {
  --iti-flag-offset: -308px;
}

.iti__ax {
  --iti-flag-offset: -330px;
}

.iti__az {
  --iti-flag-offset: -352px;
}

.iti__ba {
  --iti-flag-offset: -374px;
}

.iti__bb {
  --iti-flag-offset: -396px;
}

.iti__bd {
  --iti-flag-offset: -418px;
}

.iti__be {
  --iti-flag-offset: -440px;
}

.iti__bf {
  --iti-flag-offset: -460px;
}

.iti__bg {
  --iti-flag-offset: -482px;
}

.iti__bh {
  --iti-flag-offset: -504px;
}

.iti__bi {
  --iti-flag-offset: -526px;
}

.iti__bj {
  --iti-flag-offset: -548px;
}

.iti__bl {
  --iti-flag-offset: -570px;
}

.iti__bm {
  --iti-flag-offset: -592px;
}

.iti__bn {
  --iti-flag-offset: -614px;
}

.iti__bo {
  --iti-flag-offset: -636px;
}

.iti__bq {
  --iti-flag-offset: -658px;
}

.iti__br {
  --iti-flag-offset: -680px;
}

.iti__bs {
  --iti-flag-offset: -702px;
}

.iti__bt {
  --iti-flag-offset: -724px;
}

.iti__bv {
  --iti-flag-offset: -746px;
}

.iti__bw {
  --iti-flag-offset: -768px;
}

.iti__by {
  --iti-flag-offset: -790px;
}

.iti__bz {
  --iti-flag-offset: -812px;
}

.iti__ca {
  --iti-flag-offset: -834px;
}

.iti__cc {
  --iti-flag-offset: -856px;
}

.iti__cd {
  --iti-flag-offset: -878px;
}

.iti__cf {
  --iti-flag-offset: -900px;
}

.iti__cg {
  --iti-flag-offset: -922px;
}

.iti__ch {
  --iti-flag-offset: -944px;
}

.iti__ci {
  --iti-flag-offset: -961px;
}

.iti__ck {
  --iti-flag-offset: -983px;
}

.iti__cl {
  --iti-flag-offset: -1005px;
}

.iti__cm {
  --iti-flag-offset: -1027px;
}

.iti__cn {
  --iti-flag-offset: -1049px;
}

.iti__co {
  --iti-flag-offset: -1071px;
}

.iti__cp {
  --iti-flag-offset: -1093px;
}

.iti__cq {
  --iti-flag-offset: -1115px;
}

.iti__cr {
  --iti-flag-offset: -1137px;
}

.iti__cu {
  --iti-flag-offset: -1159px;
}

.iti__cv {
  --iti-flag-offset: -1181px;
}

.iti__cw {
  --iti-flag-offset: -1203px;
}

.iti__cx {
  --iti-flag-offset: -1225px;
}

.iti__cy {
  --iti-flag-offset: -1247px;
}

.iti__cz {
  --iti-flag-offset: -1269px;
}

.iti__de {
  --iti-flag-offset: -1291px;
}

.iti__dg {
  --iti-flag-offset: -1313px;
}

.iti__dj {
  --iti-flag-offset: -1335px;
}

.iti__dk {
  --iti-flag-offset: -1357px;
}

.iti__dm {
  --iti-flag-offset: -1379px;
}

.iti__do {
  --iti-flag-offset: -1401px;
}

.iti__dz {
  --iti-flag-offset: -1423px;
}

.iti__ea {
  --iti-flag-offset: -1445px;
}

.iti__ec {
  --iti-flag-offset: -1467px;
}

.iti__ee {
  --iti-flag-offset: -1489px;
}

.iti__eg {
  --iti-flag-offset: -1511px;
}

.iti__eh {
  --iti-flag-offset: -1533px;
}

.iti__er {
  --iti-flag-offset: -1555px;
}

.iti__es {
  --iti-flag-offset: -1577px;
}

.iti__et {
  --iti-flag-offset: -1599px;
}

.iti__eu {
  --iti-flag-offset: -1621px;
}

.iti__ez {
  --iti-flag-offset: -1643px;
}

.iti__fi {
  --iti-flag-offset: -1665px;
}

.iti__fj {
  --iti-flag-offset: -1687px;
}

.iti__fk {
  --iti-flag-offset: -1709px;
}

.iti__fm {
  --iti-flag-offset: -1731px;
}

.iti__fo {
  --iti-flag-offset: -1753px;
}

.iti__fr {
  --iti-flag-offset: -1775px;
}

.iti__fx {
  --iti-flag-offset: -1797px;
}

.iti__ga {
  --iti-flag-offset: -1819px;
}

.iti__gb {
  --iti-flag-offset: -1841px;
}

.iti__gd {
  --iti-flag-offset: -1863px;
}

.iti__ge {
  --iti-flag-offset: -1885px;
}

.iti__gf {
  --iti-flag-offset: -1907px;
}

.iti__gg {
  --iti-flag-offset: -1929px;
}

.iti__gh {
  --iti-flag-offset: -1951px;
}

.iti__gi {
  --iti-flag-offset: -1973px;
}

.iti__gl {
  --iti-flag-offset: -1995px;
}

.iti__gm {
  --iti-flag-offset: -2017px;
}

.iti__gn {
  --iti-flag-offset: -2039px;
}

.iti__gp {
  --iti-flag-offset: -2061px;
}

.iti__gq {
  --iti-flag-offset: -2083px;
}

.iti__gr {
  --iti-flag-offset: -2105px;
}

.iti__gs {
  --iti-flag-offset: -2127px;
}

.iti__gt {
  --iti-flag-offset: -2149px;
}

.iti__gu {
  --iti-flag-offset: -2171px;
}

.iti__gw {
  --iti-flag-offset: -2193px;
}

.iti__gy {
  --iti-flag-offset: -2215px;
}

.iti__hk {
  --iti-flag-offset: -2237px;
}

.iti__hm {
  --iti-flag-offset: -2259px;
}

.iti__hn {
  --iti-flag-offset: -2281px;
}

.iti__hr {
  --iti-flag-offset: -2303px;
}

.iti__ht {
  --iti-flag-offset: -2325px;
}

.iti__hu {
  --iti-flag-offset: -2347px;
}

.iti__ic {
  --iti-flag-offset: -2369px;
}

.iti__id {
  --iti-flag-offset: -2391px;
}

.iti__ie {
  --iti-flag-offset: -2413px;
}

.iti__il {
  --iti-flag-offset: -2435px;
}

.iti__im {
  --iti-flag-offset: -2457px;
}

.iti__in {
  --iti-flag-offset: -2479px;
}

.iti__io {
  --iti-flag-offset: -2501px;
}

.iti__iq {
  --iti-flag-offset: -2523px;
}

.iti__ir {
  --iti-flag-offset: -2545px;
}

.iti__is {
  --iti-flag-offset: -2567px;
}

.iti__it {
  --iti-flag-offset: -2589px;
}

.iti__je {
  --iti-flag-offset: -2611px;
}

.iti__jm {
  --iti-flag-offset: -2633px;
}

.iti__jo {
  --iti-flag-offset: -2655px;
}

.iti__jp {
  --iti-flag-offset: -2677px;
}

.iti__ke {
  --iti-flag-offset: -2699px;
}

.iti__kg {
  --iti-flag-offset: -2721px;
}

.iti__kh {
  --iti-flag-offset: -2743px;
}

.iti__ki {
  --iti-flag-offset: -2765px;
}

.iti__km {
  --iti-flag-offset: -2787px;
}

.iti__kn {
  --iti-flag-offset: -2809px;
}

.iti__kp {
  --iti-flag-offset: -2831px;
}

.iti__kr {
  --iti-flag-offset: -2853px;
}

.iti__kw {
  --iti-flag-offset: -2875px;
}

.iti__ky {
  --iti-flag-offset: -2897px;
}

.iti__kz {
  --iti-flag-offset: -2919px;
}

.iti__la {
  --iti-flag-offset: -2941px;
}

.iti__lb {
  --iti-flag-offset: -2963px;
}

.iti__lc {
  --iti-flag-offset: -2985px;
}

.iti__li {
  --iti-flag-offset: -3007px;
}

.iti__lk {
  --iti-flag-offset: -3029px;
}

.iti__lr {
  --iti-flag-offset: -3051px;
}

.iti__ls {
  --iti-flag-offset: -3073px;
}

.iti__lt {
  --iti-flag-offset: -3095px;
}

.iti__lu {
  --iti-flag-offset: -3117px;
}

.iti__lv {
  --iti-flag-offset: -3139px;
}

.iti__ly {
  --iti-flag-offset: -3161px;
}

.iti__ma {
  --iti-flag-offset: -3183px;
}

.iti__mc {
  --iti-flag-offset: -3205px;
}

.iti__md {
  --iti-flag-offset: -3226px;
}

.iti__me {
  --iti-flag-offset: -3248px;
}

.iti__mf {
  --iti-flag-offset: -3270px;
}

.iti__mg {
  --iti-flag-offset: -3292px;
}

.iti__mh {
  --iti-flag-offset: -3314px;
}

.iti__mk {
  --iti-flag-offset: -3336px;
}

.iti__ml {
  --iti-flag-offset: -3358px;
}

.iti__mm {
  --iti-flag-offset: -3380px;
}

.iti__mn {
  --iti-flag-offset: -3402px;
}

.iti__mo {
  --iti-flag-offset: -3424px;
}

.iti__mp {
  --iti-flag-offset: -3446px;
}

.iti__mq {
  --iti-flag-offset: -3468px;
}

.iti__mr {
  --iti-flag-offset: -3490px;
}

.iti__ms {
  --iti-flag-offset: -3512px;
}

.iti__mt {
  --iti-flag-offset: -3534px;
}

.iti__mu {
  --iti-flag-offset: -3556px;
}

.iti__mv {
  --iti-flag-offset: -3578px;
}

.iti__mw {
  --iti-flag-offset: -3600px;
}

.iti__mx {
  --iti-flag-offset: -3622px;
}

.iti__my {
  --iti-flag-offset: -3644px;
}

.iti__mz {
  --iti-flag-offset: -3666px;
}

.iti__na {
  --iti-flag-offset: -3688px;
}

.iti__nc {
  --iti-flag-offset: -3710px;
}

.iti__ne {
  --iti-flag-offset: -3732px;
}

.iti__nf {
  --iti-flag-offset: -3752px;
}

.iti__ng {
  --iti-flag-offset: -3774px;
}

.iti__ni {
  --iti-flag-offset: -3796px;
}

.iti__nl {
  --iti-flag-offset: -3818px;
}

.iti__no {
  --iti-flag-offset: -3840px;
}

.iti__np {
  --iti-flag-offset: -3862px;
}

.iti__nr {
  --iti-flag-offset: -3877px;
}

.iti__nu {
  --iti-flag-offset: -3899px;
}

.iti__nz {
  --iti-flag-offset: -3921px;
}

.iti__om {
  --iti-flag-offset: -3943px;
}

.iti__pa {
  --iti-flag-offset: -3965px;
}

.iti__pe {
  --iti-flag-offset: -3987px;
}

.iti__pf {
  --iti-flag-offset: -4009px;
}

.iti__pg {
  --iti-flag-offset: -4031px;
}

.iti__ph {
  --iti-flag-offset: -4053px;
}

.iti__pk {
  --iti-flag-offset: -4075px;
}

.iti__pl {
  --iti-flag-offset: -4097px;
}

.iti__pm {
  --iti-flag-offset: -4119px;
}

.iti__pn {
  --iti-flag-offset: -4141px;
}

.iti__pr {
  --iti-flag-offset: -4163px;
}

.iti__ps {
  --iti-flag-offset: -4185px;
}

.iti__pt {
  --iti-flag-offset: -4207px;
}

.iti__pw {
  --iti-flag-offset: -4229px;
}

.iti__py {
  --iti-flag-offset: -4251px;
}

.iti__qa {
  --iti-flag-offset: -4273px;
}

.iti__re {
  --iti-flag-offset: -4295px;
}

.iti__ro {
  --iti-flag-offset: -4317px;
}

.iti__rs {
  --iti-flag-offset: -4339px;
}

.iti__ru {
  --iti-flag-offset: -4361px;
}

.iti__rw {
  --iti-flag-offset: -4383px;
}

.iti__sa {
  --iti-flag-offset: -4405px;
}

.iti__sb {
  --iti-flag-offset: -4427px;
}

.iti__sc {
  --iti-flag-offset: -4449px;
}

.iti__sd {
  --iti-flag-offset: -4471px;
}

.iti__se {
  --iti-flag-offset: -4493px;
}

.iti__sg {
  --iti-flag-offset: -4515px;
}

.iti__sh {
  --iti-flag-offset: -4537px;
}

.iti__si {
  --iti-flag-offset: -4559px;
}

.iti__sj {
  --iti-flag-offset: -4581px;
}

.iti__sk {
  --iti-flag-offset: -4603px;
}

.iti__sl {
  --iti-flag-offset: -4625px;
}

.iti__sm {
  --iti-flag-offset: -4647px;
}

.iti__sn {
  --iti-flag-offset: -4669px;
}

.iti__so {
  --iti-flag-offset: -4691px;
}

.iti__sr {
  --iti-flag-offset: -4713px;
}

.iti__ss {
  --iti-flag-offset: -4735px;
}

.iti__st {
  --iti-flag-offset: -4757px;
}

.iti__su {
  --iti-flag-offset: -4779px;
}

.iti__sv {
  --iti-flag-offset: -4801px;
}

.iti__sx {
  --iti-flag-offset: -4823px;
}

.iti__sy {
  --iti-flag-offset: -4845px;
}

.iti__sz {
  --iti-flag-offset: -4867px;
}

.iti__ta {
  --iti-flag-offset: -4889px;
}

.iti__tc {
  --iti-flag-offset: -4911px;
}

.iti__td {
  --iti-flag-offset: -4933px;
}

.iti__tf {
  --iti-flag-offset: -4955px;
}

.iti__tg {
  --iti-flag-offset: -4977px;
}

.iti__th {
  --iti-flag-offset: -4999px;
}

.iti__tj {
  --iti-flag-offset: -5021px;
}

.iti__tk {
  --iti-flag-offset: -5043px;
}

.iti__tl {
  --iti-flag-offset: -5065px;
}

.iti__tm {
  --iti-flag-offset: -5087px;
}

.iti__tn {
  --iti-flag-offset: -5109px;
}

.iti__to {
  --iti-flag-offset: -5131px;
}

.iti__tr {
  --iti-flag-offset: -5153px;
}

.iti__tt {
  --iti-flag-offset: -5175px;
}

.iti__tv {
  --iti-flag-offset: -5197px;
}

.iti__tw {
  --iti-flag-offset: -5219px;
}

.iti__tz {
  --iti-flag-offset: -5241px;
}

.iti__ua {
  --iti-flag-offset: -5263px;
}

.iti__ug {
  --iti-flag-offset: -5285px;
}

.iti__uk {
  --iti-flag-offset: -5307px;
}

.iti__um {
  --iti-flag-offset: -5329px;
}

.iti__un {
  --iti-flag-offset: -5351px;
}

.iti__us {
  --iti-flag-offset: -5373px;
}

.iti__uy {
  --iti-flag-offset: -5395px;
}

.iti__uz {
  --iti-flag-offset: -5417px;
}

.iti__va {
  --iti-flag-offset: -5439px;
}

.iti__vc {
  --iti-flag-offset: -5456px;
}

.iti__ve {
  --iti-flag-offset: -5478px;
}

.iti__vg {
  --iti-flag-offset: -5500px;
}

.iti__vi {
  --iti-flag-offset: -5522px;
}

.iti__vn {
  --iti-flag-offset: -5544px;
}

.iti__vu {
  --iti-flag-offset: -5566px;
}

.iti__wf {
  --iti-flag-offset: -5588px;
}

.iti__ws {
  --iti-flag-offset: -5610px;
}

.iti__xk {
  --iti-flag-offset: -5632px;
}

.iti__ye {
  --iti-flag-offset: -5654px;
}

.iti__yt {
  --iti-flag-offset: -5676px;
}

.iti__za {
  --iti-flag-offset: -5698px;
}

.iti__zm {
  --iti-flag-offset: -5720px;
}

.iti__zw {
  --iti-flag-offset: -5742px;
}

.iti__globe {
  box-shadow: none;
  background-image: url("../img/globe.webp");
  background-position: 100%;
  background-size: contain;
  height: 19px;
}

@media (-webkit-device-pixel-ratio >= 2), (resolution >= 2x) {
  .iti__flag {
    background-image: url("../img/flags@2x.webp");
  }

  .iti__globe {
    background-image: url("../img/globe@2x.webp");
  }
}

.header {
  z-index: 999;
  align-items: center;
  width: 100%;
  padding: clamp(13px, 3.21181vw - 11.6667px, 50px) 0;
  transition: all .3s ease-in-out;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.header.scroll {
  backdrop-filter: blur(10px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  background-color: #000c;
  padding: clamp(13px, .607639vw + 8.33333px, 20px) 0;
  -webkit-backdrop-filter: blur(10px) !important;
}

.header.scroll .header__drop-menu {
  top: calc(100% + 70px);
}

@media not all and (width >= 651px) {
  .header.scroll, .header {
    padding: 4px 0 20px;
  }
}

.header__content {
  align-items: center;
  width: 100%;
  display: flex;
}

@media not all and (width >= 651px) {
  .header__content {
    display: none;
  }
}

.header__main-nav {
  align-items: center;
  gap: clamp(20px, 4.34028vw - 13.3333px, 70px);
  display: flex;
}

.header__logo-link {
  justify-content: center;
  align-items: center;
  width: clamp(60px, 4.25347vw + 27.3333px, 109px);
  margin-right: clamp(20px, 4.34028vw - 13.3333px, 70px);
  transition: transform .3s ease-in-out;
  display: flex;
}

.header__logo-link:hover {
  transform: scale(1.1);
}

@media not all and (width >= 651px) {
  .header__logo-link {
    width: 70px;
    margin-right: 0;
  }
}

.header__additional-nav {
  align-items: center;
  gap: clamp(16px, 1.21528vw + 6.66667px, 30px);
  margin-left: auto;
  display: flex;
}

.header__nav-link {
  color: var(--color-white);
  font-size: clamp(14px, .173611vw + 12.6667px, 16px);
  font-weight: 400;
  line-height: 1.219;
  transition: color .3s ease-in-out;
}

.header__nav-link:hover {
  color: var(--cl-green);
}

.header__socials-wrap {
  align-items: center;
  gap: clamp(8px, .694445vw + 2.66667px, 16px);
  display: flex;
}

.header__socials-item {
  color: #00b355;
  border-radius: 4px;
  transition: color .3s ease-in-out;
  overflow: hidden;
}

.header__socials-item:hover {
  color: var(--color-white);
}

.header__burger {
  cursor: pointer;
  background: none;
  border: none;
  flex-direction: column;
  justify-content: space-between;
  width: 24px;
  height: 16px;
  padding: 0;
  display: flex;
}

.header__burger span {
  background-color: var(--cl-white);
  width: 100%;
  height: 2px;
  transition: all .3s ease-in-out;
  display: block;
}

.header__burger.active span:first-child {
  transform: translateY(7px)rotate(45deg);
}

.header__burger.active span:nth-child(2) {
  opacity: 0;
  transform: translateX(70%);
}

.header__burger.active span:nth-child(3) {
  transform: translateY(-7px)rotate(-45deg);
}

.header__drop-menu-wrap {
  position: relative;
}

.header__drop-menu {
  opacity: 0;
  visibility: hidden;
  background-color: #000c;
  border-radius: 12px;
  flex-direction: column;
  align-items: end;
  gap: clamp(10px, .868056vw + 3.33333px, 20px);
  padding: 12px 0;
  transition: all .3s ease-in-out;
  display: flex;
  position: absolute;
  top: calc(100% + 50px);
  right: 0;
  transform: translateY(-20px);
}

.header__drop-menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.header__drop-menu-link {
  color: var(--cl-white);
  text-align: right;
  border-radius: 6px;
  justify-content: space-between;
  align-items: center;
  gap: 13.5px;
  width: 100%;
  padding: 12px 20px 12px 12px;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.219;
  transition: color .3s ease-in-out;
  display: flex;
}

.header__drop-menu-link:hover {
  color: var(--cl-green);
}

.header__drop-menu-link:hover span:before {
  background: var(--cl-green);
  height: 14px;
}

.header__drop-menu-link:hover span:after {
  transform: translate(-50%, -50%)scaleY(1);
}

.header__drop-menu-link span {
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
  position: relative;
}

.header__drop-menu-link span:before {
  content: "";
  z-index: 1;
  background: #222325;
  width: 5px;
  height: 10px;
  transition: all .3s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header__drop-menu-link span:after {
  content: "";
  background: var(--cl-green);
  width: 1px;
  height: 22px;
  transition: transform .3s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scaleY(0);
}

.header__socials-icon {
  border-radius: 4px;
}

.lang-selector {
  width: fit-content;
  position: relative;
}

.lang-selector.active .lang-selector__selected {
  border-radius: 6px 6px 0 0;
}

.lang-selector.active .lang-selector__selected:after {
  transform: rotate(180deg);
}

.lang-selector.active .lang-selector__dropdown {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.lang-selector__selected {
  z-index: 1;
  color: var(--cl-white);
  background: #1d2023;
  border: 1px solid #fff3;
  border-radius: 6px;
  justify-content: space-between;
  align-items: center;
  width: clamp(72px, 1.04167vw + 64px, 84px);
  padding: clamp(6px, .173611vw + 4.66667px, 8px) clamp(8px, .173611vw + 6.66667px, 10px);
  font-size: clamp(16px, .173611vw + 14.6667px, 18px);
  line-height: 1.22;
  transition: all .3s ease-in-out;
  display: flex;
  position: relative;
}

.lang-selector__selected:after {
  content: "";
  border: 6px solid #0000;
  border-top-width: 0;
  border-bottom-color: #ffffff80;
  width: 0;
  height: 0;
  transition: all .3s ease-in-out;
  transform: rotate(0);
}

.lang-selector__dropdown {
  opacity: 0;
  visibility: hidden;
  border: 1px solid #fff3;
  border-radius: 0 0 6px 6px;
  flex-direction: column;
  width: 100%;
  transition: all .3s ease-in-out;
  display: flex;
  position: absolute;
  top: 100%;
  overflow: hidden;
  transform: translateY(-20px);
}

.lang-selector__dropdown-item {
  color: #ffffff80;
  background: #08090a;
  width: 100%;
  padding: 8px 10px;
  font-size: 18px;
  line-height: 1.22;
}

.lang-selector__dropdown-item:not(:last-child) {
  border-bottom: 1px solid #fff3;
}

.header__content-mob {
  display: none;
}

@media not all and (width >= 651px) {
  .header__content-mob {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    justify-content: space-between;
    align-items: center;
    width: 100%;
    display: grid;
  }

  .header__content-mob > :nth-child(2) {
    justify-self: center;
  }

  .header__content-mob > :nth-child(3) {
    justify-self: flex-end;
  }
}

.header__content-mob .lang-selector {
  z-index: 999;
  position: relative;
}

.header__mob-menu {
  top: calc(var(--header-height)  - 3px);
  height: calc(100vh - var(--header-height)  - 3px);
  z-index: 998;
  opacity: 0;
  background: #000;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 52px 0 44px;
  transition: all .3s ease-in-out;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  transform: translateX(100%);
}

.header__mob-menu.active {
  opacity: 1;
  transform: translateX(0);
}

.header__mob-menu .header__bottom-wrap {
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  display: flex;
}

.header__mob-menu .header__mob-phone {
  color: #fff;
  margin: 0 auto 24px;
  font-size: 20px;
  transition: all .3s ease-in-out;
}

.header__mob-menu .header__mob-phone:hover {
  color: var(--cl-green);
}

.header__mob-menu .header__socials-wrap {
  gap: 36px;
}

.header__mob-menu-link {
  color: #ffffffb2;
  font-size: 20px;
  font-weight: 400;
  transition: all .3s ease-in-out;
}

.header__mob-menu-link:hover {
  color: #fff;
  font-weight: 600;
}

.footer {
  padding-top: clamp(15px, 11.7188vw - 75px, 150px);
  padding-bottom: clamp(32px, 1.5625vw + 20px, 50px);
}

.footer__nav-wrap {
  grid-template-columns: 1fr auto 1fr;
  gap: 10px;
  margin-bottom: 40px;
  display: grid;
}

@media not all and (width >= 1025px) {
  .footer__nav-wrap {
    grid-template-columns: 1fr;
  }
}

@media not all and (width >= 451px) {
  .footer__nav-wrap {
    gap: 20px;
  }
}

.footer__nav {
  align-items: center;
  gap: clamp(20px, 6.25vw - 70px, 50px);
  display: flex;
}

.footer__nav:last-child {
  justify-self: end;
}

@media not all and (width >= 1025px) {
  .footer__nav:last-child {
    justify-self: center;
  }

  .footer__nav:first-child {
    grid-row: 2;
    justify-self: center;
  }
}

@media not all and (width >= 451px) {
  .footer__nav {
    flex-direction: column;
    gap: 20px;
  }
}

.footer__nav-socials {
  align-items: center;
  gap: 16px;
  display: flex;
}

@media not all and (width >= 1025px) {
  .footer__nav-socials {
    display: none;
  }
}

.footer__logo {
  justify-self: center;
  width: 100%;
  max-width: 115px;
}

.footer__logo img {
  width: 100%;
}

@media not all and (width >= 769px) {
  .footer__logo {
    margin-bottom: 24px;
  }
}

.footer__nav-item {
  color: #ffffffb3;
  font-size: clamp(14px, .416667vw + 8px, 16px);
  line-height: 1.219;
  transition: color .3s;
}

.footer__nav-item:hover {
  color: var(--cl-green);
}

.footer__nav-socials-item {
  color: #00b355;
  border-radius: 4px;
  transition: color .3s;
  overflow: hidden;
}

.footer__nav-socials-item:hover .footer__nav-socials-icon {
  color: var(--cl-white);
}

.footer__nav-socials-icon {
  color: #00b355;
  border-radius: 4px;
  transition: color .3s;
}

.footer__contacts {
  flex-direction: column;
  display: flex;
}

.footer__phone {
  color: var(--cl-white);
  margin: 0 auto clamp(12px, .347222vw + 9.33333px, 16px);
  transition: color .3s;
}

.footer__phone:hover {
  color: var(--cl-green);
}

@media not all and (width >= 451px) {
  .footer__phone {
    font-size: 20px;
  }
}

.footer__bottom-wrap {
  grid-template-columns: 1fr auto 1fr;
  place-items: start;
  gap: clamp(10px, 1.73611vw - 3.33333px, 30px);
  font-size: clamp(14px, .173611vw + 12.6667px, 16px);
  line-height: 1.219;
  display: grid;
}

@media not all and (width >= 1025px) {
  .footer__bottom-wrap {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}

.footer__bottom-item {
  color: #ffffff80;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px clamp(10px, 4.16667vw - 50px, 30px);
  font-size: clamp(14px, .416667vw + 8px, 16px);
  display: flex;
}

@media not all and (width >= 1025px) {
  .footer__bottom-item:first-child {
    grid-row: 3;
  }
}

.footer__bottom-item:last-child {
  justify-self: end;
}

@media not all and (width >= 1025px) {
  .footer__bottom-item:last-child {
    grid-row: 2;
    justify-self: center;
  }

  .footer__bottom-item {
    text-align: center;
    justify-self: center;
  }
}

.footer__address {
  text-align: center;
  color: #ffffffb3;
  justify-self: center;
}

@media not all and (width >= 451px) {
  .footer__address {
    text-wrap: balance;
  }
}

.footer__credentials-link {
  color: #ffffff80;
  text-align: right;
  background: none;
  border: none;
  margin-left: auto;
  font-size: clamp(14px, .416667vw + 8px, 16px);
  text-decoration: underline;
  transition: color .3s;
  display: block;
}

.footer__credentials-link:hover {
  color: var(--cl-green);
}

@media not all and (width >= 1025px) {
  .footer__credentials-link {
    text-align: center;
    text-decoration: underline;
  }
}

@media not all and (width >= 551px) {
  .footer__credentials-link {
    margin-left: unset;
    width: 100%;
  }
}

.notify {
  z-index: 9999;
  backdrop-filter: blur(10px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  opacity: 0;
  background: #00000080;
  border: 1px solid #ffffff1a;
  border-radius: clamp(5px, .434028vw + 1.66667px, 10px);
  justify-content: center;
  align-items: center;
  width: clamp(200px, 8.68056vw + 133.333px, 300px);
  padding: clamp(10px, .868056vw + 3.33333px, 20px);
  font-size: 14px;
  transition: all .3s;
  display: flex;
  position: fixed;
  bottom: 20px;
  right: 20px;
  transform: translateX(110%);
  -webkit-backdrop-filter: blur(10px) !important;
}

.notify.error {
  border-color: #f003;
}

.notify.active {
  opacity: 1;
  transform: translateX(0);
}

.popup {
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  justify-content: center;
  align-items: center;
  transition: all .5s;
  display: flex;
  position: fixed;
  inset: 0;
}

.popup.is-open {
  visibility: visible;
  opacity: 1;
}

.popup {
  background: #00000080;
}

.popup__content-wrap {
  height: unset;
  background: var(--cl-black);
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  min-width: 300px;
  max-width: 1200px;
  max-height: min(90%, 700px);
  padding: clamp(20px, 2.60417vw, 50px);
  display: flex;
  position: relative;
  box-shadow: 0 0 20px #00000080;
}

@media not all and (width >= 769px) {
  .popup__content-wrap {
    height: auto;
    padding-top: 40px;
  }
}

.popup__content {
  width: 100%;
  height: 100%;
}

.popup__close-btn {
  z-index: 1;
  color: var(--cl-white);
  background: linear-gradient(-264.82deg, #16171a 0%, #2d3135 100%);
  border: none;
  border-radius: 999px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  transition: all .3s;
  display: flex;
  position: absolute;
  top: 0;
  right: 20px;
  transform: translateY(-50%);
}

.popup__close-btn:hover {
  color: var(--cl-red);
}

.popup__close-btn:hover:before, .popup__close-btn:hover:after {
  background: var(--cl-red);
}

@media not all and (width >= 769px) {
  .popup__close-btn {
    width: 30px;
    height: 30px;
    right: 10px;
  }
}

.popup__close-btn:before, .popup__close-btn:after {
  content: "";
  width: 15px;
  height: 2px;
  transition: all .3s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popup__close-btn:before {
  background: var(--cl-white);
  transform: translate(-50%, -50%)rotate(45deg);
}

.popup__close-btn:after {
  background: var(--cl-white);
  transform: translate(-50%, -50%)rotate(-45deg);
}

.popup__close-icon {
  width: 15px;
  height: 15px;
  display: none;
}

@media not all and (width >= 769px) {
  .popup__close-icon {
    width: 10px;
    height: 10px;
  }
}

@keyframes popupSlide {
  from {
    transform: translateY(var(--pp-default-translate-y-from)) translateX(var(--pp-default-translate-x-from));
    opacity: var(--pp-opacity-from);
  }

  to {
    transform: translateY(var(--pp-default-translate-y-to)) translateX(var(--pp-default-translate-x-to));
    opacity: var(--pp-opacity-to);
  }
}

@keyframes opacityPopupBg {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes gradientPopupBtn {
  0% {
    transform: translateX(-100%)skew(45deg);
  }

  100% {
    transform: translateX(100%)skew(45deg);
  }
}

.credentials-popup {
  text-align: justify;
  height: 100%;
  overflow: auto;
}

.credentials-popup h2 {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
}

.credentials-popup h2:not(:last-child) {
  margin-bottom: 20px;
}

.credentials-popup h3 {
  font-size: 20px;
  font-weight: 700;
}

.credentials-popup h3:not(:first-child) {
  margin-top: 20px;
}

.credentials-popup a {
  color: var(--cl-green);
  text-decoration: underline;
}

.credentials-popup a:hover {
  text-decoration: none;
}

.credentials-popup ul > li > ul > li {
  padding-left: 20px;
}

#public-offer-popup .popup__content-wrap, #privacy-policy-popup .popup__content-wrap {
  color: #000;
  aspect-ratio: 21 / 9;
  background: #fff;
  font-family: Tinos, serif;
}

@media not all and (width >= 769px) {
  #public-offer-popup .popup__content-wrap, #privacy-policy-popup .popup__content-wrap {
    aspect-ratio: unset;
    width: 95%;
    height: 100%;
  }
}

#public-offer-popup a, #privacy-policy-popup a {
  color: #00f;
}

.right-text {
  text-align: right;
}

.btn {
  --border-color: #2edc80;
  --bg-color: #094023;
  --hover-bg: radial-gradient(154.23% 100% at 50.15% 100%, #094023 0%, #188049 59.92%, #2fdd81 100%);
}

.btn.red {
  --border-color: #b50027;
  --bg-color: #3f000e;
  --hover-bg: radial-gradient(118.22% 100% at 50.15% 100%, #650016 0%, #85001d 60.31%, #b60128 100%);
}

.btn.purple {
  --border-color: #f375f2;
  --bg-color: #4f114e;
  --hover-bg: radial-gradient(118.22% 100% at 50.15% 100%, #8f1f7e 0%, 1 #b01f8b 60.31%, #f375f2 100%);
}

.btn.teal {
  --border-color: #00ffcb;
  --bg-color: #004e3e;
  --hover-bg: radial-gradient(118.22% 100% at 50.15% 100%, #004e3e 0%, #00765e 60.28%, #00b28d 100%);
}

.btn.w-full {
  width: 100%;
}

.btn.no-border {
  background: radial-gradient(100% 350.07% at 100%, #07331c 0%, #041b0f 100%);
  border: none;
  width: fit-content;
}

.btn.no-border:after {
  background: #094023;
  border: none;
  inset: 0;
}

.btn.no-border.red {
  background: radial-gradient(100% 722.87% at 100%, #3f000e 0%, #24040b 100%);
}

.btn.no-border.red:after {
  background: #650016;
}

.btn.is-loading {
  pointer-events: none;
  cursor: not-allowed;
}

.btn.is-loading .btn__loader {
  aspect-ratio: 1;
  --mask: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--mask);
  -webkit-mask: var(--mask);
  mask: var(--mask);
  -webkit-mask-composite: source-out;
  -webkit-mask-composite: source-out;
  background: #fff;
  border-radius: 50%;
  width: 20px;
  padding: 3px;
  animation: 1s linear infinite l3;
  mask-composite: subtract;
}

.btn.is-loading .btn__text {
  display: none;
}

.btn.sm {
  border-radius: clamp(6px, .173611vw + 4.66667px, 8px);
  padding: clamp(8px, .520833vw + 4px, 14px) 25px;
  font-size: clamp(12px, .347222vw + 9.33333px, 16px);
}

.btn.sm:before {
  inset: 1px;
}

.btn {
  background: var(--bg-color);
  border: none;
  border: 2px solid var(--border-color);
  color: var(--cl-white);
  text-transform: uppercase;
  border-radius: clamp(8px, .347222vw + 5.33333px, 12px);
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  min-width: clamp(150px, 4.34028vw + 116.667px, 200px);
  padding: clamp(14px, .173611vw + 12.6667px, 16px) 32px;
  font-size: clamp(12px, .347222vw + 9.33333px, 16px);
  font-weight: 600;
  line-height: 1.219;
  transition: all .5s;
  display: flex;
  position: relative;
}

@media not all and (width >= 551px) {
  .btn {
    width: 100%;
  }
}

.btn:not(:disabled):hover:after {
  opacity: 1;
}

.btn:after {
  content: "";
  border-radius: inherit;
  background: var(--hover-bg);
  opacity: 0;
  transition: all .5s;
  position: absolute;
  inset: -2px;
}

.btn:disabled {
  opacity: .5;
  filter: grayscale();
  cursor: not-allowed;
}

.btn__text {
  z-index: 2;
  font-weight: 600;
  position: relative;
}

.arrow-btn {
  aspect-ratio: 1;
  cursor: pointer;
  background: linear-gradient(-270deg, #fff0 0% 94.82%), linear-gradient(-270deg, #fff 0%, #fff0 94.82%);
  border: none;
  border-radius: 999px;
  justify-content: center;
  align-items: center;
  width: clamp(48px, 2.67857vw + 20.5714px, 72px);
  display: flex;
  position: relative;
  overflow: hidden;
}

.arrow-btn:before {
  content: "";
  z-index: 1;
  opacity: 0;
  background: linear-gradient(270deg, #fff0 0% 94.82%), linear-gradient(270deg, #fff 0%, #fff0 94.82%);
  border-radius: 999px;
  transition: all .5s;
  position: absolute;
  inset: 0;
}

.arrow-btn:after {
  content: "";
  z-index: 1;
  background: linear-gradient(-264.82deg, #16171a 0%, #2d3135 100%);
  border-radius: 999px;
  position: absolute;
  inset: 1px;
}

.arrow-btn:hover:before {
  opacity: 1;
}

.arrow-btn.revert {
  background: linear-gradient(270deg, #fff0 0% 94.82%), linear-gradient(270deg, #fff 0%, #fff0 94.82%);
}

.arrow-btn.revert:before {
  background: linear-gradient(-270deg, #fff0 0% 94.82%), linear-gradient(-270deg, #fff 0%, #fff0 94.82%);
}

.arrow-btn.revert:after {
  background: linear-gradient(-264.82deg, #16171a 0%, #2d3135 100%);
}

.arrow-btn.revert .arrow-btn__dot {
  right: unset;
  left: -20%;
  transform: translate(50%, -50%);
}

.arrow-btn.revert .arrow-btn__icon {
  transform: translateX(10%);
}

.arrow-btn.revert:hover .arrow-btn__icon {
  transform: translateX(5px);
}

.arrow-btn.revert:hover .arrow-btn__dot {
  left: calc(50% - 15px);
}

.arrow-btn .arrow-btn__icon {
  transform: translateX(-10%);
}

.arrow-btn:hover .arrow-btn__icon {
  transform: translateX(-5px);
}

.arrow-btn:hover .arrow-btn__dot {
  right: calc(50% - 15px);
}

.arrow-btn__icon {
  z-index: 2;
  color: var(--cl-white);
  width: clamp(18px, .868056vw + 11.3333px, 28px);
  transition: all .5s;
  position: relative;
}

.arrow-btn__dot {
  z-index: 2;
  background: var(--cl-white);
  border-radius: 50%;
  width: 6px;
  height: 6px;
  transition: all .5s;
  position: absolute;
  top: 50%;
  right: -20%;
  transform: translate(-50%, -50%);
}

.circle-btn {
  aspect-ratio: 1;
  background: linear-gradient(270deg, #ff494b 0%, #ff494b00 100%);
  border: none;
  border-radius: 999px;
  justify-content: center;
  align-items: center;
  width: clamp(54px, 3.64583vw + 26px, 96px);
  transition: all .5s;
  display: flex;
  position: relative;
}

.circle-btn:disabled {
  cursor: not-allowed;
  opacity: .5 !important;
}

.circle-btn.is-loading {
  cursor: not-allowed;
  pointer-events: none;
}

.circle-btn.is-loading .circle-btn__loader {
  display: block;
}

.circle-btn.is-loading .circle-btn__icon {
  display: none;
}

.circle-btn.purple {
  background: linear-gradient(270deg, #f475f3 0%, #f475f300 100%);
}

.circle-btn.purple .circle-btn__hover-bg {
  background: linear-gradient(-264.82deg, #f475f3 0% 100%);
}

.circle-btn.white {
  background: linear-gradient(270deg, #fff 0%, #fff0 100%);
}

.circle-btn.white .circle-btn__hover-bg {
  background: linear-gradient(-264.82deg, #fff 0% 100%);
}

.circle-btn:not(:disabled):hover {
  scale: 1.08;
}

.circle-btn:not(:disabled):hover .circle-btn__hover-bg {
  opacity: 1;
}

.circle-btn:after {
  content: "";
  z-index: 1;
  background: linear-gradient(-264.82deg, #16171a 0%, #2d3135 100%);
  border-radius: 999px;
  position: absolute;
  inset: 2px;
}

.circle-btn__hover-bg {
  z-index: 2;
  opacity: 0;
  background: linear-gradient(225deg, #16171a 0%, #7d2425 100%);
  border: 2px solid #ff494b;
  border-radius: 999px;
  transition: all .5s;
  position: absolute;
  inset: 1px;
}

.circle-btn__loader {
  z-index: 2;
  aspect-ratio: 1;
  --mask: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--mask);
  -webkit-mask: var(--mask);
  mask: var(--mask);
  -webkit-mask-composite: source-out;
  -webkit-mask-composite: source-out;
  background: #fff;
  border-radius: 50%;
  width: 20px;
  padding: 3px;
  animation: 1s linear infinite l3;
  display: none;
  position: relative;
  mask-composite: subtract;
}

.circle-btn__icon {
  z-index: 3;
  color: var(--cl-white);
  width: clamp(15px, 1.90972vw + .333333px, 37px);
  transition: all .5s;
  position: relative;
}

.simple-btn {
  background: linear-gradient(270deg, #fff0 0% 94.82%), linear-gradient(270deg, #fff 0%, #fff0 94.82%);
  border: none;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 12px;
  transition: all .3s;
  display: flex;
  position: relative;
}

.simple-btn:not(:disabled):hover:after {
  opacity: 1;
}

.simple-btn:disabled {
  opacity: .5;
  filter: grayscale();
  cursor: not-allowed;
}

@media not all and (width >= 769px) {
  .simple-btn {
    width: 100%;
  }
}

.simple-btn:before {
  content: "";
  z-index: 1;
  background: linear-gradient(264.82deg, #16171a 0%, #2d3135 100%);
  border-radius: 8px;
  position: absolute;
  inset: 1px;
}

.simple-btn:after {
  content: "";
  opacity: 0;
  background: #fff;
  border-radius: 8px;
  transition: all .3s;
  position: absolute;
  inset: 0;
}

.simple-btn.is-loading {
  pointer-events: none;
  cursor: not-allowed;
}

.simple-btn.is-loading .simple-btn__loader {
  z-index: 2;
  aspect-ratio: 1;
  --mask: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--mask);
  -webkit-mask: var(--mask);
  mask: var(--mask);
  -webkit-mask-composite: source-out;
  -webkit-mask-composite: source-out;
  background: #fff;
  border-radius: 50%;
  width: 20px;
  padding: 3px;
  animation: 1s linear infinite l3;
  position: relative;
  mask-composite: subtract;
}

.simple-btn.is-loading .simple-btn__text {
  display: none;
}

.simple-btn__text {
  z-index: 2;
  font-weight: 600;
  position: relative;
}

.simple-btn__text img {
  width: 40px;
  height: 40px;
}

@media not all and (width >= 769px) {
  .simple-btn__text img {
    width: 25px;
    height: 25px;
  }
}

@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

.input {
  color: var(--cl-white);
  background: #08090a;
  border: 1px solid #ffffff4d;
  border-radius: clamp(8px, .347222vw + 5.33333px, 12px);
  padding: clamp(12px, .694445vw + 6.66667px, 20px);
  font-size: clamp(14px, .173611vw + 12.6667px, 16px);
  line-height: 1.219;
}

.input::placeholder {
  color: #ffffff80;
}

.checkbox {
  color: var(--cl-white);
  align-items: center;
  gap: 10px;
  font-size: clamp(14px, .173611vw + 12.6667px, 16px);
  line-height: 1.219;
  display: flex;
}

.checkbox.red .checkbox__label a, .checkbox.red .checkbox__label button {
  color: var(--cl-red);
}

.checkbox__input-wrap {
  border: 1px solid #ffffff4d;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
}

.checkbox__input-wrap .checkbox__input {
  display: none;
}

.checkbox__input-wrap .checkbox__input:checked + .checkbox__check {
  opacity: 1;
  scale: 1;
}

.checkbox__check {
  opacity: 0;
  transition: all .3s;
  scale: .3;
}

.checkbox__label {
  color: #ffffffb3;
  font-size: clamp(14px, .173611vw + 12.6667px, 16px);
}

.checkbox__label a, .checkbox__label button {
  color: var(--cl-green);
  background: none;
  border: none;
  padding: 0;
  font-size: clamp(14px, .173611vw + 12.6667px, 16px);
  font-weight: 600;
  text-decoration: underline;
}

.hero-section {
  min-height: 100vh;
  padding-bottom: 20px;
  display: flex;
}

@media not all and (width >= 551px) {
  .hero-section {
    background: linear-gradient(#080a0b 0%, #090a0c 33.11%, #08090a 57.14%, #0d0f12 75.44%, #0e1114 100%);
    min-height: 815px;
    padding-bottom: 45px;
  }
}

.hero-section__bg-wrap {
  z-index: -1;
  background: #00000080;
  width: 100%;
  max-width: 1920px;
  height: 100%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (width >= 1920px) {
  .hero-section__bg-wrap {
    -webkit-mask-image: radial-gradient(#000 60%, #0000 80%);
    mask-image: radial-gradient(#000 60%, #0000 80%);
  }
}

@media not all and (width >= 551px) {
  .hero-section__bg-wrap {
    z-index: 0;
    max-height: 574px;
    -webkit-mask-image: linear-gradient(#000 0% 90%, #0000 95%);
    mask-image: linear-gradient(#000 0% 90%, #0000 95%);
  }
}

.hero-section__bg {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.hero {
  --padding-top: 0px;
  padding-top: var(--padding-top);
  height: 100%;
  display: flex;
}

@media not all and (width >= 551px) {
  .hero {
    padding-top: 280px;
  }
}

.hero__content {
  flex-direction: column;
  flex: auto;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 100%;
  display: flex;
}

.hero__title-wrap {
  z-index: 1;
  width: fit-content;
  margin: 0 auto;
  padding: clamp(0px, 4.94792vw - 38px, 57px) clamp(0px, 3.29861vw - 25.3333px, 38px) clamp(0px, 4.07986vw - 31.3333px, 47px);
  position: relative;
}

.hero__title-wrap:before {
  content: "";
  z-index: -1;
  filter: blur(30px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  background: #00000080;
  border-radius: 12px;
  display: block;
  position: absolute;
  inset: 0;
}

.hero__title, .hero__title-mob {
  text-align: center;
  font-family: var(--font-title);
  letter-spacing: .05rem;
  text-transform: uppercase;
  color: var(--cl-white);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: clamp(52px, 3.81944vw + 22.6667px, 96px);
  font-weight: 400;
  line-height: 1.05;
  display: flex;
}

@media not all and (width >= 601px) {
  .hero__title {
    display: none;
  }
}

.hero__title-mob {
  display: none;
}

@media not all and (width >= 601px) {
  .hero__title-mob {
    display: flex;
  }
}

.hero__title-shadowed {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(#999999b3, #ffffffb3);
  -webkit-background-clip: text;
}

.hero__list {
  justify-content: center;
  align-items: center;
  gap: clamp(5px, 6.07639vw - 41.6667px, 75px);
  margin-top: 4px;
  margin-bottom: clamp(10px, 2.60417vw - 10px, 40px);
  display: flex;
}

@media not all and (width >= 374px) {
  .hero__list {
    flex-wrap: wrap;
  }
}

.hero__list-item {
  color: var(--cl-green);
  letter-spacing: 4px;
  text-transform: uppercase;
  font-size: clamp(11px, 1.5625vw - 1px, 29px);
  font-weight: 300;
}

@media not all and (width >= 451px) {
  .hero__list-item {
    letter-spacing: .2em;
  }
}

.hero__btn {
  margin: 0 auto;
}

.hero__partners-title {
  z-index: 1;
  color: #ffffffb3;
  font-size: clamp(20px, 1.21528vw + 10.6667px, 34px);
  font-family: var(--font-title);
  letter-spacing: 2%;
  text-align: center;
  margin-bottom: 20px;
  position: relative;
}

.hero__partner-list {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 100%;
  margin-top: auto;
  display: flex;
}

@media not all and (width >= 1440px) {
  .hero__partner-list {
    gap: 12px 4px;
  }
}

.hero__partner-item {
  background: linear-gradient(150deg, #fff 26.09%, #fff0 44.42% 56.94%, #fff 72.14%);
  border-radius: clamp(6px, .520833vw + 2px, 12px);
  justify-content: center;
  align-items: center;
  width: clamp(111px, 10.9375vw + 27px, 237px);
  height: clamp(36px, 3.03819vw + 12.6667px, 71px);
  display: flex;
  position: relative;
}

@media not all and (width >= 1280px) {
  .hero__partner-item {
    padding: 9px 24px;
  }
}

@media not all and (width >= 551px) {
  .hero__partner-item {
    padding: 9px 12px;
  }

  .hero__partner-item.okx img {
    max-height: 12px;
  }
}

.hero__partner-item:nth-child(n+4) {
  background: linear-gradient(-150deg, #fff 26.09%, #fff0 44.42% 56.94%, #fff 72.14%);
}

.hero__partner-item:before {
  content: "";
  background: #1d2023;
  border-radius: clamp(6px, .520833vw + 2px, 12px);
  display: block;
  position: absolute;
  inset: 1px;
}

.hero__partner-item img {
  z-index: 1;
  position: relative;
}

@media not all and (width >= 1440px) {
  .hero__partner-item img {
    max-width: 80%;
  }
}

@media not all and (width >= 1280px) {
  .hero__partner-item img {
    width: 100%;
    max-width: 100%;
    max-height: clamp(20px, 1.95695vw + 4.95108px, 30px);
  }
}

@media not all and (width >= 551px) {
  .hero__partner-item img {
    max-height: 100%;
  }
}

.hero__additional-info {
  color: #ffffff80;
  text-align: center;
  margin-top: clamp(13px, 1.99653vw - 2.33333px, 36px);
  font-size: clamp(11px, .434028vw + 7.66667px, 16px);
  font-style: italic;
  line-height: 1.219;
}

canvas {
  width: 350px;
  height: 350px;
}

.section-about-us {
  padding-top: clamp(115px, 9.54861vw + 41.6667px, 225px);
  padding-bottom: clamp(100px, 22.1354vw - 70px, 355px);
}

.section-about-us:before {
  content: "";
  z-index: -1;
  aspect-ratio: 1920 / 671;
  background: url("../img/wave-bg.webp") center / cover no-repeat;
  min-width: 926px;
  position: absolute;
  bottom: -225px;
  left: 0;
  right: 0;
}

@media (width >= 1920px) {
  .section-about-us:before {
    bottom: -350px;
  }
}

@media not all and (width >= 769px) {
  .section-about-us:before {
    left: -150px;
  }
}

.about-us {
  justify-content: space-between;
  gap: clamp(23px, 6.68403vw - 28.3333px, 100px);
  display: flex;
}

@media not all and (width >= 1024px) {
  .about-us {
    flex-direction: column;
    gap: clamp(23px, 2.34375vw + 5px, 50px);
  }
}

.about-us__content {
  max-width: 805px;
}

.about-us__text {
  color: #ffffffb3;
  text-align: justify;
  font-size: clamp(14px, .173611vw + 12.6667px, 16px);
  line-height: 1.219;
}

.about-us__counters {
  flex-direction: column;
  gap: clamp(12px, 1.5625vw, 30px);
  width: 50%;
  max-width: 570px;
  display: flex;
}

@media not all and (width >= 1025px) {
  .about-us__counters {
    width: 100%;
    max-width: 500px;
  }
}

.about-us__counter {
  justify-content: space-between;
  display: flex;
}

.about-us__counter:not(:last-child) {
  border-bottom: 1px solid #fff3;
  padding-bottom: clamp(12px, 1.5625vw, 30px);
}

.about-us__counter-content {
  min-width: clamp(300px, 19.3576vw + 151.333px, 523px);
}

.about-us__counter-value {
  font-family: var(--font-title);
  margin-bottom: 8px;
  font-size: clamp(48px, 7.5vw - 48px, 96px);
  font-weight: 400;
  line-height: 1;
}

.about-us__counter-value .count-up {
  font-family: var(--font-title);
  font-size: clamp(48px, 7.5vw - 48px, 96px);
  font-weight: 400;
  line-height: 1;
}

.about-us__counter-value .plus {
  font-family: var(--font-main);
  font-size: clamp(48px, 5vw - 16px, 80px);
  font-weight: 600;
  line-height: 96px;
}

.about-us__counter-text {
  color: #ffffffb3;
  font-size: clamp(14px, .173611vw + 12.6667px, 16px);
  line-height: 1.219;
}

.about-us__counter-num {
  color: #fff3;
  letter-spacing: 3px;
  font-size: clamp(14px, .173611vw + 12.6667px, 16px);
  line-height: 1.219;
}

.achievements__top {
  --gap: clamp(24px, calc(24px + 56 * ((100vw - 1024px) / 896)), 80px);
  align-items: center;
  gap: var(--gap);
  margin-bottom: clamp(16px, 1.21528vw + 6.66667px, 30px);
  display: flex;
}

.achievements__top > * {
  flex-basis: calc(50% - var(--gap) / 2);
}

@media not all and (width >= 769px) {
  .achievements__top {
    flex-direction: column-reverse;
  }
}

.achievements__slider-nav {
  align-items: center;
  gap: clamp(20px, 6.25vw - 60px, 60px);
  display: flex;
}

@media not all and (width >= 769px) {
  .achievements__slider-nav {
    display: none;
  }
}

.achievements__title-wrap {
  justify-content: space-between;
  display: flex;
}

.achievements__title {
  margin-bottom: 0;
}

.achievements__bottom {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: clamp(24px, 6.25vw - 40px, 80px);
  display: grid;
}

@media not all and (width >= 769px) {
  .achievements__bottom {
    flex-direction: column;
    gap: 54px;
    display: flex;
  }
}

.achievements__tabs-slider-wrap {
  width: 100%;
  max-width: 100%;
}

.achievements__tabs-nav {
  background: linear-gradient(var(--cl-black), var(--cl-black)) padding-box, linear-gradient(200deg, #fff 35.39%, #fff0 49.76%, #fff0 54.23%, #fff 62.38%) border-box;
  border: 1px solid #0000;
  border-radius: clamp(6px, .173611vw + 4.66667px, 8px);
  justify-content: space-between;
  gap: clamp(0px, .434028vw - 3.33333px, 5px);
  padding: clamp(4px, .694445vw - 1.33333px, 12px);
  display: flex;
}

.achievements__tabs-nav-item {
  z-index: 1;
  color: #ffffff80;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: clamp(4px, .347222vw + 1.33333px, 8px);
  padding: clamp(6px, .868056vw - .666667px, 16px) clamp(8px, 1.38889vw - 2.66667px, 24px);
  font-size: clamp(14px, .173611vw + 12.6667px, 16px);
  font-weight: 300;
  line-height: 1;
  transition: all .5s;
  position: relative;
}

.achievements__tabs-nav-item:before {
  content: "";
  z-index: -1;
  opacity: 0;
  background: linear-gradient(264.82deg, #16171a 0%, #2d3135 100%);
  border-radius: clamp(4px, .347222vw + 1.33333px, 8px);
  position: absolute;
  inset: 0;
}

.achievements__tabs-nav-item.active {
  color: var(--cl-white);
  font-weight: 600;
}

.achievements__tabs-nav-item.active:before {
  opacity: 1;
}

.achievements__info-tab * {
  text-align: justify;
  font-size: clamp(14px, .173611vw + 12.6667px, 16px);
  line-height: 1.219;
}

.achievements__info-tab p:not(:last-child) {
  color: #ffffffb3;
  margin-bottom: clamp(20px, 2.60417vw, 50px);
}

.achievements__info-tab ul {
  padding-left: 20px;
}

.achievements__info-tab ul:not(:last-child) {
  margin-bottom: clamp(20px, 2.60417vw, 50px);
}

.achievements__info-tab ul li {
  list-style: disc;
}

.achievements__info-tab ul li:not(:last-child) {
  margin-bottom: clamp(9px, .694445vw + 3.66667px, 17px);
}

.achievements__info-tab ul li span {
  color: #fff9;
}

.achievements__screenshot {
  border-radius: clamp(0px, .694445vw - 5.33333px, 8px);
}

.achievements__screenshot-slider {
  border-radius: 8px;
  max-width: 100%;
}

@media not all and (width >= 769px) {
  .achievements__screenshot-slider {
    max-width: 550px;
  }
}

.achievements__screenshot-slider-nav {
  justify-content: center;
  align-items: center;
  gap: 48px;
  margin-top: 34px;
  display: none;
}

@media not all and (width >= 769px) {
  .achievements__screenshot-slider-nav {
    display: flex;
  }
}

.achievements__screenshot-slider-bullets {
  justify-content: center;
  align-items: center;
  margin-top: clamp(8px, 1.21528vw - 1.33333px, 22px);
  display: flex;
}

.achievements__screenshot-slider-bullets .swiper-pagination-bullet {
  --swiper-pagination-bullet-horizontal-gap: 6px;
  opacity: 1;
  background: #2d3135;
  border-radius: 999px;
  width: 100%;
  max-width: clamp(13px, 1.38889vw + 2.33333px, 29px);
  height: clamp(3px, .260417vw + 1px, 6px);
  transition: all .5s;
}

.achievements__screenshot-slider-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: var(--cl-white);
}

.scalping-section {
  backdrop-filter: blur(75px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-backdrop-filter: blur(75px) !important;
}

.scalping__title {
  text-align: center;
  margin-bottom: 30px;
}

.scalping__text {
  color: #ffffffb3;
  text-align: center;
  max-width: 1412px;
  margin: 0 auto clamp(30px, 5.64236vw - 13.3333px, 95px);
  font-size: clamp(14px, .173611vw + 12.6667px, 16px);
  line-height: 1.219;
}

@media not all and (width >= 501px) {
  .scalping__text {
    text-align: justify;
  }
}

.scalping__video-wrap {
  aspect-ratio: 3125 / 1400;
  width: 100%;
  max-width: 1477px;
  margin: 0 auto 50px;
  position: relative;
}

@media not all and (width >= 551px) {
  .scalping__video-wrap {
    aspect-ratio: 3125 / 2500;
  }
}

.scalping__video-pc {
  max-width: unset;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  aspect-ratio: 3224 / 983;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media not all and (width >= 551px) {
  .scalping__video-pc {
    top: 59%;
  }
}

.scalping__play-btn {
  position: absolute;
  top: 20.5%;
  left: 50%;
  transform: translateX(-50%);
}

@media not all and (width >= 551px) {
  .scalping__play-btn {
    top: 28%;
  }
}

.scalping__more-btn {
  margin: 0 auto;
}

.section-wrap {
  clip-path: inset(3px 0 2px);
  position: relative;
  overflow: hidden;
}

.glow-svg {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.glow-svg line, .glow-svg circle {
  stroke: #c54a4c;
  fill: #c54a4c;
  stroke-width: 200px;
  stroke-linecap: round;
}

@media (pointer: coarse) {
  .glow-svg {
    display: none;
  }
}

.glow {
  z-index: -1;
  color: #fff;
  border-radius: 50%;
  font-size: 20px;
  line-height: 1.219;
  transition: opacity .4s .2s, scale .4s .2s;
  display: inline-block;
  position: absolute;
}

.glow-sm {
  background: linear-gradient(90deg, #ff8082 0%, #c54a4c 100%);
  width: 150px;
  height: 150px;
}

.glow-xl {
  background: linear-gradient(90deg, #ff494b 0%, #b5191b 100%);
  width: 300px;
  height: 300px;
}

.scalping-videos-slider__wrap {
  align-items: center;
  gap: clamp(10px, .868056vw + 3.33333px, 20px);
  height: 100%;
  display: flex;
}

@media not all and (width >= 769px) {
  .scalping-videos-slider__wrap {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px 48px;
  }
}

.scalping-videos-slider {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@media not all and (width >= 769px) {
  .scalping-videos-slider {
    aspect-ratio: 5 / 4;
    order: -1;
    height: auto;
  }
}

.scalping-videos-slider__nav-btn {
  flex-shrink: 0;
}

.scalping__video-wrap-text {
  color: #fd494b;
  text-align: center;
  text-transform: uppercase;
  font-size: clamp(10px, 1.21528vw + .666666px, 24px);
  line-height: 1;
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%);
}

@media not all and (width >= 550px) {
  .scalping__video-wrap-text {
    width: 100%;
    top: 67%;
  }
}

.scalping__video-wrap-text:before {
  content: "";
  z-index: 1;
  background: url("../img/fade-arrow.svg") center / contain no-repeat;
  width: clamp(14px, .868056vw + 7.33333px, 24px);
  height: clamp(30px, 4.42708vw - 4px, 81px);
  display: block;
  position: absolute;
  bottom: calc(100% + clamp(6px, .868056vw - .666667px, 16px));
  left: 50%;
  transform: translateX(-50%);
}

@media not all and (width >= 769px) {
  .scalping__video-wrap-text:before {
    display: none;
  }
}

@media not all and (width >= 551px) {
  .project-section .container {
    width: 92%;
  }
}

.projects__title {
  text-align: center;
}

.projects__card-list {
  grid-template-columns: repeat(2, minmax(300px, 1fr));
  gap: clamp(24px, 2.5vw - 8px, 40px);
  margin-bottom: clamp(20px, 2.60417vw, 50px);
  display: grid;
}

@media not all and (width >= 1024px) {
  .projects__card-list {
    grid-template-columns: 1fr;
    margin-bottom: 0;
  }
}

.project-card {
  --bg-blur-color: #0a3c22;
  cursor: pointer;
  background: #040404 0 0 / contain;
  border: clamp(1px, .0868056vw + .333333px, 2px) solid #ffffff4d;
  border-radius: clamp(12px, 1.04167vw + 4px, 24px);
  padding: clamp(16px, 1.21528vw + 6.66667px, 30px) clamp(16px, 2.95139vw - 6.66667px, 50px);
  transition: all .5s;
  position: relative;
}

.project-card:hover {
  border-color: #2eda7f;
}

.project-card:hover:before {
  opacity: 1;
}

.project-card.red {
  --bg-blur-color: #520001;
}

@media not all and (width >= 551px) {
  .project-card.red {
    background-size: 100%;
  }
}

.project-card.red:after {
  background: url("../img/project-red-bg.jpeg") top no-repeat;
}

.project-card.red:hover {
  border-color: #ff5254;
}

.project-card:before {
  content: "";
  background: radial-gradient(76.81% 28.66% at 50% 100%, var(--bg-blur-color) 0%, #040404 100%);
  opacity: 0;
  border-radius: 24px;
  transition: all .5s;
  position: absolute;
  inset: 0;
}

.project-card:after {
  content: "";
  z-index: 1;
  background: url("../img/project-green-bg.jpeg") top no-repeat;
  border-radius: 24px;
  position: absolute;
  inset: 0;
}

.project-card__top-wrap {
  z-index: 2;
  border-bottom: 1px solid #ffffff4d;
  justify-content: space-between;
  gap: clamp(17px, .46875vw + 11px, 20px);
  margin-bottom: clamp(12px, 2.00893vw - 8.57143px, 30px);
  display: flex;
  position: relative;
}

.project-card__top-left {
  z-index: 2;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 10px;
  display: flex;
  position: relative;
}

.project-card__logo {
  z-index: 2;
  aspect-ratio: 176 / 68;
  max-width: clamp(97px, 11.6319vw + 7.66666px, 231px);
  position: relative;
}

.project-card__title {
  font-family: var(--font-title);
  font-size: clamp(30px, 3.79464vw - 8.85714px, 64px);
  font-weight: 400;
  line-height: 1;
}

.project-card__top-right {
  padding-right: clamp(10px, 6.25vw - 70px, 50px);
}

@media not all and (width >= 551px) {
  .project-card__top-right {
    max-width: 114px;
  }
}

.project-card__info {
  z-index: 2;
  color: #ffffffb3;
  text-align: justify;
  margin-bottom: clamp(18px, 1.33929vw + 4.28571px, 30px);
  font-size: clamp(14px, .173611vw + 12.6667px, 16px);
  line-height: 1.219;
  position: relative;
}

.project-card__more-btn {
  z-index: 2;
  margin-top: auto;
  position: relative;
}

.owners-section {
  z-index: 1;
  position: relative;
}

@media not all and (width >= 501px) {
  .owners-section {
    padding-bottom: 200px;
  }
}

.owners-section__title {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(162.41deg, #fff0 50.46%, #ff494b99 107.02%), linear-gradient(200.44deg, #fff 55.82%, #149a2f80 97.61%);
  -webkit-background-clip: text;
  width: fit-content;
  margin: 0 auto;
}

.owners-section__bg {
  z-index: -1;
  pointer-events: none;
  opacity: .8;
  position: absolute;
}

@media not all and (width >= 1440px) {
  .owners-section__bg {
    max-width: clamp(650px, 34.3474vw + 340.53px, 1000px);
  }
}

.owners-section__bg.green {
  bottom: -550px;
  left: 0;
}

@media not all and (width >= 1440px) {
  .owners-section__bg.green {
    bottom: -250px;
  }
}

@media not all and (width >= 901px) {
  .owners-section__bg.green {
    bottom: unset;
    max-width: 1000px;
    top: 0;
  }
}

@media not all and (width >= 768px) {
  .owners-section__bg.green {
    max-width: 1000px;
    top: 100px;
    left: -50px;
  }
}

@media not all and (width >= 501px) {
  .owners-section__bg.green {
    width: 135%;
    max-width: 1500px;
    top: 50px;
  }
}

.owners-section__bg.red {
  top: -700px;
  right: 0;
}

@media not all and (width >= 1440px) {
  .owners-section__bg.red {
    top: -250px;
  }
}

@media not all and (width >= 901px) {
  .owners-section__bg.red {
    top: unset;
    bottom: 0;
  }
}

@media not all and (width >= 501px) {
  .owners-section__bg.red {
    width: 125%;
    max-width: 1500px;
    bottom: -300px;
    right: -50px;
  }
}

.owners {
  justify-content: center;
  display: flex;
}

@media not all and (width >= 901px) {
  .owners {
    flex-direction: column;
  }
}

.owners__item {
  position: relative;
}

.owners__item.petro {
  margin-right: -150px;
}

.owners__item.petro .owners__item-img-wrap {
  max-width: clamp(350px, 29.9479vw + 120px, 695px);
}

@media not all and (width >= 901px) {
  .owners__item.petro .owners__item-img-wrap {
    max-width: clamp(350px, 100vw - 51px, 500px);
    margin-left: auto;
  }
}

@media not all and (width >= 768px) {
  .owners__item.petro .owners__item-img-wrap {
    margin-top: -80px;
    margin-right: -70px;
  }
}

.owners__item.petro .owners__item-info {
  top: clamp(150px, 450px - 15.625vw, 200px);
  left: clamp(-280px, 200px - 25vw, -200px);
}

@media not all and (width >= 1740px) {
  .owners__item.petro .owners__item-info {
    top: 450px;
    left: -100px;
  }
}

@media not all and (width >= 1280px) {
  .owners__item.petro .owners__item-info {
    top: 450px;
    left: -100px;
  }
}

@media not all and (width >= 901px) {
  .owners__item.petro .owners__item-info {
    left: unset;
    top: unset;
    bottom: 0;
    right: 0;
  }
}

@media not all and (width >= 501px) {
  .owners__item.petro .owners__item-info {
    bottom: -50px;
  }
}

@media not all and (width >= 901px) {
  .owners__item.petro {
    margin-right: 0;
  }
}

.owners__item.danylo {
  margin-left: clamp(-50px, 33.3333px - 4.34028vw, 0px);
}

.owners__item.danylo .owners__item-img-wrap {
  max-width: clamp(350px, 45.0521vw + 4px, 869px);
}

@media not all and (width >= 901px) {
  .owners__item.danylo .owners__item-img-wrap {
    max-width: clamp(350px, 100vw - 51px, 500px);
  }
}

@media not all and (width >= 769px) {
  .owners__item.danylo .owners__item-img-wrap {
    margin-left: -50px;
  }
}

.owners__item.danylo .owners__item-info {
  bottom: 50px;
  right: -170px;
}

@media not all and (width >= 1440px) {
  .owners__item.danylo .owners__item-info {
    right: -100px;
  }
}

@media not all and (width >= 901px) {
  .owners__item.danylo .owners__item-info {
    bottom: 0;
    right: unset;
    left: 0;
  }
}

@media not all and (width >= 501px) {
  .owners__item.danylo .owners__item-info {
    bottom: -90px;
  }
}

.owners__item-info {
  background: #00000080;
  border: 1px solid #ffffff4d;
  border-radius: 16px;
  max-width: clamp(343px, 9.54861vw + 269.667px, 453px);
  padding: clamp(16px, 2.1875vw - 12px, 30px) clamp(12px, 2.8125vw - 24px, 30px);
  position: absolute;
}

@media not all and (width >= 551px) {
  .owners__item-info {
    max-width: 100%;
  }
}

.owners__item-title {
  font-family: var(--font-title);
  text-transform: uppercase;
  font-size: clamp(40px, 2.08333vw + 24px, 64px);
  font-weight: 400;
  line-height: 1;
}

.owners__item-descr {
  border-bottom: 1px solid #ffffff4d;
  margin-bottom: clamp(12px, .694445vw + 6.66667px, 20px);
  padding-bottom: clamp(12px, .694445vw + 6.66667px, 20px);
  font-size: clamp(14px, .347222vw + 11.3333px, 18px);
  line-height: 1.22;
}

.owners__item-list-item {
  color: #ffffffb3;
  padding-left: 1.5ch;
  font-size: clamp(14px, .173611vw + 12.6667px, 16px);
  line-height: 1.22;
  position: relative;
}

.owners__item-list-item:before {
  content: "•";
  position: absolute;
  left: 0;
}

.blog-section {
  overflow: visible;
}

.blog-section__bg-wrap {
  z-index: -1;
  aspect-ratio: 3264 / 2307;
  clip-path: inset(0 0 3px);
  width: 170%;
  position: absolute;
  top: 32%;
  left: 31%;
  transform: translate(-50%, -50%);
  -webkit-mask-image: linear-gradient(#0000 0%, #000 30% 100%, #0000 100%);
  mask-image: linear-gradient(#0000 0%, #000 30% 100%, #0000 100%);
}

.blog-section__title {
  text-align: center;
}

.blog-grid {
  grid-template-columns: repeat(4, 1fr);
  gap: clamp(12px, 5.83333vw - 72px, 40px);
  display: grid;
}

@media not all and (width >= 1440px) {
  .blog-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media not all and (width >= 1024px) {
  .blog-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media not all and (width >= 768px) {
  .blog-grid {
    grid-template-columns: 1fr;
  }
}

.blog-grid .blog-card.hidden {
  display: none;
}

@media (width >= 1440px) {
  .blog-grid .blog-card:nth-child(6n+1), .blog-grid .blog-card:nth-child(6n+6) {
    grid-area: span 1 / span 2;
  }

  .blog-grid .blog-card:nth-child(6n+2), .blog-grid .blog-card:nth-child(6n+3), .blog-grid .blog-card:nth-child(6n+4), .blog-grid .blog-card:nth-child(6n+5) {
    grid-area: span 2 / span 1;
    min-height: clamp(200px, 127.407vw - 1902.22px, 544px);
  }

  .blog-grid .blog-card:nth-child(6n+2) .blog-card__main-info, .blog-grid .blog-card:nth-child(6n+3) .blog-card__main-info, .blog-grid .blog-card:nth-child(6n+4) .blog-card__main-info, .blog-grid .blog-card:nth-child(6n+5) .blog-card__main-info {
    flex-direction: column;
    gap: 16px;
  }

  .blog-grid .blog-card:nth-child(6n+2) .blog-card__preview-wrap, .blog-grid .blog-card:nth-child(6n+3) .blog-card__preview-wrap, .blog-grid .blog-card:nth-child(6n+4) .blog-card__preview-wrap, .blog-grid .blog-card:nth-child(6n+5) .blog-card__preview-wrap {
    aspect-ratio: 355 / 198;
  }

  .blog-grid .blog-card:nth-child(6n+2) .blog-card__date, .blog-grid .blog-card:nth-child(6n+3) .blog-card__date, .blog-grid .blog-card:nth-child(6n+4) .blog-card__date, .blog-grid .blog-card:nth-child(6n+5) .blog-card__date {
    margin-top: auto;
  }
}

@media not all and (width >= 1440px) {
  @media (width >= 768px) {
    .blog-grid .blog-card .blog-card__main-info {
      flex-direction: column;
      gap: 16px;
    }

    .blog-grid .blog-card .blog-card__preview-wrap {
      aspect-ratio: 355 / 198;
    }

    .blog-grid .blog-card .blog-card__date {
      margin-top: auto;
    }
  }
}

.blog-card {
  color: var(--cl-white);
  background: #00000052;
  border: 1px solid #ffffff4d;
  border-radius: clamp(12px, 1.04167vw + 4px, 24px);
  flex-direction: column;
  padding: clamp(12px, .694445vw + 6.66667px, 20px);
  transition: border-color .3s;
  display: flex;
}

.blog-card:hover {
  border-color: var(--cl-white);
}

.blog-card.vertical .blog-card__main-info {
  flex-direction: column;
  gap: 16px;
}

.blog-card.vertical .blog-card__preview-wrap {
  aspect-ratio: 355 / 198;
}

.blog-card__main-info {
  gap: clamp(12px, .694445vw + 6.66667px, 20px);
  margin-bottom: 20px;
  display: flex;
}

@media not all and (width >= 501px) {
  .blog-card__main-info {
    grid-template-columns: 1.35fr minmax(0, 1fr);
    display: grid;
  }
}

.blog-card__preview-wrap {
  aspect-ratio: 420 / 257;
  border: 1px solid #fff3;
  border-radius: 12px;
  width: 100%;
  max-width: 420px;
  overflow: hidden;
}

.blog-card__preview {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  width: 100%;
  height: 100%;
}

.blog-card__info {
  width: 100%;
  max-width: 350px;
}

.blog-card__logo-wrap {
  align-items: center;
  gap: clamp(6px, .347222vw + 3.33333px, 10px);
  margin-bottom: clamp(8px, 1.04167vw, 20px);
  display: flex;
}

@media not all and (width >= 551px) {
  .blog-card__logo-wrap {
    gap: 2px;
  }
}

.blog-card__logo {
  max-width: clamp(58px, 7.20486vw + 2.66666px, 141px);
}

@media not all and (width >= 551px) {
  .blog-card__logo:not(:last-child) {
    width: 20px;
    height: 20px;
  }
}

.blog-card__logo-text {
  font-size: 16px;
  font-weight: 500;
}

@media not all and (width >= 551px) {
  .blog-card__logo-text {
    font-family: Inter, sans-serif;
    font-size: 10px;
  }
}

.blog-card__title {
  font-family: var(--font-title);
  margin-bottom: 4px;
  font-size: clamp(26px, 1.90972vw + 11.3333px, 48px);
  font-weight: 400;
  line-height: 1;
}

.blog-card__descr {
  color: #ffffffb3;
  font-size: clamp(12px, .347222vw + 9.33333px, 16px);
  line-height: 1.22;
}

@media not all and (width >= 501px) {
  .blog-card__descr {
    display: none;
  }
}

.blog-card__descr-mob {
  color: #ffffffb3;
  grid-column: span 2;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.22;
  display: none;
}

@media not all and (width >= 501px) {
  .blog-card__descr-mob {
    display: block;
  }
}

.blog-card__date {
  text-align: right;
  color: #ffffff80;
  border-top: 1px solid #ffffff4d;
  margin-top: auto;
  padding-top: 12px;
  font-size: clamp(12px, .173611vw + 10.6667px, 14px);
  line-height: 1.21;
}

.blog-section__btn {
  color: #ffffffb3;
  text-transform: uppercase;
  background: none;
  border: none;
  justify-content: center;
  margin: clamp(24px, 2.25694vw + 6.66667px, 50px) auto 0;
  font-size: clamp(14px, .173611vw + 12.6667px, 16px);
  font-weight: 400;
  line-height: 1.219;
  text-decoration: underline;
  display: flex;
}

.blog-section__btn.hidden {
  display: none;
}

.socials-section {
  z-index: 1;
}

.socials-section__bg-line {
  z-index: -1;
  -o-object-fit: fill;
  object-fit: fill;
  pointer-events: none;
  width: 100%;
  min-width: 1100px;
  height: 100%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -25%);
}

@media not all and (width >= 1025px) {
  .socials-section__bg-line {
    transform: translate(-50%, -50%);
  }
}

.socials-section__title {
  text-align: center;
  margin-bottom: clamp(12px, 3.125vw - 12px, 48px);
}

.socials {
  --columns: 3;
  --gap: clamp(12px, calc(12px + 4 * ((100vw - 768px) / 1152)), 16px);
  justify-content: center;
  align-items: center;
  gap: var(--gap);
  flex-wrap: wrap;
  display: flex;
}

@media not all and (width >= 1025px) {
  .socials {
    --columns: 2;
  }
}

@media not all and (width >= 769px) {
  .socials {
    --columns: 1;
  }
}

.social-card {
  --width: calc((100% - (var(--columns)  - 1) * var(--gap)) / var(--columns));
  width: var(--width);
  aspect-ratio: 556 / 138;
  color: var(--cl-white);
  background: #00000080;
  border: 1px solid #ffffff4d;
  border-radius: 8px;
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 7px;
  padding: 14px 14px 12px 20px;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media not all and (width >= 769px) {
  .social-card {
    max-width: 400px;
  }
}

.social-card:before {
  content: "";
  opacity: 0;
  background: linear-gradient(264.82deg, #16171a 0%, #212427 64.87%, #2d3135 100%);
  transition: opacity .3s ease-in-out;
  position: absolute;
  inset: 0;
}

.social-card:hover:before {
  opacity: 1;
}

.social-card:hover .social-card__subscribers {
  color: var(--cl-white);
}

.social-card:hover .social-card__icon {
  filter: brightness();
}

.social-card__title-wrap {
  z-index: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
  font-weight: 400;
  display: flex;
  position: relative;
}

.social-card__info {
  z-index: 1;
  justify-content: space-between;
  align-items: end;
  display: flex;
  position: relative;
}

@media not all and (width >= 1440px) {
  .social-card__info {
    flex-shrink: 0;
  }
}

.social-card__subscribers {
  color: #6c7279;
  margin-bottom: -2.6em;
  transition: color .3s ease-in-out;
}

.social-card__subscribers .count-up {
  font-family: var(--font-title);
  font-size: clamp(86px, 11.25vw - 76px, 140px);
  font-weight: 400;
  line-height: 1;
  display: inline-block;
}

.social-card__subscribers .plus {
  font-size: clamp(37px, 1.99653vw + 21.6667px, 60px);
  font-weight: 800;
  line-height: 1.219;
  display: inline-block;
  transform: translateY(-.4em);
}

.social-card__title {
  color: #ffffff80;
  text-align: right;
  max-width: clamp(100px, 7.55208vw + 42px, 187px);
  font-size: clamp(12px, .833333vw - 9.53674e-7px, 16px);
  line-height: 1.219;
}

.social-card__icon {
  filter: brightness(0) invert();
  transition: filter .3s ease-in-out;
}

.social-card__icon.instagram {
  --hover-color: #ff543e;
}

.social-card__icon.telegram {
  --hover-color: #2aabee;
}

.social-card__icon.discord {
  --hover-color: #5865f2;
}

.social-card__icon.youtube {
  --hover-color: #ef3737;
}

.contact-form {
  background: linear-gradient(216deg, #fff 25.97%, #fff0 36.63% 67.82%, #fff 78.13%);
  border-radius: clamp(16px, 1.73611vw + 2.66667px, 36px);
  padding: clamp(32px, 4.16667vw, 80px) clamp(24px, 4.86111vw - 13.3333px, 80px);
  position: relative;
}

.contact-form:before {
  content: "";
  background: linear-gradient(#16181b 0%, #08090a 100%);
  border-radius: clamp(16px, 1.73611vw + 2.66667px, 36px);
  position: absolute;
  inset: clamp(1px, .15625vw - 1px, 2px);
}

.contact-form > * {
  z-index: 1;
  position: relative;
}

.contact-form__inner-wrap {
  width: fit-content;
  margin: 0 auto;
}

.contact-us-section__title {
  text-align: center;
  margin-bottom: 8px;
  font-size: clamp(48px, 4.51389vw + 13.3333px, 100px);
}

.contact-us_description {
  color: #ffffffb3;
  text-align: center;
  margin-bottom: clamp(24px, 3.125vw, 60px);
  font-size: clamp(14px, .173611vw + 12.6667px, 16px);
  line-height: 1.219;
}

.contact-form__row {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: clamp(12px, .694445vw + 6.66667px, 20px);
  margin-bottom: clamp(12px, 1.5625vw, 30px);
  display: grid;
}

.contact-form__row:last-child {
  margin-bottom: clamp(32px, 2.43056vw + 13.3333px, 60px);
}

.contact-form__row.row-1 {
  grid-template-columns: 1fr;
}

@media not all and (width >= 769px) {
  .contact-form__row {
    grid-template-columns: 1fr;
  }
}

.contact-form__btn {
  margin: 0 auto;
}

.university-hero-section {
  padding-top: 0;
}

.university-hero-section .container {
  width: 93%;
}

.university-hero-section__decor-wrap {
  opacity: .75;
  mix-blend-mode: lighten;
  position: absolute;
}

.university-hero-section__decor-wrap.left {
  top: 1%;
  left: 0;
}

.university-hero-section__decor-wrap.top {
  top: 0;
  left: 0;
}

@media not all and (width >= 551px) {
  .university-hero-section__decor-wrap.top {
    display: none;
  }
}

.university-hero-section__decor-wrap.top.mob {
  display: none;
}

@media not all and (width >= 551px) {
  .university-hero-section__decor-wrap.top.mob {
    display: block;
  }
}

@media not all and (width >= 769px) {
  .university-hero {
    display: none;
  }
}

.university-hero__title-wrap {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.university-hero__scalping-wrap, .university-hero__why-wrap, .university-hero__dollar-wrap {
  position: relative;
}

.university-hero__dollar-wrap {
  width: clamp(250px, 17.5347vw + 115.333px, 452px);
}

.university-hero__dollar-text-item {
  color: #2af895;
  text-transform: uppercase;
  border-radius: 8px;
  width: max-content;
  padding: clamp(8px, 1.04167vw, 20px) clamp(19px, 4.42708vw - 15px, 70px);
  font-size: clamp(12px, .694445vw + 6.66667px, 20px);
  font-weight: 400;
  position: absolute;
}

.university-hero__dollar-text-item.item-1 {
  background: linear-gradient(250deg, #2af8954d 0%, #2af89505 100%);
  top: 12%;
  right: -60%;
}

.university-hero__dollar-text-item.item-2 {
  background: linear-gradient(250deg, #2af8954d 0%, #2af89505 100%);
  top: 23%;
  right: -92%;
}

.university-hero__dollar-text-item.item-3 {
  background: linear-gradient(95.13deg, #2af8954d 0%, #2af89505 100%);
  bottom: 18%;
  left: -71%;
}

.university-hero__dollar-text-item.item-4 {
  background: linear-gradient(95.13deg, #2af8954d 0%, #2af89505 100%);
  bottom: 7%;
  left: -91%;
}

@media not all and (width >= 1280px) {
  .university-hero__dollar-text-item.item-4 {
    left: -70%;
  }
}

.university-hero__why, .university-hero__scalping {
  font-family: var(--font-title);
  letter-spacing: .4rem;
  width: fit-content;
  font-size: clamp(48px, 12.066vw - 44.6667px, 187px);
  font-weight: 400;
  line-height: .95;
}

.university-hero__scalping-descr {
  text-align: justify;
  color: #ffffff80;
  max-width: 782px;
  font-size: clamp(14px, .347222vw + 11.3333px, 18px);
  font-weight: 400;
  position: absolute;
  top: calc(100% + clamp(15px, 1.30208vw + 5px, 30px));
  right: 0;
}

.university-hero__btn {
  margin-top: 40px;
}

.university-hero__logo {
  max-width: clamp(107px, 7.63889vw + 48.3333px, 195px);
  position: absolute;
  bottom: calc(100% + clamp(15px, 1.30208vw + 5px, 30px));
  left: 50%;
  transform: translateX(-50%);
}

.university-hero-mob {
  z-index: 1;
  padding-top: 90px;
  display: none;
  position: relative;
}

@media not all and (width >= 769px) {
  .university-hero-mob {
    display: block;
  }
}

.university-hero-mob__title {
  letter-spacing: .04rem;
  margin-bottom: 7px;
}

.university-hero-mob__descr {
  color: #ffffff80;
  text-align: justify;
  max-width: 500px;
  margin: 0 auto 32px;
  font-size: 14px;
  line-height: 1.219;
}

.university-hero-mob__dollar-wrap {
  max-width: clamp(250px, 22.831vw + 124.429px, 300px);
  margin: 0 auto 24px;
  position: relative;
  transform: translateX(clamp(-70px, 31.9635vw - 245.799px, 0px));
}

@media not all and (width >= 450px) {
  .university-hero-mob__dollar-wrap {
    max-width: 200px;
    transform: translateX(-40px);
  }
}

.university-hero-mob__dollar-logo {
  width: clamp(107px, 33.3333vw - 76.3333px, 180px);
  position: absolute;
  top: 5%;
  left: -20%;
}

@media not all and (width >= 550px) {
  .university-hero-mob__dollar-logo {
    left: -10%;
  }
}

.university-hero-mob__dollar-text-item {
  color: #2af895;
  text-transform: uppercase;
  background: linear-gradient(250deg, #2af8954d 0%, #2af89505 100%);
  border-radius: 4px;
  width: max-content;
  padding: clamp(8px, 2.75229vw - 7.13761px, 14px) clamp(12px, 6.42202vw - 23.3211px, 26px);
  font-size: clamp(12px, 2.75229vw - 3.13761px, 18px);
  font-weight: 400;
  position: absolute;
}

.university-hero-mob__dollar-text-item.item-1 {
  top: 13%;
  right: -60%;
}

@media not all and (width >= 550px) {
  .university-hero-mob__dollar-text-item.item-1 {
    right: -40%;
  }
}

@media not all and (width >= 450px) {
  .university-hero-mob__dollar-text-item.item-1 {
    right: -46%;
  }
}

.university-hero-mob__dollar-text-item.item-2 {
  top: 24%;
  right: -60%;
}

@media not all and (width >= 550px) {
  .university-hero-mob__dollar-text-item.item-2 {
    top: 22%;
    right: -42%;
  }
}

@media not all and (width >= 450px) {
  .university-hero-mob__dollar-text-item.item-2 {
    top: 23%;
    right: -46%;
  }
}

.university-hero-mob__dollar-text-item.item-3 {
  bottom: 15%;
  right: -50%;
}

@media not all and (width >= 550px) {
  .university-hero-mob__dollar-text-item.item-3 {
    bottom: 20%;
    right: -35%;
  }
}

@media not all and (width >= 450px) {
  .university-hero-mob__dollar-text-item.item-3 {
    bottom: 20%;
    right: -42%;
  }
}

.university-hero-mob__dollar-text-item.item-4 {
  bottom: 4%;
  right: -65%;
}

@media not all and (width >= 550px) {
  .university-hero-mob__dollar-text-item.item-4 {
    bottom: 12%;
    right: -43%;
  }
}

@media not all and (width >= 450px) {
  .university-hero-mob__dollar-text-item.item-4 {
    bottom: 10%;
    right: -47%;
  }
}

.studets-stat__text .blur {
  filter: blur(3px);
}

.advantages-section___title {
  text-align: center;
}

@media not all and (width >= 551px) {
  .advantages-section___title {
    margin-bottom: 12px;
  }
}

.advantages__list {
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(12px, 1.5625vw, 30px);
  display: grid;
}

@media not all and (width >= 1281px) {
  .advantages__list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media not all and (width >= 769px) {
  .advantages__list {
    grid-template-columns: 1fr;
  }
}

.advantages__item {
  background-color: #00000080;
  background-image: url("../img/adv-bg-1.webp");
  background-position: center;
  background-size: cover;
  border: 1px solid #ffffff4d;
  border-radius: 12px;
  padding: clamp(16px, 2.08333vw, 40px) clamp(16px, 2.08333vw, 40px) clamp(24px, 3.90625vw - 6px, 69px);
}

.advantages__item:nth-child(2) {
  background-image: url("../img/adv-bg-2.webp");
}

.advantages__item:nth-child(3) {
  background-image: url("../img/adv-bg-3.webp");
}

.advantages__item:nth-child(4) {
  background-image: url("../img/adv-bg-4.webp");
}

.advantages__item:nth-child(5) {
  background-image: url("../img/adv-bg-5.webp");
}

.advantages__item:nth-child(6) {
  background-image: url("../img/adv-bg-6.webp");
}

@media not all and (width >= 769px) {
  .advantages__item {
    max-width: 400px;
    margin: 0 auto;
  }
}

.advantages__item-icon {
  -o-object-fit: contain;
  object-fit: contain;
  width: clamp(101px, 6.59722vw + 50.3333px, 177px);
  height: clamp(89px, 7.03125vw + 35px, 170px);
  margin: 0 auto;
  display: block;
}

.advantages__item-text {
  border-top: 1px solid #ffffff4d;
  margin-top: clamp(16px, 2.08333vw, 40px);
  padding-top: clamp(16px, 1.21528vw + 6.66667px, 30px);
}

.advantages__item-title {
  font-family: var(--font-title);
  text-align: center;
  margin-bottom: clamp(8px, 1.04167vw, 20px);
  font-size: clamp(32px, .434028vw + 28.6667px, 37px);
  line-height: 1;
}

.advantages__item-desc {
  color: #ffffffb2;
  text-align: center;
  text-wrap: balance;
  font-size: clamp(14px, .347222vw + 11.3333px, 18px);
  line-height: 1.219;
}

.instruments-section__title {
  text-align: center;
}

@media not all and (width >= 551px) {
  .instruments-section__title {
    margin-bottom: 12px;
  }
}

.instruments {
  grid-template-columns: 1fr 1.2fr 1fr;
  gap: clamp(12px, 1.5625vw, 30px);
  display: grid;
}

@media not all and (width >= 1025px) {
  .instruments {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}

.instruments .instrument-card:nth-child(5n+1) {
  aspect-ratio: unset;
  grid-area: 1 / 2 / span 2;
}

@media not all and (width >= 1025px) {
  .instruments .instrument-card:nth-child(5n+1) {
    grid-column: 1;
    grid-row: unset;
    min-height: 426px;
  }
}

.instruments .instrument-card:nth-child(5n+1) .instrument-card__inner-wrap {
  background: #000;
}

.instruments .instrument-card:nth-child(5n+1) .instrument-card__bg-wrap {
  -webkit-mask-image: none;
  mask-image: none;
}

.instruments .instrument-card:nth-child(5n+1) .instrument-card__bg-wrap:before {
  content: "";
  background: linear-gradient(#08090a 0%, #08090ac3 19.65%, #08090a00 42.65% 69.94%, #08090a 100%);
  position: absolute;
  inset: 0;
}

.instruments .instrument-card:nth-child(5n+1) .instrument-card__num {
  position: absolute;
  top: 12px;
  left: 12px;
}

.instruments .instrument-card:nth-child(5n+1) .instrument-card__title {
  text-align: center;
  margin-top: 0;
}

.instruments .instrument-card:nth-child(5n+1) .instrument-card__descr {
  text-align: center;
  min-height: unset;
}

.instruments .instrument-card:nth-child(5n+2) {
  background: linear-gradient(300deg, #fff 0%, #fff0 59.75% 75.3%, #fff 100%);
  grid-area: span 1 / 1 / auto / 2;
}

@media not all and (width >= 1025px) {
  .instruments .instrument-card:nth-child(5n+2) {
    grid-column: 1;
    grid-row: unset;
  }
}

.instruments .instrument-card:nth-child(5n+3) {
  background: linear-gradient(62.68deg, #fff 0%, #fff0 59.75% 75.3%, #fff 100%);
  grid-area: span 1 / 1 / auto / 2;
}

@media not all and (width >= 1025px) {
  .instruments .instrument-card:nth-child(5n+3) {
    grid-column: 1;
    grid-row: unset;
  }
}

@media not all and (width >= 451px) {
  .instruments .instrument-card:nth-child(5n+3) .instrument-card__bg {
    -o-object-position: 90px -40px;
    object-position: 90px -40px;
    -o-object-fit: contain;
    object-fit: contain;
    max-width: unset;
    min-width: 380px;
    height: auto;
  }
}

.instruments .instrument-card:nth-child(5n+4) {
  background: linear-gradient(240deg, #fff 0%, #fff0 59.75% 75.3%, #fff 100%);
  grid-area: 1 / 3 / auto / 3;
}

@media not all and (width >= 1025px) {
  .instruments .instrument-card:nth-child(5n+4) {
    grid-column: 1;
    grid-row: unset;
  }
}

.instruments .instrument-card:nth-child(5n+5) {
  background: linear-gradient(140deg, #fff 0%, #fff0 59.75% 75.3%, #fff 100%);
  grid-area: span 1 / 3 / auto / 3;
}

@media not all and (width >= 1025px) {
  .instruments .instrument-card:nth-child(5n+5) {
    grid-column: 1;
    grid-row: unset;
  }
}

@media not all and (width >= 451px) {
  .instruments .instrument-card:nth-child(5n+5) .instrument-card__bg {
    -o-object-position: 50px -65px;
    object-position: 50px -65px;
    -o-object-fit: contain;
    object-fit: contain;
    max-width: unset;
    min-width: 440px;
    height: auto;
  }
}

.instrument-card {
  aspect-ratio: 512 / 368;
  background: linear-gradient(#fff 0%, #fff0 59.75% 75.3%, #fff 100%);
  border-radius: 12px;
  padding: clamp(12px, .694445vw + 6.66667px, 20px);
  position: relative;
}

.instrument-card:before {
  content: "";
  background: #000;
  border-radius: 12px;
  position: absolute;
  inset: 1px;
}

@media not all and (width >= 1281px) {
  .instrument-card {
    aspect-ratio: 512 / 512;
  }
}

@media not all and (width >= 1025px) {
  .instrument-card {
    aspect-ratio: unset;
    width: 100%;
    max-width: 600px;
    min-height: 258px;
  }
}

.instrument-card__inner-wrap {
  background: #ffffff1a;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

@media not all and (width >= 769px) {
  .instrument-card__inner-wrap {
    background: #ffffff0d;
  }
}

.instrument-card__bg-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  -webkit-mask-image: radial-gradient(circle at 60% 20%, #000 0%, #0000 65% 100%);
  mask-image: radial-gradient(circle at 60% 20%, #000 0%, #0000 65% 100%);
}

.instrument-card__bg {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.instrument-card__content {
  z-index: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 12px 12px 20px;
  display: flex;
  position: relative;
}

.instrument-card__num {
  color: #fff9;
  border: 1px solid #fff3;
  border-radius: 8px;
  width: fit-content;
  padding: clamp(4px, .347222vw + 1.33333px, 8px) clamp(6px, .520833vw + 2px, 12px);
  font-size: clamp(12px, .347222vw + 9.33333px, 16px);
  line-height: 1.2;
}

.instrument-card__title {
  font-family: var(--font-title);
  margin-top: auto;
  margin-bottom: 12px;
  font-size: clamp(32px, 1.38889vw + 21.3333px, 48px);
  font-weight: 400;
  line-height: 1;
}

.instrument-card__descr {
  color: #ffffffb2;
  min-height: 5em;
  font-size: clamp(14px, .173611vw + 12.6667px, 16px);
  line-height: 1.2;
}

@media not all and (width >= 1025px) {
  .instrument-card__descr {
    min-height: unset;
  }
}

.presentation {
  justify-content: end;
  align-items: center;
  gap: clamp(25px, 8.94097vw - 43.6667px, 128px);
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  position: relative;
}

@media not all and (width >= 1025px) {
  .presentation {
    flex-direction: column;
  }
}

.presentation-section__bg-wrap {
  z-index: -1;
  opacity: .5;
  filter: blur(10px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  width: calc(100% + 187px);
  position: absolute;
  top: 15%;
  right: 0;
  transform: scaleX(-1);
}

@media not all and (width >= 769px) {
  .presentation-section__bg-wrap {
    top: unset;
    right: unset;
    filter: blur(3px);
    width: 100%;
    min-width: 1000px;
    bottom: -41%;
    left: -70%;
    transform: scaleX(-1)rotate(-10deg);
  }
}

.presentation-section__bg-wrap:before {
  content: "";
  background: linear-gradient(#08090a 0%, #08090a00 29.8% 69.6%, #08090a 100%);
  position: absolute;
  inset: 0;
}

.presentation__blur-logo {
  z-index: -1;
  filter: blur(10px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  opacity: .25;
  width: 100%;
  position: absolute;
  top: 39%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media not all and (width >= 769px) {
  .presentation__blur-logo {
    filter: blur(4px);
  }
}

@media not all and (width >= 551px) {
  .presentation__blur-logo {
    filter: blur(3px);
    z-index: 1;
  }
}

.presentation__text-wrap {
  flex-direction: column;
  gap: clamp(20px, 1.73611vw + 6.66667px, 40px);
  display: flex;
}

.presentation__text {
  text-align: justify;
  max-width: clamp(500px, 14.4965vw + 388.667px, 667px);
  font-size: clamp(14px, .520833vw + 10px, 20px);
  line-height: 1.219;
}

.presentation__button {
  z-index: 1;
  background: none;
  border: none;
  max-width: clamp(253px, 28.2118vw + 36.3333px, 578px);
  position: relative;
}

.presentation__button .presentation__button-img {
  transition: all .3s;
}

.presentation__button:hover .presentation__button-img {
  filter: drop-shadow(12px 12px 120px #2af89580);
}

#presentation-popup .popup__content-wrap {
  height: unset;
  aspect-ratio: 16 / 9;
  filter: drop-shadow(12px 12px 120px #2af89533);
}

.presentation-popup {
  height: 100%;
}

.tariffs-section__title {
  text-align: center;
  margin-bottom: 109px;
}

@media not all and (width >= 551px) {
  .tariffs-section__title {
    margin-bottom: 75px;
  }
}

.tariffs-section__bg {
  pointer-events: none;
  mix-blend-mode: lighten;
  opacity: .6;
  position: absolute;
  top: -40%;
  left: 0;
}

@media not all and (width >= 901px) {
  .tariffs-section__bg {
    top: -10%;
  }
}

@media not all and (width >= 551px) {
  .tariffs-section__bg {
    top: -7%;
  }
}

.tariffs {
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(24px, .520833vw + 20px, 30px);
  display: grid;
}

@media not all and (width >= 901px) {
  .tariffs {
    grid-template-columns: 1fr;
    gap: 90px;
  }
}

.tariff {
  background: linear-gradient(#fff 0%, #9990 17.29%, #fff 51.97%);
  border-radius: clamp(8px, .347222vw + 5.33333px, 12px);
  padding: 0 clamp(6px, .669643vw - .857143px, 12px) clamp(6px, .669643vw - .857143px, 12px);
  position: relative;
}

@media not all and (width >= 901px) {
  .tariff {
    width: 100%;
    max-width: 547px;
    margin: 0 auto;
  }
}

.tariff:before {
  content: "";
  background: #000;
  border-radius: clamp(8px, .347222vw + 5.33333px, 12px);
  position: absolute;
  inset: 1px;
}

.tariff__title, .tariff__descr, .tariff__price, .tariff__program, .tariff__icon, .tariff__loan-info {
  z-index: 1;
  position: relative;
}

.tariff__decor {
  border-radius: clamp(16px, .694445vw + 10.6667px, 24px);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.tariff__icon {
  width: clamp(160px, 6.13839vw + 97.1429px, 215px);
  margin: clamp(-79px, -2.51736vw - 30.6667px, -50px) auto clamp(8px, .892857vw - 1.14286px, 16px);
  display: block;
}

.tariff__title {
  font-family: var(--font-title);
  text-align: center;
  margin-bottom: clamp(8px, 3.34821vw - 26.2857px, 38px);
  font-size: clamp(60px, 4.01786vw + 18.8571px, 96px);
  line-height: 1;
}

.tariff__descr {
  text-align: center;
  color: #ffffffb3;
  min-height: 6rem;
  margin-bottom: clamp(20px, 2.45536vw - 5.14286px, 42px);
  font-size: clamp(14px, .223214vw + 11.7143px, 16px);
}

.tariff__price {
  background: #ffffff1f;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  gap: clamp(6px, .446429vw + 1.42857px, 10px);
  width: fit-content;
  min-width: clamp(140px, 9.59822vw + 41.7143px, 226px);
  margin: 0 auto clamp(15px, .558036vw + 9.28571px, 20px);
  padding: clamp(8px, .446429vw + 3.42857px, 12px) clamp(15px, 1.11607vw + 3.57143px, 25px);
  display: flex;
}

.tariff__price-value {
  font-family: var(--font-title);
  font-size: clamp(50px, 3.34821vw + 15.7143px, 80px);
  line-height: 1;
}

.tariff__price-currency {
  color: #2af895;
  font-family: var(--font-title);
  font-size: clamp(24px, 1.78571vw + 5.71429px, 40px);
  line-height: 1;
}

.tariff__additional-text {
  z-index: 1;
  color: #ffffff80;
  text-align: center;
  margin-bottom: clamp(8px, .607639vw + 3.33333px, 15px);
  font-size: clamp(12px, .223214vw + 9.71429px, 14px);
  font-style: italic;
  line-height: 1.219;
  position: relative;
}

.tariff__program-title {
  margin-bottom: clamp(10px, 1.11607vw - 1.42857px, 20px);
  font-size: clamp(14px, .669643vw + 7.14286px, 20px);
  font-weight: 600;
  line-height: 1.219;
}

.tariff__program-list {
  flex-direction: column;
  gap: clamp(10px, 1.11607vw - 1.42857px, 20px);
  margin-bottom: clamp(24px, 1.78571vw + 5.71429px, 40px);
  display: flex;
}

.tariff__program-item.disabled {
  opacity: .6;
}

.tariff__program-item.disabled .tariff__program-item-main:before {
  background: url("../img/close.svg") center / contain no-repeat;
}

.tariff__program-item-main {
  align-items: center;
  font-size: clamp(14px, .223214vw + 11.7143px, 16px);
  line-height: 1.219;
  display: flex;
}

.tariff__program-item-main:before {
  content: "";
  background: url("../img/check.svg") center / contain no-repeat;
  flex-shrink: 0;
  align-self: start;
  width: clamp(13px, .446429vw + 8.42857px, 17px);
  height: clamp(13px, .446429vw + 8.42857px, 17px);
  margin-right: clamp(7px, .260417vw + 5px, 10px);
  display: block;
  position: relative;
  top: .2em;
}

.tariff__program-item-main:not(:last-child) {
  margin-bottom: clamp(10px, 1.11607vw - 1.42857px, 20px);
}

.tariff__program-item-main-icon {
  margin-right: 3px;
}

.tariff__program-item-additional {
  color: #2af895;
  font-size: clamp(14px, .223214vw + 11.7143px, 16px);
}

.tariff__loan-info {
  color: #ffffffb2;
  text-align: center;
  margin-top: clamp(8px, .446429vw + 3.42857px, 12px);
  font-size: clamp(12px, .446429vw + 7.42857px, 16px);
  font-style: italic;
  line-height: 1.219;
}

.tariff__additional-info {
  z-index: 1;
  justify-content: space-between;
  gap: clamp(10px, 1.11607vw - 1.42857px, 20px);
  margin-top: clamp(10px, 1.67411vw - 7.14286px, 25px);
  display: flex;
  position: relative;
}

.tariff__additional-info-item {
  flex-wrap: wrap;
  align-items: center;
  gap: 0 8px;
  font-size: clamp(14px, .416667vw + 8px, 16px);
  line-height: 1.219;
  display: flex;
}

.tariff__additional-info-item:first-child:last-child {
  justify-content: center;
  width: 100%;
}

.tariff__additional-info-item-title {
  color: #ffffffb3;
}

.tariff__additional-info-item-text span {
  font-weight: 600;
}

.payment-info__wrap {
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(24px, .520833vw + 20px, 30px);
  margin-top: clamp(24px, .520833vw + 20px, 30px);
  display: grid;
}

@media not all and (width >= 1281px) {
  .payment-info__wrap {
    grid-template-columns: 1fr;
  }
}

.payment-info {
  border: 1px solid var(--cl-white);
  border-radius: clamp(16px, .694445vw + 10.6667px, 24px);
  grid-column: 2 / span 2;
  align-items: center;
  gap: 15px clamp(24px, 11.25vw - 138px, 78px);
  padding: clamp(10px, 2.79018vw - 18.5714px, 35px) clamp(10px, 3.90625vw - 30px, 45px) clamp(10px, 2.79018vw - 18.5714px, 35px) clamp(10px, 2.79018vw - 18.5714px, 35px);
  font-size: clamp(14px, .223214vw + 11.7143px, 16px);
  line-height: 1.219;
  display: flex;
}

@media not all and (width >= 1281px) {
  .payment-info {
    grid-column: 1;
  }
}

@media not all and (width >= 701px) {
  .payment-info {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.payment-info__title {
  color: #ffffffb3;
  margin-right: auto;
  font-size: clamp(14px, .669643vw + 7.14286px, 20px);
}

.payment-info__title strong {
  color: var(--cl-white);
  font-weight: 600;
}

@media not all and (width >= 701px) {
  .payment-info__title {
    text-align: center;
    width: 100%;
    font-size: 18px;
  }
}

.payment-info__item {
  flex-direction: column;
  gap: 4px;
  display: flex;
}

.payment-info__item-title {
  color: #ffffffb3;
  font-size: 16px;
  font-style: italic;
}

.payment-info__item-logo {
  -o-object-fit: contain;
  object-fit: contain;
  width: clamp(150px, 8.50695vw + 84.6667px, 248px);
  height: clamp(27px, .868056vw + 20.3333px, 37px);
}

.tariff__bf-label {
  z-index: 2;
  width: clamp(150px, 8.03572vw + 67.7143px, 222px);
  height: clamp(150px, 8.03572vw + 67.7143px, 222px);
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(15%, -15%);
}

@media not all and (width >= 901px) {
  .tariff__bf-label {
    width: 222px;
    height: 222px;
  }
}

@media not all and (width >= 501px) {
  .tariff__bf-label {
    width: 170px;
    height: 170px;
  }
}

.tariff__old-price {
  font-family: var(--font-title);
  color: #ffffff80;
  font-size: clamp(24px, 1.78571vw + 5.71429px, 40px);
  text-decoration: line-through;
}

.tariff__corner-asset {
  z-index: 1;
  pointer-events: none;
  border-radius: 0 12px 0 0;
  width: 33%;
  position: absolute;
  top: 1px;
  right: 1px;
  overflow: hidden;
}

.studets-stats-section__title {
  text-align: center;
}

.studets-stats {
  align-items: center;
  gap: 20px;
  display: flex;
}

@media not all and (width >= 769px) {
  .studets-stats {
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px 48px;
  }
}

.studets-stat {
  aspect-ratio: 682 / 543;
  background: linear-gradient(248.33deg, #fff 25.97%, #fff0 36.63% 67.82%, #fff 78.13%);
  border-radius: clamp(10px, 1.11607vw - 1.42857px, 20px);
  flex-direction: column;
  padding: clamp(15px, 1.67411vw - 2.14286px, 30px);
  display: flex;
  position: relative;
}

.studets-stat:before {
  content: "";
  border-radius: inherit;
  background: linear-gradient(#26292d 0%, #08090a 100%);
  position: absolute;
  inset: clamp(1px, .15625vw - 1px, 2px);
}

.studets-stat__info {
  z-index: 1;
  gap: clamp(8px, .892857vw - 1.14286px, 16px);
  display: flex;
  position: relative;
}

.studets-stat__img {
  border-radius: 50%;
  width: clamp(32px, 2.45536vw + 6.85714px, 54px);
  height: clamp(32px, 2.45536vw + 6.85714px, 54px);
}

.studets-stat__name {
  margin-bottom: clamp(2px, .223214vw - .285714px, 4px);
  font-size: clamp(14px, .669643vw + 7.14286px, 20px);
  font-weight: 500;
  line-height: 1.2;
}

.studets-stat__text {
  font-size: clamp(10px, .223214vw + 7.71429px, 12px);
  line-height: 1.2;
}

.studets-stat__screen {
  z-index: 1;
  width: 100%;
  margin: auto 0;
  position: relative;
}

.studets-stat__screen-img {
  width: 100%;
  height: 100%;
}

.studets-stats-slider__nav-btn {
  flex-shrink: 0;
}

@media not all and (width >= 769px) {
  .studets-stats-slider__nav-btn {
    order: 2;
  }
}

.studets-stats-slider {
  flex: 1;
  width: 100%;
  max-width: clamp(450px, 20.1389vw + 295.333px, 682px);
  margin: 0 auto;
}

@media not all and (width >= 769px) {
  .studets-stats-slider {
    max-width: 100%;
  }
}

.studets-stats-slider .swiper-slide {
  overflow: visible;
}

.studets-stats-slider .swiper-slide.swiper-slide-active .participants-deal {
  display: block;
  box-shadow: 0 0 150px 50px #000;
}

.reviews-section__title {
  text-align: center;
}

.reviews {
  min-height: clamp(700px, 44.6429vw + 242.857px, 1100px);
  margin-top: -70px;
  padding-top: clamp(100px, 11.1607vw - 14.2857px, 200px);
  display: flex;
  position: relative;
}

@media not all and (width >= 1025px) {
  .reviews {
    margin-top: -60px;
    padding-top: 130px;
  }
}

.reviews__phone-wrap {
  width: 100%;
  max-width: clamp(400px, 24.2188vw + 90px, 555px);
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media not all and (width >= 769px) {
  .reviews__phone-wrap {
    max-width: 340px;
  }
}

.reviews__phone-wrap:before {
  content: "";
  z-index: -1;
  filter: blur(110px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  opacity: .5;
  background: #ffffff26;
  border-radius: 50%;
  width: 706px;
  height: 910px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.reviews__content {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.reviews__slider-wrap {
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  width: 100%;
  margin: 0 auto;
  display: flex;
}

@media not all and (width >= 1025px) {
  .reviews__slider-wrap {
    justify-content: center;
  }
}

.reviews-slider {
  max-width: clamp(220px, 9.98264vw + 143.333px, 335px);
  height: clamp(480px, 35.4167vw - 30px, 650px);
  margin-bottom: 20px;
}

@media not all and (width >= 769px) {
  .reviews-slider {
    max-width: 214px;
    height: 400px;
    margin-bottom: 0;
  }
}

.reviews-slider .swiper-slide {
  opacity: 0;
  filter: blur(3px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  align-items: center;
  transition: all .2s;
  display: flex;
}

.reviews-slider .swiper-slide.swiper-slide-active {
  filter: none;
}

.reviews-slider .swiper-slide.swiper-slide-active .review {
  backdrop-filter: blur(10px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-backdrop-filter: blur(10px) !important;
}

.reviews-slider .swiper-slide.swiper-slide-prev, .reviews-slider .swiper-slide.swiper-slide-next, .reviews-slider .swiper-slide.swiper-slide-active {
  opacity: 1;
}

.reviews-slider__btn {
  z-index: 2;
  flex-shrink: 0;
}

@media not all and (width >= 1025px) {
  .reviews-slider__btn:not(.reviews-slider__btn.mob) {
    display: none;
  }
}

.review {
  backdrop-filter: blur();
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  background: #001e0e80;
  border: 1px solid #2af895;
  border-radius: 16px 16px 16px 0;
  padding: clamp(12px, .954861vw + 4.66667px, 23px) clamp(12px, 1.12847vw + 3.33333px, 25px);
  transition: all .3s .1s;
  position: relative;
  -webkit-backdrop-filter: blur() !important;
}

.review__name {
  margin-bottom: 4px;
  font-family: Inter, sans-serif;
  font-size: clamp(12px, .173611vw + 10.6667px, 14px);
  font-weight: 600;
}

.review__text {
  color: #fffc;
  letter-spacing: .01em;
  scrollbar-width: none;
  max-height: clamp(300px, 10.4167vw + 220px, 420px);
  margin-bottom: clamp(2px, .520833vw - 2px, 8px);
  font-family: Inter, sans-serif;
  font-size: clamp(12px, .347222vw + 9.33333px, 16px);
  font-weight: 300;
  line-height: 1.4;
  overflow: auto;
}

.review__text::-webkit-scrollbar {
  display: none;
}

.review__reactions {
  color: var(--cl-white);
  background: #ffffff1a;
  border-radius: 999px;
  width: fit-content;
  padding: clamp(4px, .173611vw + 2.66667px, 6px) clamp(6px, .347222vw + 3.33333px, 10px);
  font-size: clamp(10px, .347222vw + 7.33333px, 14px);
}

.review__more-btn {
  border-radius: clamp(8px, .446429vw + 3.42857px, 12px);
  padding: clamp(12px, 1.25vw - 4px, 20px) clamp(24px, 1.25vw + 8px, 32px);
}

.review__more-btn .btn__text {
  font-size: clamp(12px, .446429vw + 7.42857px, 16px);
}

.review__slider-nav-mob {
  display: none;
}

@media not all and (width >= 1025px) {
  .review__slider-nav-mob {
    gap: 48px;
    margin-top: 88px;
    display: flex;
  }
}

.our-partners {
  background: linear-gradient(0deg, #272a2e 0%, #272a2e00 100%);
  border-radius: clamp(12px, 1.04167vw + 4px, 24px);
  padding: clamp(20px, 2.60417vw, 50px) clamp(30px, 3.47222vw + 3.33333px, 70px);
  position: relative;
}

.our-partners:before {
  content: "";
  z-index: 1;
  border-radius: inherit;
  background: linear-gradient(#1e2024 0%, #15171a 49.83%, #0e1011 100%);
  display: block;
  position: absolute;
  inset: 1px;
}

.our-partners__title {
  z-index: 1;
  color: #ffffffb3;
  letter-spacing: 2%;
  text-align: center;
  margin-bottom: clamp(10px, .868056vw + 3.33333px, 20px);
  font-size: clamp(24px, 1.5625vw + 12px, 42px);
  position: relative;
}

.our-partners__list {
  z-index: 1;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 100%;
  margin-top: auto;
  display: flex;
  position: relative;
}

@media not all and (width >= 1440px) {
  .our-partners__list {
    gap: 12px clamp(4px, .782779vw - 2.01957px, 8px);
  }
}

.our-partners__item {
  background: linear-gradient(150deg, #fff 26.09%, #fff0 44.42% 56.94%, #fff 72.14%);
  border-radius: clamp(6px, .520833vw + 2px, 12px);
  flex: 1;
  justify-content: center;
  align-items: center;
  min-width: 111px;
  height: clamp(36px, 3.03819vw + 12.6667px, 71px);
  display: flex;
  position: relative;
}

@media not all and (width >= 1280px) {
  .our-partners__item {
    padding: 9px 24px;
  }
}

@media not all and (width >= 551px) {
  .our-partners__item {
    padding: 9px 12px;
  }

  .our-partners__item.okx img {
    max-height: 12px;
  }
}

.our-partners__item:nth-child(n+4) {
  background: linear-gradient(-150deg, #fff 26.09%, #fff0 44.42% 56.94%, #fff 72.14%);
}

.our-partners__item:before {
  content: "";
  background: #1d2023;
  border-radius: clamp(6px, .520833vw + 2px, 12px);
  display: block;
  position: absolute;
  inset: 1px;
}

.our-partners__item img {
  z-index: 1;
  position: relative;
}

@media not all and (width >= 1440px) {
  .our-partners__item img {
    max-width: 80%;
  }
}

@media not all and (width >= 1280px) {
  .our-partners__item img {
    width: 100%;
    max-width: 100%;
    max-height: clamp(20px, 1.95695vw + 4.95108px, 30px);
  }
}

@media not all and (width >= 551px) {
  .our-partners__item img {
    max-height: 100%;
  }
}

.live-trading-hero {
  padding-top: clamp(89px, 4.77431vw + 52.3333px, 144px);
  display: flex;
}

@media not all and (width >= 769px) {
  .live-trading-hero {
    min-height: 700px;
    max-height: 850px;
    padding-bottom: 30px;
  }

  .live-trading-hero .container {
    min-height: 100%;
  }

  .live-trading-hero__btn-start {
    margin-top: 30px;
  }

  .live-trading-hero__btn-start .btn__text {
    font-size: 20px;
  }
}

.live-trading-hero__bg-wrap {
  z-index: -1;
  max-width: clamp(1000px, 28.8194vw + 778.667px, 1332px);
  max-height: 100%;
  position: absolute;
  top: clamp(100px, 3.81944vw + 70.6667px, 144px);
  left: clamp(-200px, 31.2989vw - 600.939px, 0px);
  -webkit-mask-image: linear-gradient(#0000, #000 5% 95%, #0000);
  mask-image: linear-gradient(#0000, #000 5% 95%, #0000);
}

@media not all and (width >= 769px) {
  .live-trading-hero__bg-wrap {
    top: 90px;
    left: -150px;
  }
}

@media not all and (width >= 551px) {
  .live-trading-hero__bg-wrap {
    width: 164%;
  }
}

.live-trading-hero__content {
  flex-direction: column;
  align-items: end;
  height: 100%;
  display: flex;
}

@media not all and (width >= 769px) {
  .live-trading-hero__content {
    height: 100%;
  }
}

.live-trading-hero__title {
  font-family: var(--font-title);
  text-align: right;
  letter-spacing: .04rem;
  text-wrap: balance;
  max-width: clamp(600px, 38.8889vw + 301.333px, 1048px);
  margin-bottom: 30px;
  font-size: clamp(39px, 4.16362vw + 16.0584px, 96px);
  font-weight: 400;
  line-height: .95;
}

.scalping-effectivity-section__main-wrap {
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-bottom: 30px;
  display: grid;
}

.scalping-effectivity-section__video {
  border: 1px solid #444752;
  border-radius: 12px;
  overflow: hidden;
}

.live-trading-hero__info-block {
  margin-bottom: clamp(12px, 1.04167vw + 4px, 24px);
}

@media not all and (width >= 769px) {
  .live-trading-hero__info-block {
    margin-top: auto;
  }
}

@media not all and (width >= 501px) {
  .live-trading-hero__info-block {
    margin: auto auto 12px;
  }
}

.live-trading-hero__btn-wrap {
  background: none;
  border: none;
  align-items: center;
  display: flex;
}

.live-trading-hero__btn-wrap:hover .live-trading-hero__btn-play {
  filter: drop-shadow(12px 8px 80px #7a269c);
  transform: scale(1.1);
}

@media not all and (width >= 501px) {
  .live-trading-hero__btn-wrap {
    margin: 0 auto;
  }
}

@media not all and (width >= 450px) {
  .live-trading-hero__btn-wrap {
    width: 100%;
  }
}

.live-trading-hero__btn-play {
  z-index: 1;
  flex-shrink: 0;
  width: clamp(100px, 6.59722vw + 49.3333px, 176px);
  height: clamp(100px, 6.59722vw + 49.3333px, 176px);
  margin-right: clamp(-93px, -2.86458vw - 38px, -60px);
  transition: all .3s;
  position: relative;
}

.live-trading-hero__btn-text-wrap {
  background: #050506;
  border: 1px solid #ffffff4d;
  border-radius: clamp(8px, .347222vw + 5.33333px, 12px);
  flex-direction: column;
  padding: clamp(11px, .607639vw + 6.33333px, 18px) clamp(20px, 5.12153vw - 19.3333px, 79px) clamp(11px, .607639vw + 6.33333px, 18px) clamp(70px, 5.03472vw + 31.3333px, 128px);
  display: flex;
}

@media not all and (width >= 450px) {
  .live-trading-hero__btn-text-wrap {
    width: 100%;
  }
}

.live-trading-hero__btn-text {
  color: var(--cl-white);
  text-align-last: left;
  text-transform: uppercase;
  font-size: clamp(14px, .694445vw + 8.66667px, 22px);
  font-weight: 600;
}

.live-trading-hero__btn-text.highlight {
  color: #00ffcb;
}

@media not all and (width >= 551px) {
  .scalping-effectivity-section {
    padding-top: 120px;
  }
}

.scalping-effectivity-section__bg {
  z-index: -1;
  opacity: .7;
  position: absolute;
  top: -300px;
  left: 0;
}

.scalping-effectivity-slider__wrap {
  justify-content: space-between;
  align-items: center;
  gap: 37px;
  display: flex;
}

.scalping-effectivity-slider__btn {
  flex-shrink: 0;
}

.scalping-effectivity-section__image-group {
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  display: grid;
}

@media not all and (width >= 769px) {
  .scalping-effectivity-section__image-group {
    grid-template-columns: 1fr;
  }
}

.what-you-get {
  --columns: 4;
  --gap-x: clamp(16px, calc(16px + 14 * ((100vw - 768px) / 1152)), 30px);
  --gap-y: clamp(30px, calc(30px + 40 * ((100vw - 768px) / 1152)), 70px);
  justify-content: center;
  align-items: stretch;
  gap: var(--gap-y) var(--gap-x);
  flex-wrap: wrap;
  display: flex;
}

.what-you-get .what-you-get__item {
  --width: calc((100% - (var(--columns)  - 1) * var(--gap-x)) / var(--columns));
  width: var(--width);
}

@media not all and (width >= 1561px) {
  .what-you-get {
    --columns: 3;
  }
}

@media not all and (width >= 1281px) {
  .what-you-get {
    --columns: 2;
  }
}

@media not all and (width >= 769px) {
  .what-you-get {
    --columns: 1;
    --gap-y: 42px;
  }
}

.what-you-get-section__title {
  margin-bottom: 77px;
}

.what-you-get__item {
  aspect-ratio: 402 / 205;
  background: linear-gradient(#00ffcb00 0%, #00ffcb 100%);
  border-radius: 30px;
  flex-direction: column;
  align-items: center;
  padding: 5px 37px 45px;
  display: flex;
  position: relative;
}

@media not all and (width >= 769px) {
  .what-you-get__item {
    aspect-ratio: 343 / 148;
    max-width: 550px;
  }
}

.what-you-get__item:before {
  content: "";
  z-index: 1;
  background: #151515;
  border-radius: 30px;
  position: absolute;
  inset: 1px;
}

.what-you-get__item:after {
  content: "";
  z-index: 1;
  filter: blur(50px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  background: #00ffcb4d;
  border-radius: 50%;
  width: 191px;
  height: 59px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.what-you-get__item-icon, .what-you-get__item-title {
  z-index: 1;
}

.what-you-get__item-icon {
  max-width: clamp(93px, 3.55903vw + 65.6667px, 134px);
  height: clamp(52px, 1.73611vw + 38.6667px, 72px);
  margin-top: -17px;
  margin-bottom: clamp(20px, 2.34375vw + 2px, 47px);
}

.what-you-get__item-title {
  text-align: center;
  font-size: clamp(18px, .173611vw + 16.6667px, 20px);
  font-weight: 500;
  line-height: 1.22;
}

.live-trading-section__left-bg {
  z-index: -1;
  max-width: clamp(278px, 30.0347vw + 47.3333px, 624px);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media not all and (width >= 1025px) {
  .live-trading-section__left-bg {
    left: -57px;
  }
}

@media not all and (width >= 769px) {
  .live-trading-section__left-bg {
    top: 30%;
  }
}

.live-trading-section__right-bg {
  z-index: -1;
  opacity: .6;
  mix-blend-mode: lighten;
  max-width: clamp(350px, 52.691vw - 54.6667px, 957px);
  position: absolute;
  top: -60%;
  right: 0;
}

@media not all and (width >= 769px) {
  .live-trading-section__right-bg {
    top: -35%;
    right: -30px;
  }
}

.live-trading__title {
  margin-bottom: 78px;
}

.live-trading {
  grid-template-columns: 388fr 769fr 388fr;
  place-items: center;
  gap: clamp(10px, 1.73611vw - 3.33333px, 30px);
  display: grid;
}

@media not all and (width >= 1441px) {
  .live-trading {
    grid-template-columns: 1fr 2fr 1fr;
  }
}

@media not all and (width >= 1281px) {
  .live-trading {
    grid-template-columns: 2fr 1fr;
  }
}

@media not all and (width >= 1025px) {
  .live-trading {
    grid-template-columns: 1fr 1fr;
  }
}

@media not all and (width >= 769px) {
  .live-trading {
    grid-template-columns: 1fr;
    gap: 30px;
    max-width: 500px;
    margin: 0 auto;
  }
}

.live-trading__video-wrap {
  aspect-ratio: 769 / 546;
  background: linear-gradient(56deg, #fff 0%, #fff0 59.75% 75.3%, #fff 100%);
  border-radius: clamp(6px, 1.21528vw - 3.33333px, 20px);
  grid-column: 2 / 3;
  padding: clamp(8px, .434028vw + 4.66667px, 13px) clamp(13px, 1.21528vw + 3.66667px, 27px);
  position: relative;
}

.live-trading__video-wrap:before {
  content: " ";
  z-index: 1;
  background: #000;
  border-radius: clamp(6px, 1.21528vw - 3.33333px, 20px);
  position: absolute;
  inset: 3px;
}

@media not all and (width >= 1281px) {
  .live-trading__video-wrap {
    grid-column: unset;
    aspect-ratio: 68 / 45;
  }
}

.live-trading__video-cover {
  z-index: 1;
  border-radius: clamp(6px, 1.21528vw - 3.33333px, 20px);
  position: relative;
}

.live-trading__info-wrap {
  flex-direction: column;
  gap: clamp(20px, 3.47222vw - 6.66667px, 60px);
  display: flex;
}

@media not all and (width >= 769px) {
  .live-trading__info-wrap {
    width: 100%;
  }
}

.live-trading__info-item-title {
  color: #00ffcb;
  margin-bottom: clamp(8px, .347222vw + 5.33333px, 12px);
  font-size: clamp(16px, .173611vw + 14.6667px, 18px);
  line-height: 1.22;
}

.live-trading__info-item-text {
  font-size: clamp(14px, .173611vw + 12.6667px, 16px);
  line-height: 1.22;
}

.live-trading__btn {
  margin: clamp(40px, 3.47222vw + 13.3333px, 80px) auto 0;
}

@media not all and (width >= 769px) {
  .live-trading__btn .btn__text {
    font-size: 20px;
  }
}

.live-trading__video-play {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.live-trading__video-play:hover {
  transform: translate(-50%, -50%)scale(1.08);
}

.section-thank-you, .section-thank-you .container {
  height: 100%;
}

.thank-you {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  height: 100%;
  display: flex;
}

.thank-you__title {
  font-family: var(--font-title);
  font-size: clamp(24px, 3.47222vw - 2.66667px, 64px);
  font-weight: 700;
}

.thank-you__text {
  font-size: clamp(16px, .520833vw + 12px, 22px);
}

.profit-hero-section {
  padding-top: clamp(80px, 6.51042vw + 30px, 155px);
}

.profit-hero-section__decor {
  opacity: .6;
  z-index: -1;
  position: absolute;
  left: 0;
}

@media not all and (width >= 1025px) {
  .profit-hero-section__decor {
    top: -30%;
  }
}

@media not all and (width >= 551px) {
  .profit-hero-section__decor {
    width: 140%;
    top: -25%;
  }
}

@media (width >= 1921px) {
  .profit-hero-section__decor {
    top: -70%;
  }
}

.profit-hero {
  justify-content: space-between;
  align-items: center;
  gap: clamp(10px, .868056vw + 3.33333px, 20px);
  margin-bottom: 30px;
  display: flex;
}

@media not all and (width >= 1025px) {
  .profit-hero {
    flex-direction: column;
    margin-bottom: 0;
  }
}

.profit-hero__left-block {
  max-width: clamp(400px, 52.9167vw - 362px, 654px);
}

@media not all and (width >= 1025px) {
  .profit-hero__left-block {
    max-width: unset;
  }
}

.profit-hero__logo {
  max-width: clamp(270px, 22.9167vw - 60px, 380px);
  margin-bottom: clamp(20px, .868056vw + 13.3333px, 30px);
}

@media not all and (width >= 1025px) {
  .profit-hero__logo {
    max-width: clamp(118px, 22.5263vw - 5.89474px, 225px);
    margin-inline: auto;
  }
}

@media not all and (width >= 550px) {
  .profit-hero__logo {
    margin-inline: unset;
  }
}

.profit-hero__text {
  color: #ffffffb3;
  text-align: justify;
  margin-bottom: clamp(20px, 1.30208vw + 10px, 35px);
  font-size: clamp(14px, .173611vw + 12.6667px, 16px);
}

@media not all and (width >= 769px) {
  .profit-hero__text {
    margin-bottom: 0;
  }
}

.profit-hero__right-block {
  gap: clamp(41px, 6.875vw - 47px, 85px);
  display: flex;
}

@media not all and (width >= 1025px) {
  .profit-hero__right-block {
    width: 100%;
  }
}

.profit-hero__stats {
  flex-direction: column;
  gap: clamp(8px, 1.04167vw, 20px);
  padding-top: clamp(19px, 2.08333vw + 3px, 43px);
  display: flex;
}

@media not all and (width >= 1025px) {
  .profit-hero__stats {
    align-items: end;
    width: 100%;
  }
}

.profit-stat {
  backdrop-filter: blur(75px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  background: radial-gradient(264.92% 100% at 50% 0, #ffffff1b 0%, #ffffff0b 29.88%);
  border-radius: clamp(12px, 1.5625vw, 30px);
  justify-content: space-between;
  gap: clamp(6px, 1.67411vw - 11.1429px, 21px);
  max-width: 387px;
  padding: clamp(7px, 1.04167vw - 1px, 19px) clamp(9px, .347222vw + 6.33333px, 13px) clamp(10px, 1.04167vw + 2px, 22px) clamp(7px, 1.04167vw - 1px, 19px);
  display: flex;
  position: relative;
  overflow: hidden;
  -webkit-backdrop-filter: blur(75px) !important;
}

.profit-stat:before {
  content: "";
  filter: blur(31px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  background: #2af895cc;
  border-radius: 999px;
  width: 37px;
  height: 37px;
  position: absolute;
  top: 45%;
  left: 0;
}

.profit-stat:after {
  content: "";
  filter: blur(50px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  background: #2af895cc;
  border-radius: 999px;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  right: 0;
}

.profit-stat:first-child {
  left: clamp(-150px, -7.8125vw, -100px);
}

@media not all and (width >= 1025px) {
  .profit-stat:first-child {
    left: 0;
  }

  .profit-stat:nth-child(2) {
    left: 28px;
  }
}

.profit-stat:nth-child(3) {
  left: clamp(100px, 7.8125vw, 150px);
}

@media not all and (width >= 1025px) {
  .profit-stat:nth-child(3) {
    left: 56px;
  }
}

.profit-stat[data-aiv] {
  opacity: 0;
}

.profit-stat[data-aiv] .profit-stat__chart {
  transform-origin: bottom;
  transition: scale .5s .25s;
  scale: 1 0;
}

.profit-stat[data-aiv] .profit-stat__info {
  opacity: 0;
  transition: opacity .5s, transform .5s;
  transform: translateY(20px);
}

.profit-stat[data-aiv].in-view {
  opacity: 1;
}

.profit-stat[data-aiv].in-view .profit-stat__chart {
  scale: 1;
}

.profit-stat[data-aiv].in-view .profit-stat__info {
  opacity: 1;
  transform: translateY(0);
}

.profit-stat__info {
  max-width: 209px;
}

@media not all and (width >= 1025px) {
  .profit-stat__info {
    max-width: clamp(100px, 16vw + 36px, 200px);
  }
}

.profit-stat__user-info {
  align-items: center;
  gap: 8px;
  margin-bottom: clamp(5px, .694445vw - .333334px, 13px);
  display: flex;
}

.profit-stat__user-name {
  font-size: clamp(10px, .347222vw + 7.33333px, 14px);
  font-weight: 500;
}

.profit-stat__user-position {
  opacity: .5;
  font-size: clamp(5px, .607639vw + .333333px, 12px);
}

.profit-stat__result {
  color: #2af895;
  font-size: clamp(16px, 1.21528vw + 6.66667px, 30px);
  font-weight: 700;
  line-height: 1.218;
}

.profit-stat__text {
  font-size: clamp(5px, .607639vw + .333333px, 12px);
}

.profit-stat__user-image {
  background: #484848;
  border-radius: 999px;
  justify-content: center;
  align-items: center;
  width: clamp(20px, 1.38889vw + 9.33333px, 36px);
  height: clamp(20px, 1.38889vw + 9.33333px, 36px);
  padding: 4px;
  display: flex;
}

.profit-stat__chart {
  align-self: end;
}

@media not all and (width >= 1025px) {
  .profit-stat__chart {
    max-width: clamp(44px, 4.16vw + 27.36px, 70px);
  }
}

.profit-hero__phone-wrap {
  max-width: clamp(300px, 8.68056vw + 233.333px, 400px);
}

@media not all and (width >= 550px) {
  .profit-hero__phone-wrap {
    max-width: 200px;
  }
}

.profit-hero__btn.mob {
  margin-top: 20px;
  display: none;
}

@media not all and (width >= 769px) {
  .profit-hero__btn.mob {
    display: flex;
  }

  .profit-hero__btn.desktop {
    display: none;
  }
}

.profit-what-you-get {
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: "frst title title"
                       "scnd thrd frth"
                       "fth sxth svnth";
  align-items: center;
  gap: clamp(20px, 6.94444vw - 33.3333px, 100px) clamp(20px, 3.55903vw - 7.33333px, 61px);
  display: grid;
}

@media not all and (width >= 769px) {
  .profit-what-you-get {
    grid-template-columns: 1fr;
    grid-template-areas: "title"
                         "frst"
                         "scnd"
                         "thrd"
                         "frth"
                         "fth"
                         "sxth"
                         "svnth";
  }
}

.profit-what-you-get__bg {
  z-index: -1;
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media not all and (width >= 769px) {
  .profit-what-you-get__bg {
    min-width: 1070px;
    left: 0;
    transform: translate(0%, -35%);
  }
}

@media (width >= 1920px) {
  .profit-what-you-get__bg {
    -webkit-mask-image: linear-gradient(to right, #0000 0%, #000 3% 97%, #0000 100%);
    mask-image: linear-gradient(to right, #0000 0%, #000 3% 97%, #0000 100%);
  }
}

.profit-what-you-get__title {
  z-index: 1;
  font-family: var(--font-title);
  text-align: end;
  grid-area: title;
  font-size: clamp(48px, 8.68056vw - 18.6667px, 148px);
  line-height: 1;
}

@media not all and (width >= 769px) {
  .profit-what-you-get__title {
    text-align: center;
  }
}

.you-get-card {
  border-bottom: 2px solid #fff;
  height: 100%;
  padding: clamp(28px, 1.38889vw + 17.3333px, 44px) clamp(12px, .694445vw + 6.66667px, 20px);
  transition: all .5s;
  position: relative;
}

@media not all and (width >= 769px) {
  .you-get-card {
    min-height: 95px;
  }
}

.you-get-card:after {
  content: "";
  z-index: -1;
  transform-origin: bottom;
  opacity: 0;
  background: radial-gradient(60% 90% at 50.15% 115%, #e8333480 0%, #0000 100%);
  transition: all .5s;
  position: absolute;
  inset: 0;
  transform: scaleY(0);
}

.you-get-card:hover {
  border-color: #e83334;
}

.you-get-card:hover:after {
  opacity: 1;
  transform: scaleY(1);
}

.you-get-card:hover .you-get-card__num {
  color: #ff494b33;
}

.you-get-card:first-of-type {
  grid-area: frst;
}

.you-get-card:nth-of-type(2) {
  grid-area: scnd;
}

.you-get-card:nth-of-type(3) {
  grid-area: thrd;
}

.you-get-card:nth-of-type(4) {
  grid-area: frth;
}

.you-get-card:nth-of-type(5) {
  grid-area: fth;
}

.you-get-card:nth-of-type(6) {
  grid-area: sxth;
}

.you-get-card:nth-of-type(7) {
  grid-area: svnth;
}

.you-get-card__title {
  text-transform: uppercase;
  font-size: clamp(16px, .520833vw + 12px, 22px);
  font-weight: 600;
}

.you-get-card__num {
  color: #ffffff1f;
  font-family: var(--font-title);
  font-size: clamp(121px, 3.38542vw + 95px, 160px);
  line-height: .76;
  transition: all .5s;
  position: absolute;
  bottom: 0;
  right: -2px;
}

.inside-profit {
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  gap: clamp(30px, 22.9167vw - 300px, 140px);
  display: grid;
}

@media not all and (width >= 1281px) {
  .inside-profit {
    grid-template-columns: 1fr;
    grid-template-areas: "title"
                         "text"
                         "video";
    justify-items: center;
  }
}

@media not all and (width >= 701px) {
  .inside-profit {
    justify-items: start;
  }
}

.inside-profit__title {
  font-family: var(--font-title);
  text-transform: uppercase;
  font-size: clamp(98px, .260417vw + 96px, 101px);
  line-height: 1;
}

@media not all and (width >= 1281px) {
  .inside-profit__title {
    grid-area: title;
  }
}

.inside-profit__video-wrap {
  background: linear-gradient(57deg, #e83334 0%, #e8333400 59.75% 75.3%, #e83334 100%);
  border-radius: clamp(16px, 1.38889vw + 5.33333px, 32px);
  padding: clamp(14px, 1.38889vw + 3.33333px, 30px);
  position: relative;
  box-shadow: 0 4px 250px #ff494b66;
}

@media not all and (width >= 1281px) {
  .inside-profit__video-wrap {
    grid-area: video;
  }
}

.inside-profit__video-wrap:before {
  content: "";
  border-radius: inherit;
  background: #000;
  position: absolute;
  inset: 2px;
}

@media not all and (width >= 1281px) {
  .inside-profit__video-wrap {
    max-width: 900px;
  }
}

.inside-profit__play-btn {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.inside-profit__video-preview {
  z-index: 1;
  border-radius: 24px;
  position: relative;
}

.inside-profit__text-wrap {
  color: #ffffffb3;
  font-size: clamp(14px, .347222vw + 11.3333px, 18px);
}

.inside-profit__text-wrap strong {
  color: #fff;
  font-weight: 500;
}

@media not all and (width >= 1281px) {
  .inside-profit__text-wrap {
    grid-area: text;
    max-width: 700px;
  }

  .inside-profit__logo {
    max-width: 339px;
  }
}

.how-join-section__bg {
  z-index: -1;
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-mask-image: linear-gradient(to right, #0000, #fff 3%, #fff, #fff 97%, #0000);
  mask-image: linear-gradient(to right, #0000, #fff 3%, #fff, #fff 97%, #0000);
}

@media not all and (width >= 1441px) {
  .how-join-section__bg {
    top: 55%;
  }
}

@media not all and (width >= 769px) {
  .how-join-section__bg {
    opacity: .6;
    min-width: 1700px;
    transform: translate(-32%, -56%);
  }
}

.how-join-section__title {
  text-align: center;
  margin-bottom: 54px;
}

.how-join {
  grid-template-areas: "frst frst frst thrd thrd thrd fth fth fth"
                       "scnd scnd scnd scnd scnd frth frth frth frth";
  gap: 100px;
  display: grid;
}

@media not all and (width >= 1025px) {
  .how-join {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-template-areas: unset;
    gap: 24px;
  }
}

.how-join .how-join-card:first-child {
  grid-area: frst;
  height: clamp(750px, 15.625vw + 550px, 850px);
  padding-top: 180px;
}

@media not all and (width >= 1025px) {
  .how-join .how-join-card:first-child {
    height: unset;
    grid-area: unset;
    padding-top: 0;
  }
}

.how-join .how-join-card:nth-child(2) {
  grid-area: scnd;
  place-content: center;
  margin-top: clamp(-150px, -4.34028vw - 66.6667px, -100px);
  padding-top: 130px;
}

.how-join .how-join-card:nth-child(2) .how-join-card__num-wrap {
  position: relative;
}

.how-join .how-join-card:nth-child(2) .how-join-card__num-wrap:before {
  background: linear-gradient(#ff494b00 0%, #ff494b 100%);
  height: 100%;
  position: absolute;
  bottom: calc(100% + 20px);
}

.how-join .how-join-card:nth-child(2) .how-join-card__num-wrap:after {
  position: absolute;
  bottom: calc(100% + 20px);
}

@media not all and (width >= 1025px) {
  .how-join .how-join-card:nth-child(2) {
    margin-top: unset;
    padding-top: unset;
    grid-area: unset;
  }

  .how-join .how-join-card:nth-child(2) .how-join-card__num-wrap:before, .how-join .how-join-card:nth-child(2) .how-join-card__num-wrap:after {
    position: static;
  }

  .how-join .how-join-card:nth-child(2) .how-join-card__num-wrap:before {
    background: linear-gradient(0deg, #ff494b00 0%, #ff494b 100%);
  }
}

.how-join .how-join-card:nth-child(3) {
  grid-area: thrd;
  height: 400px;
  padding-top: 90px;
}

@media not all and (width >= 1025px) {
  .how-join .how-join-card:nth-child(3) {
    height: unset;
    grid-area: unset;
    padding-top: 0;
  }
}

.how-join .how-join-card:nth-child(4) {
  grid-area: frth;
  place-content: center;
  margin-top: clamp(-150px, -7.8125vw, -100px);
}

.how-join .how-join-card:nth-child(4) .how-join-card__num-wrap {
  position: relative;
}

.how-join .how-join-card:nth-child(4) .how-join-card__num-wrap:before {
  background: linear-gradient(#ff494b00 0%, #ff494b 100%);
  height: 100%;
  position: absolute;
  bottom: calc(100% + 20px);
}

.how-join .how-join-card:nth-child(4) .how-join-card__num-wrap:after {
  position: absolute;
  bottom: calc(100% + 20px);
}

@media not all and (width >= 1025px) {
  .how-join .how-join-card:nth-child(4) {
    margin-top: unset;
    grid-area: unset;
  }

  .how-join .how-join-card:nth-child(4) .how-join-card__num-wrap:before, .how-join .how-join-card:nth-child(4) .how-join-card__num-wrap:after {
    position: static;
  }

  .how-join .how-join-card:nth-child(4) .how-join-card__num-wrap:before {
    background: linear-gradient(0deg, #ff494b00 0%, #ff494b 100%);
  }
}

.how-join .how-join-card:nth-child(5) {
  grid-area: fth;
  height: 460px;
}

@media not all and (width >= 1025px) {
  .how-join .how-join-card:nth-child(5) {
    height: unset;
    grid-area: unset;
  }

  .how-join .how-join-card {
    place-content: center;
  }
}

.how-join-card {
  gap: 12px;
  display: flex;
}

.how-join-card__num-wrap {
  color: #ff494b;
  flex-direction: column;
  align-items: center;
  font-size: 26px;
  font-weight: 500;
  line-height: 1.2;
  display: flex;
}

.how-join-card__num-wrap:before {
  content: "";
  background: linear-gradient(0deg, #ff494b00 0%, #ff494b 100%);
  flex: 1;
  order: 1;
  width: 2px;
  height: 10px;
  display: block;
}

@media not all and (width >= 769px) {
  .how-join-card__num-wrap:before {
    width: 1px;
  }
}

.how-join-card__num-wrap:after {
  content: "";
  background: #ff494b;
  border-radius: 999px;
  width: 10px;
  height: 10px;
  display: block;
}

.how-join-card__num {
  margin-bottom: 16px;
}

.how-join-card__title {
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: 26px;
  font-weight: 600;
  line-height: 1.2;
}

.how-join-card__text {
  color: #ffffffb3;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.5;
}

.how-join-card__text strong {
  color: #fff;
  font-weight: 500;
}

.how-join-card__link-list {
  flex-direction: column;
  gap: 12px;
  display: flex;
}

.how-join-card__link {
  color: #fff;
  text-transform: uppercase;
  background: #ffb80033;
  border: 1px solid #ffb800;
  border-radius: 999px;
  align-items: center;
  gap: 8px;
  width: fit-content;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  transition: background .3s;
  display: flex;
}

.how-join-card__link:hover {
  background: #ffb800;
}

.how-join-card__link:after {
  content: "";
  background: url("../img/arrow-top-right.svg");
  width: 24px;
  height: 24px;
  display: block;
}

.how-join-card__link.red {
  background: #ff494b33;
  border: 1px solid #ff494b;
}

.how-join-card__link.red:hover {
  background: #ff494b;
}

.how-join-card__link.grey {
  background: #26282ecc;
  border: 1px solid #444752;
}

.how-join-card__link.grey:hover {
  background: #444752;
}

.how-join-card__info-wrap {
  max-width: 354px;
}

.participants-deals {
  align-items: center;
  gap: 20px;
  display: flex;
}

@media not all and (width >= 769px) {
  .participants-deals {
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px 48px;
  }
}

.participants-deals-slider__nav-btn {
  flex-shrink: 0;
}

@media not all and (width >= 769px) {
  .participants-deals-slider__nav-btn {
    order: 2;
  }
}

.participants-deals__title {
  z-index: 1;
  text-align: center;
  margin-bottom: clamp(12px, 5.90278vw - 33.3333px, 80px);
  position: relative;
}

.participants-deals-slider {
  flex: 1;
  width: 100%;
  max-width: clamp(550px, 39.0625vw + 250px, 1000px);
  margin: 0 auto;
}

@media not all and (width >= 769px) {
  .participants-deals-slider {
    max-width: 100%;
  }
}

.participants-deals-slider .swiper-slide {
  overflow: visible;
}

.participants-deals-slider .swiper-slide.swiper-slide-active .participants-deal {
  display: block;
  box-shadow: 0 0 150px 50px #000;
}

.participants-deal {
  width: 100%;
  height: 100%;
  transition: all .6s;
}

.landing-hero-section {
  padding-top: 190px;
}

.landing-hero__content-wrap {
  grid-template-columns: 1fr .61fr;
  display: grid;
}

.landing-hero__text-info {
  padding-top: 100px;
}

.landing-hero__info-blocks {
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  gap: 41px;
  margin-bottom: 67px;
  display: grid;
}

.landing-hero__descr {
  color: #ffffffb3;
  max-width: 868px;
  margin-bottom: 80px;
  font-size: 24px;
  line-height: 1.2;
}

.landing-info-block {
  background: linear-gradient(#01ffcb 0%, #01ffcb00 85%);
  border-radius: 20px 20px 0 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 26px 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.landing-info-block:before {
  content: "";
  background: linear-gradient(#15352e 0%, #08090a 85%);
  border-radius: 18px 18px 0 0;
  position: absolute;
  inset: 2.6px;
}

.landing-info-block:after {
  content: "";
  filter: blur(40px);
  background: #01ffcb4d;
  border-radius: 100%;
  width: 74%;
  height: 70px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.landing-info-block__title {
  z-index: 1;
  text-transform: uppercase;
  color: #ffffffb3;
  font-size: 23px;
  font-weight: 500;
  position: relative;
}

.landing-info-block__info {
  z-index: 1;
  font-size: 31px;
  font-weight: 600;
  position: relative;
}

.landing-hero__title {
  font-size: 72px;
  line-height: 1;
  font-family: var(--font-title);
  margin-bottom: 24px;
}

.landing-hero__title .highlight {
  color: #01ffcb;
}

.landing-hero__form-img-wrap {
  margin-bottom: -125px;
  position: relative;
}

.landing-hero__form-img-wrap .hand {
  z-index: 2;
  width: 22%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.landing-hero__form-img-wrap:before {
  content: "";
  z-index: -1;
  filter: blur(100px);
  background: #01ffcb4d;
  width: 50%;
  height: 50%;
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
}

.landing-hero__form {
  z-index: 1;
  background: #212223;
  border: 2px solid #fff3;
  border-radius: 30px;
  padding: 40px 30px;
  position: relative;
  right: 70px;
}

.landing-hero__form-arrow {
  position: absolute;
  top: 15%;
  right: 92%;
}

.landing-hero__form-title {
  text-align: center;
  margin-bottom: 12px;
  font-size: 24px;
}

.landing-hero__form-title .highlight {
  color: #ff61fb;
  font-weight: 600;
}

.landing-hero__form-accent-text {
  text-align: center;
  white-space: nowrap;
  margin-bottom: 30px;
  font-size: 36px;
  font-weight: 600;
}

.landing-hero__list {
  grid-template-columns: repeat(2, 1fr);
  gap: 23px 30px;
  max-width: 688px;
  display: grid;
}

.landing-hero__list-item {
  align-items: center;
  gap: 12px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
  display: flex;
}

.landing-hero__list-item:before {
  content: "";
  background: url("../img/landing-hero-check.png") no-repeat;
  width: 32px;
  height: 32px;
  display: inline-block;
}
/*# sourceMappingURL=styles.335a32bd.css.map */
