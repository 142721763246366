:root {
  --iti-border-gray: #ccc;
  --iti-text-gray: #999;
  --iti-spacer-horizontal: 8px;
  --iti-flag-height: 15px;
  --iti-flag-width: 20px;
  --iti-border-width: 1px;
  --iti-arrow-height: 4px;
  --iti-arrow-width: 6px;
  --iti-triangle-border: calc(var(--iti-arrow-width) / 2);
  --iti-arrow-padding: 6px;
  --iti-input-padding: 6px;
  --iti-right-hand-selected-country-padding: calc(
    var(--iti-spacer-horizontal) + var(--iti-spacer-horizontal) +
      var(--iti-flag-width)
  );
  --iti-selected-country-arrow-padding: calc(
    var(--iti-arrow-padding) + var(--iti-arrow-padding) + var(--iti-flag-width) +
      var(--iti-spacer-horizontal) + var(--iti-arrow-width) +
      var(--iti-input-padding)
  );
  /* --iti-path-flags-1x: url(assets/images/intl-tel-input/flags.webp);
  --iti-path-flags-2x: url(assets/images/intl-tel-input/flags@2x.webp);
  --iti-path-globe-1x: url(assets/images/intl-tel-input/globe.webp);
  --iti-path-globe-2x: url(assets/images/intl-tel-input/globe@2x.webp); */
  --iti-flag-sprite-width: 5762px;
  --iti-flag-sprite-height: 15px;
  --iti-mobile-popup-margin: 30px;

  --iti-border-color: #5b5b5b;
  --iti-dialcode-color: #999999;
  --iti-dropdown-bg: #0d1117;
  --iti-arrow-color: #aaaaaa;
  --iti-hover-color: #30363d;
}

.iti {
  position: relative;
  display: inline-block;
}
.iti * {
  box-sizing: border-box;
}
.iti__hide {
  display: none;
}
.iti__v-hide {
  visibility: hidden;
}
.iti__a11y-text {
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  position: absolute;
}
.iti input.iti__tel-input,
.iti input.iti__tel-input[type='text'],
.iti input.iti__tel-input[type='tel'] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: var(--iti-right-hand-selected-country-padding);
  margin-right: 0;

  width: 100%;
}
.iti__country-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: var(--iti-border-width);
}
.iti__selected-country {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  border-radius: 0;
  font-weight: inherit;
  line-height: inherit;
  text-decoration: none;
}
.iti__selected-country-primary {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 var(--iti-arrow-padding) 0 var(--iti-spacer-horizontal);
}
.iti__arrow {
  margin-left: var(--iti-arrow-padding);
  width: 0;
  height: 0;
  border-left: var(--iti-triangle-border) solid transparent;
  border-right: var(--iti-triangle-border) solid transparent;
  border-top: var(--iti-arrow-height) solid var(--iti-arrow-color);
}
[dir='rtl'] .iti__arrow {
  margin-right: var(--iti-arrow-padding);
  margin-left: 0;
}
.iti__arrow--up {
  border-top: none;
  border-bottom: var(--iti-arrow-height) solid var(--iti-arrow-color);
}
.iti__dropdown-content {
  border-radius: 3px;
  background-color: var(--iti-dropdown-bg);
}
.iti--inline-dropdown .iti__dropdown-content {
  position: absolute;
  z-index: 3;
  margin-top: 3px;
  margin-left: calc(var(--iti-border-width) * -1);
  border: var(--iti-border-width) solid var(--iti-border-color);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}
.iti__search-input {
  width: 100%;
  border-width: 0;
  border-radius: 3px;
  padding: 9px 12px;

  background: transparent;
  border-bottom: 1px solid var(--iti-border-color);
  color: #ffffff;
}
.iti__search-input + .iti__country-list {
  border-top: 1px solid var(--iti-border-color);
}
.iti__country-list {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.iti--inline-dropdown .iti__country-list {
  max-height: 185px;
}
.iti--flexible-dropdown-width .iti__country-list {
  white-space: nowrap;
}
@media (max-width: 500px) {
  .iti--flexible-dropdown-width .iti__country-list {
    white-space: normal;
  }
}
.iti__flag-box {
  display: inline-block;
  width: var(--iti-flag-width);
}
.iti__country {
  display: flex;
  align-items: center;
  padding: 8px var(--iti-spacer-horizontal);
  outline: none;
}
.iti__dial-code {
  color: var(--iti-dialcode-color);
}
.iti__country.iti__highlight {
  background-color: var(--iti-hover-color);
}
.iti__flag-box,
.iti__country-name {
  margin-right: var(--iti-spacer-horizontal);
}
[dir='rtl'] .iti__flag-box,
[dir='rtl'] .iti__country-name {
  margin-right: 0;
  margin-left: var(--iti-spacer-horizontal);
}
.iti--allow-dropdown input.iti__tel-input,
.iti--allow-dropdown input.iti__tel-input[type='text'],
.iti--allow-dropdown input.iti__tel-input[type='tel'] {
  padding-right: var(--iti-input-padding);
  padding-left: var(--iti-selected-country-arrow-padding);
  margin-left: 0;
}
[dir='rtl'] .iti--allow-dropdown input.iti__tel-input,
[dir='rtl'] .iti--allow-dropdown input.iti__tel-input[type='text'],
[dir='rtl'] .iti--allow-dropdown input.iti__tel-input[type='tel'] {
  padding-right: var(--iti-selected-country-arrow-padding);
  padding-left: var(--iti-input-padding);
  margin-right: 0;
}
.iti--allow-dropdown .iti__country-container {
  right: auto;
  left: 0;
}
[dir='rtl'] .iti--allow-dropdown .iti__country-container {
  right: 0;
  left: auto;
}
.iti--allow-dropdown
  .iti__country-container:not(:has(+ input[disabled])):not(
    :has(+ input[readonly])
  ):hover,
.iti--allow-dropdown
  .iti__country-container:not(:has(+ input[disabled])):not(
    :has(+ input[readonly])
  ):hover
  button {
  cursor: pointer;
}
.iti--allow-dropdown
  .iti__country-container:not(:has(+ input[disabled])):not(
    :has(+ input[readonly])
  )
  .iti__selected-country-primary:hover,
.iti--allow-dropdown
  .iti__country-container:not(:has(+ input[disabled])):not(
    :has(+ input[readonly])
  )
  .iti__selected-country:has(+ .iti__dropdown-content:hover)
  .iti__selected-country-primary {
  /* background-color: var(--iti-hover-color); */
}
.iti .iti__selected-dial-code {
  margin-left: 4px;

  font-size: ac(16px, 14px);
}
[dir='rtl'] .iti .iti__selected-dial-code {
  margin-left: 0;
  margin-right: 4px;
}
.iti--container {
  position: fixed;
  top: -1000px;
  left: -1000px;
  z-index: 1060;
  padding: var(--iti-border-width);
}
.iti--container:hover {
  cursor: pointer;
}

.iti--fullscreen-popup.iti--container {
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  padding: var(--iti-mobile-popup-margin);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.iti--fullscreen-popup .iti__dropdown-content {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  position: relative;
}
.iti--fullscreen-popup .iti__country {
  padding: 10px 10px;
  line-height: 1.5em;
}

.iti__flag {
  --iti-flag-offset: 0px;
  --iti-flag-width: 20px;
  --iti-flag-height: 14px;
  height: var(--iti-flag-height);
  width: var(--iti-flag-width);
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url(../../assets/images/intl-tel-input/flags.webp);
  background-repeat: no-repeat;
  background-position: var(--iti-flag-offset) 0;
  background-size: var(--iti-flag-sprite-width) var(--iti-flag-sprite-height);
}

.iti__np {
  --iti-flag-width: 13px;
}

.iti__va,
.iti__ch {
  --iti-flag-width: 15px;
}

.iti__ne,
.iti__be {
  --iti-flag-width: 18px;
}

.iti__mc {
  --iti-flag-width: 19px;
}

.iti__zw,
.iti__ws,
.iti__vg,
.iti__uz,
.iti__uk,
.iti__tv,
.iti__to,
.iti__tl,
.iti__tk,
.iti__tj,
.iti__tc,
.iti__ta,
.iti__su,
.iti__st,
.iti__ss,
.iti__si,
.iti__sh,
.iti__sd,
.iti__sc,
.iti__sb,
.iti__ps,
.iti__pn,
.iti__ph,
.iti__om,
.iti__nz,
.iti__nu,
.iti__nr,
.iti__ng,
.iti__nf,
.iti__nc,
.iti__my,
.iti__ms,
.iti__mp,
.iti__mn,
.iti__mk,
.iti__me,
.iti__md,
.iti__ly,
.iti__lv,
.iti__lk,
.iti__lc,
.iti__kz,
.iti__ky,
.iti__kw,
.iti__kp,
.iti__ki,
.iti__jo,
.iti__jm,
.iti__io,
.iti__im,
.iti__ie,
.iti__hu,
.iti__hr,
.iti__hn,
.iti__hm,
.iti__gw,
.iti__gs,
.iti__gi,
.iti__gb,
.iti__fk,
.iti__fj,
.iti__et,
.iti__er,
.iti__eh,
.iti__dm,
.iti__dg,
.iti__cx,
.iti__cu,
.iti__ck,
.iti__cc,
.iti__ca,
.iti__by,
.iti__bs,
.iti__bn,
.iti__bm,
.iti__ba,
.iti__az,
.iti__au,
.iti__as,
.iti__am,
.iti__ai,
.iti__ae,
.iti__ac {
  --iti-flag-height: 10px;
}

.iti__us,
.iti__um,
.iti__py,
.iti__mh,
.iti__lr,
.iti__gu,
.iti__fm {
  --iti-flag-height: 11px;
}

.iti__vu,
.iti__tt,
.iti__sv,
.iti__ni,
.iti__mx,
.iti__lu,
.iti__lt,
.iti__li,
.iti__km,
.iti__kg,
.iti__je,
.iti__ir,
.iti__ht,
.iti__gy,
.iti__gd,
.iti__fi,
.iti__de,
.iti__cv,
.iti__cr,
.iti__cq,
.iti__bz,
.iti__bi,
.iti__bh,
.iti__bg,
.iti__bd {
  --iti-flag-height: 12px;
}

.iti__tg,
.iti__se,
.iti__pw,
.iti__pl,
.iti__kh,
.iti__gt,
.iti__ee,
.iti__ax,
.iti__ar {
  --iti-flag-height: 13px;
}

.iti__xk,
.iti__va,
.iti__sm,
.iti__sj,
.iti__pg,
.iti__np,
.iti__no,
.iti__ne,
.iti__mc,
.iti__is,
.iti__il,
.iti__ga,
.iti__fo,
.iti__dk,
.iti__ch,
.iti__cd,
.iti__bv,
.iti__be,
.iti__al {
  --iti-flag-height: 15px;
}

.iti__qa {
  --iti-flag-height: 8px;
}

.iti__ac {
  --iti-flag-offset: 0px;
}

.iti__ad {
  --iti-flag-offset: -22px;
}

.iti__ae {
  --iti-flag-offset: -44px;
}

.iti__af {
  --iti-flag-offset: -66px;
}

.iti__ag {
  --iti-flag-offset: -88px;
}

.iti__ai {
  --iti-flag-offset: -110px;
}

.iti__al {
  --iti-flag-offset: -132px;
}

.iti__am {
  --iti-flag-offset: -154px;
}

.iti__ao {
  --iti-flag-offset: -176px;
}

.iti__aq {
  --iti-flag-offset: -198px;
}

.iti__ar {
  --iti-flag-offset: -220px;
}

.iti__as {
  --iti-flag-offset: -242px;
}

.iti__at {
  --iti-flag-offset: -264px;
}

.iti__au {
  --iti-flag-offset: -286px;
}

.iti__aw {
  --iti-flag-offset: -308px;
}

.iti__ax {
  --iti-flag-offset: -330px;
}

.iti__az {
  --iti-flag-offset: -352px;
}

.iti__ba {
  --iti-flag-offset: -374px;
}

.iti__bb {
  --iti-flag-offset: -396px;
}

.iti__bd {
  --iti-flag-offset: -418px;
}

.iti__be {
  --iti-flag-offset: -440px;
}

.iti__bf {
  --iti-flag-offset: -460px;
}

.iti__bg {
  --iti-flag-offset: -482px;
}

.iti__bh {
  --iti-flag-offset: -504px;
}

.iti__bi {
  --iti-flag-offset: -526px;
}

.iti__bj {
  --iti-flag-offset: -548px;
}

.iti__bl {
  --iti-flag-offset: -570px;
}

.iti__bm {
  --iti-flag-offset: -592px;
}

.iti__bn {
  --iti-flag-offset: -614px;
}

.iti__bo {
  --iti-flag-offset: -636px;
}

.iti__bq {
  --iti-flag-offset: -658px;
}

.iti__br {
  --iti-flag-offset: -680px;
}

.iti__bs {
  --iti-flag-offset: -702px;
}

.iti__bt {
  --iti-flag-offset: -724px;
}

.iti__bv {
  --iti-flag-offset: -746px;
}

.iti__bw {
  --iti-flag-offset: -768px;
}

.iti__by {
  --iti-flag-offset: -790px;
}

.iti__bz {
  --iti-flag-offset: -812px;
}

.iti__ca {
  --iti-flag-offset: -834px;
}

.iti__cc {
  --iti-flag-offset: -856px;
}

.iti__cd {
  --iti-flag-offset: -878px;
}

.iti__cf {
  --iti-flag-offset: -900px;
}

.iti__cg {
  --iti-flag-offset: -922px;
}

.iti__ch {
  --iti-flag-offset: -944px;
}

.iti__ci {
  --iti-flag-offset: -961px;
}

.iti__ck {
  --iti-flag-offset: -983px;
}

.iti__cl {
  --iti-flag-offset: -1005px;
}

.iti__cm {
  --iti-flag-offset: -1027px;
}

.iti__cn {
  --iti-flag-offset: -1049px;
}

.iti__co {
  --iti-flag-offset: -1071px;
}

.iti__cp {
  --iti-flag-offset: -1093px;
}

.iti__cq {
  --iti-flag-offset: -1115px;
}

.iti__cr {
  --iti-flag-offset: -1137px;
}

.iti__cu {
  --iti-flag-offset: -1159px;
}

.iti__cv {
  --iti-flag-offset: -1181px;
}

.iti__cw {
  --iti-flag-offset: -1203px;
}

.iti__cx {
  --iti-flag-offset: -1225px;
}

.iti__cy {
  --iti-flag-offset: -1247px;
}

.iti__cz {
  --iti-flag-offset: -1269px;
}

.iti__de {
  --iti-flag-offset: -1291px;
}

.iti__dg {
  --iti-flag-offset: -1313px;
}

.iti__dj {
  --iti-flag-offset: -1335px;
}

.iti__dk {
  --iti-flag-offset: -1357px;
}

.iti__dm {
  --iti-flag-offset: -1379px;
}

.iti__do {
  --iti-flag-offset: -1401px;
}

.iti__dz {
  --iti-flag-offset: -1423px;
}

.iti__ea {
  --iti-flag-offset: -1445px;
}

.iti__ec {
  --iti-flag-offset: -1467px;
}

.iti__ee {
  --iti-flag-offset: -1489px;
}

.iti__eg {
  --iti-flag-offset: -1511px;
}

.iti__eh {
  --iti-flag-offset: -1533px;
}

.iti__er {
  --iti-flag-offset: -1555px;
}

.iti__es {
  --iti-flag-offset: -1577px;
}

.iti__et {
  --iti-flag-offset: -1599px;
}

.iti__eu {
  --iti-flag-offset: -1621px;
}

.iti__ez {
  --iti-flag-offset: -1643px;
}

.iti__fi {
  --iti-flag-offset: -1665px;
}

.iti__fj {
  --iti-flag-offset: -1687px;
}

.iti__fk {
  --iti-flag-offset: -1709px;
}

.iti__fm {
  --iti-flag-offset: -1731px;
}

.iti__fo {
  --iti-flag-offset: -1753px;
}

.iti__fr {
  --iti-flag-offset: -1775px;
}

.iti__fx {
  --iti-flag-offset: -1797px;
}

.iti__ga {
  --iti-flag-offset: -1819px;
}

.iti__gb {
  --iti-flag-offset: -1841px;
}

.iti__gd {
  --iti-flag-offset: -1863px;
}

.iti__ge {
  --iti-flag-offset: -1885px;
}

.iti__gf {
  --iti-flag-offset: -1907px;
}

.iti__gg {
  --iti-flag-offset: -1929px;
}

.iti__gh {
  --iti-flag-offset: -1951px;
}

.iti__gi {
  --iti-flag-offset: -1973px;
}

.iti__gl {
  --iti-flag-offset: -1995px;
}

.iti__gm {
  --iti-flag-offset: -2017px;
}

.iti__gn {
  --iti-flag-offset: -2039px;
}

.iti__gp {
  --iti-flag-offset: -2061px;
}

.iti__gq {
  --iti-flag-offset: -2083px;
}

.iti__gr {
  --iti-flag-offset: -2105px;
}

.iti__gs {
  --iti-flag-offset: -2127px;
}

.iti__gt {
  --iti-flag-offset: -2149px;
}

.iti__gu {
  --iti-flag-offset: -2171px;
}

.iti__gw {
  --iti-flag-offset: -2193px;
}

.iti__gy {
  --iti-flag-offset: -2215px;
}

.iti__hk {
  --iti-flag-offset: -2237px;
}

.iti__hm {
  --iti-flag-offset: -2259px;
}

.iti__hn {
  --iti-flag-offset: -2281px;
}

.iti__hr {
  --iti-flag-offset: -2303px;
}

.iti__ht {
  --iti-flag-offset: -2325px;
}

.iti__hu {
  --iti-flag-offset: -2347px;
}

.iti__ic {
  --iti-flag-offset: -2369px;
}

.iti__id {
  --iti-flag-offset: -2391px;
}

.iti__ie {
  --iti-flag-offset: -2413px;
}

.iti__il {
  --iti-flag-offset: -2435px;
}

.iti__im {
  --iti-flag-offset: -2457px;
}

.iti__in {
  --iti-flag-offset: -2479px;
}

.iti__io {
  --iti-flag-offset: -2501px;
}

.iti__iq {
  --iti-flag-offset: -2523px;
}

.iti__ir {
  --iti-flag-offset: -2545px;
}

.iti__is {
  --iti-flag-offset: -2567px;
}

.iti__it {
  --iti-flag-offset: -2589px;
}

.iti__je {
  --iti-flag-offset: -2611px;
}

.iti__jm {
  --iti-flag-offset: -2633px;
}

.iti__jo {
  --iti-flag-offset: -2655px;
}

.iti__jp {
  --iti-flag-offset: -2677px;
}

.iti__ke {
  --iti-flag-offset: -2699px;
}

.iti__kg {
  --iti-flag-offset: -2721px;
}

.iti__kh {
  --iti-flag-offset: -2743px;
}

.iti__ki {
  --iti-flag-offset: -2765px;
}

.iti__km {
  --iti-flag-offset: -2787px;
}

.iti__kn {
  --iti-flag-offset: -2809px;
}

.iti__kp {
  --iti-flag-offset: -2831px;
}

.iti__kr {
  --iti-flag-offset: -2853px;
}

.iti__kw {
  --iti-flag-offset: -2875px;
}

.iti__ky {
  --iti-flag-offset: -2897px;
}

.iti__kz {
  --iti-flag-offset: -2919px;
}

.iti__la {
  --iti-flag-offset: -2941px;
}

.iti__lb {
  --iti-flag-offset: -2963px;
}

.iti__lc {
  --iti-flag-offset: -2985px;
}

.iti__li {
  --iti-flag-offset: -3007px;
}

.iti__lk {
  --iti-flag-offset: -3029px;
}

.iti__lr {
  --iti-flag-offset: -3051px;
}

.iti__ls {
  --iti-flag-offset: -3073px;
}

.iti__lt {
  --iti-flag-offset: -3095px;
}

.iti__lu {
  --iti-flag-offset: -3117px;
}

.iti__lv {
  --iti-flag-offset: -3139px;
}

.iti__ly {
  --iti-flag-offset: -3161px;
}

.iti__ma {
  --iti-flag-offset: -3183px;
}

.iti__mc {
  --iti-flag-offset: -3205px;
}

.iti__md {
  --iti-flag-offset: -3226px;
}

.iti__me {
  --iti-flag-offset: -3248px;
}

.iti__mf {
  --iti-flag-offset: -3270px;
}

.iti__mg {
  --iti-flag-offset: -3292px;
}

.iti__mh {
  --iti-flag-offset: -3314px;
}

.iti__mk {
  --iti-flag-offset: -3336px;
}

.iti__ml {
  --iti-flag-offset: -3358px;
}

.iti__mm {
  --iti-flag-offset: -3380px;
}

.iti__mn {
  --iti-flag-offset: -3402px;
}

.iti__mo {
  --iti-flag-offset: -3424px;
}

.iti__mp {
  --iti-flag-offset: -3446px;
}

.iti__mq {
  --iti-flag-offset: -3468px;
}

.iti__mr {
  --iti-flag-offset: -3490px;
}

.iti__ms {
  --iti-flag-offset: -3512px;
}

.iti__mt {
  --iti-flag-offset: -3534px;
}

.iti__mu {
  --iti-flag-offset: -3556px;
}

.iti__mv {
  --iti-flag-offset: -3578px;
}

.iti__mw {
  --iti-flag-offset: -3600px;
}

.iti__mx {
  --iti-flag-offset: -3622px;
}

.iti__my {
  --iti-flag-offset: -3644px;
}

.iti__mz {
  --iti-flag-offset: -3666px;
}

.iti__na {
  --iti-flag-offset: -3688px;
}

.iti__nc {
  --iti-flag-offset: -3710px;
}

.iti__ne {
  --iti-flag-offset: -3732px;
}

.iti__nf {
  --iti-flag-offset: -3752px;
}

.iti__ng {
  --iti-flag-offset: -3774px;
}

.iti__ni {
  --iti-flag-offset: -3796px;
}

.iti__nl {
  --iti-flag-offset: -3818px;
}

.iti__no {
  --iti-flag-offset: -3840px;
}

.iti__np {
  --iti-flag-offset: -3862px;
}

.iti__nr {
  --iti-flag-offset: -3877px;
}

.iti__nu {
  --iti-flag-offset: -3899px;
}

.iti__nz {
  --iti-flag-offset: -3921px;
}

.iti__om {
  --iti-flag-offset: -3943px;
}

.iti__pa {
  --iti-flag-offset: -3965px;
}

.iti__pe {
  --iti-flag-offset: -3987px;
}

.iti__pf {
  --iti-flag-offset: -4009px;
}

.iti__pg {
  --iti-flag-offset: -4031px;
}

.iti__ph {
  --iti-flag-offset: -4053px;
}

.iti__pk {
  --iti-flag-offset: -4075px;
}

.iti__pl {
  --iti-flag-offset: -4097px;
}

.iti__pm {
  --iti-flag-offset: -4119px;
}

.iti__pn {
  --iti-flag-offset: -4141px;
}

.iti__pr {
  --iti-flag-offset: -4163px;
}

.iti__ps {
  --iti-flag-offset: -4185px;
}

.iti__pt {
  --iti-flag-offset: -4207px;
}

.iti__pw {
  --iti-flag-offset: -4229px;
}

.iti__py {
  --iti-flag-offset: -4251px;
}

.iti__qa {
  --iti-flag-offset: -4273px;
}

.iti__re {
  --iti-flag-offset: -4295px;
}

.iti__ro {
  --iti-flag-offset: -4317px;
}

.iti__rs {
  --iti-flag-offset: -4339px;
}

.iti__ru {
  --iti-flag-offset: -4361px;
}

.iti__rw {
  --iti-flag-offset: -4383px;
}

.iti__sa {
  --iti-flag-offset: -4405px;
}

.iti__sb {
  --iti-flag-offset: -4427px;
}

.iti__sc {
  --iti-flag-offset: -4449px;
}

.iti__sd {
  --iti-flag-offset: -4471px;
}

.iti__se {
  --iti-flag-offset: -4493px;
}

.iti__sg {
  --iti-flag-offset: -4515px;
}

.iti__sh {
  --iti-flag-offset: -4537px;
}

.iti__si {
  --iti-flag-offset: -4559px;
}

.iti__sj {
  --iti-flag-offset: -4581px;
}

.iti__sk {
  --iti-flag-offset: -4603px;
}

.iti__sl {
  --iti-flag-offset: -4625px;
}

.iti__sm {
  --iti-flag-offset: -4647px;
}

.iti__sn {
  --iti-flag-offset: -4669px;
}

.iti__so {
  --iti-flag-offset: -4691px;
}

.iti__sr {
  --iti-flag-offset: -4713px;
}

.iti__ss {
  --iti-flag-offset: -4735px;
}

.iti__st {
  --iti-flag-offset: -4757px;
}

.iti__su {
  --iti-flag-offset: -4779px;
}

.iti__sv {
  --iti-flag-offset: -4801px;
}

.iti__sx {
  --iti-flag-offset: -4823px;
}

.iti__sy {
  --iti-flag-offset: -4845px;
}

.iti__sz {
  --iti-flag-offset: -4867px;
}

.iti__ta {
  --iti-flag-offset: -4889px;
}

.iti__tc {
  --iti-flag-offset: -4911px;
}

.iti__td {
  --iti-flag-offset: -4933px;
}

.iti__tf {
  --iti-flag-offset: -4955px;
}

.iti__tg {
  --iti-flag-offset: -4977px;
}

.iti__th {
  --iti-flag-offset: -4999px;
}

.iti__tj {
  --iti-flag-offset: -5021px;
}

.iti__tk {
  --iti-flag-offset: -5043px;
}

.iti__tl {
  --iti-flag-offset: -5065px;
}

.iti__tm {
  --iti-flag-offset: -5087px;
}

.iti__tn {
  --iti-flag-offset: -5109px;
}

.iti__to {
  --iti-flag-offset: -5131px;
}

.iti__tr {
  --iti-flag-offset: -5153px;
}

.iti__tt {
  --iti-flag-offset: -5175px;
}

.iti__tv {
  --iti-flag-offset: -5197px;
}

.iti__tw {
  --iti-flag-offset: -5219px;
}

.iti__tz {
  --iti-flag-offset: -5241px;
}

.iti__ua {
  --iti-flag-offset: -5263px;
}

.iti__ug {
  --iti-flag-offset: -5285px;
}

.iti__uk {
  --iti-flag-offset: -5307px;
}

.iti__um {
  --iti-flag-offset: -5329px;
}

.iti__un {
  --iti-flag-offset: -5351px;
}

.iti__us {
  --iti-flag-offset: -5373px;
}

.iti__uy {
  --iti-flag-offset: -5395px;
}

.iti__uz {
  --iti-flag-offset: -5417px;
}

.iti__va {
  --iti-flag-offset: -5439px;
}

.iti__vc {
  --iti-flag-offset: -5456px;
}

.iti__ve {
  --iti-flag-offset: -5478px;
}

.iti__vg {
  --iti-flag-offset: -5500px;
}

.iti__vi {
  --iti-flag-offset: -5522px;
}

.iti__vn {
  --iti-flag-offset: -5544px;
}

.iti__vu {
  --iti-flag-offset: -5566px;
}

.iti__wf {
  --iti-flag-offset: -5588px;
}

.iti__ws {
  --iti-flag-offset: -5610px;
}

.iti__xk {
  --iti-flag-offset: -5632px;
}

.iti__ye {
  --iti-flag-offset: -5654px;
}

.iti__yt {
  --iti-flag-offset: -5676px;
}

.iti__za {
  --iti-flag-offset: -5698px;
}

.iti__zm {
  --iti-flag-offset: -5720px;
}

.iti__zw {
  --iti-flag-offset: -5742px;
}

.iti__globe {
  background-image: url(../../assets/images/intl-tel-input/globe.webp);
  background-size: contain;
  background-position: right;
  box-shadow: none;
  height: 19px;
}

@media (min-resolution: 2x) {
  .iti__flag {
    background-image: url(../../assets/images/intl-tel-input/flags@2x.webp);
  }
  .iti__globe {
    background-image: url(../../assets/images/intl-tel-input/globe@2x.webp);
  }
}
