.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: ac(50px, 13px) 0;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;

  &.scroll {
    background-color: rgba(0, 0, 0, 0.8);
    padding: ac(20px, 13px) 0;

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px) !important;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;

    .header__drop-menu {
      top: calc(100% + 70px);
    }

    @mixin media 651 {
      /* background: rgba(0, 0, 0, 1); */
      padding: 4px 0 20px 0;
    }
  }

  @mixin media 651 {
    padding: 4px 0 20px 0;

    /* background: rgba(0, 0, 0, 1); */
  }
}

.header__content {
  display: flex;
  align-items: center;
  width: 100%;

  @mixin media 651 {
    display: none;
  }
}

.header__main-nav {
  display: flex;
  align-items: center;
  gap: ac(70px, 20px);
}

.header__logo-link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ac(70px, 20px);

  width: ac(109px, 60px);

  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  @mixin media 651 {
    margin-right: 0;

    width: 70px;
  }
}

.header__additional-nav {
  display: flex;
  align-items: center;
  gap: ac(30px, 16px);

  margin-left: auto;
}

.header__nav-link {
  color: var(--color-white);

  font-size: ac(16px, 14px);
  font-weight: 400;
  line-height: 1.219;

  transition: color 0.3s ease-in-out;

  &:hover {
    color: var(--cl-green);
  }
}

.header__socials-wrap {
  display: flex;
  align-items: center;
  gap: ac(16px, 8px);
}

.header__socials-item {
  overflow: hidden;

  border-radius: 4px;

  color: rgba(0, 179, 85, 1);

  transition: color 0.3s ease-in-out;

  &:hover {
    color: var(--color-white);
  }
}

.header__burger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 24px;
  height: 16px;
  cursor: pointer;

  background: transparent;
  border: none;
  padding: 0;

  span {
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--cl-white);
    transition: all 0.3s ease-in-out;
  }

  &.active {
    span:nth-child(1) {
      transform: translateY(7px) rotate(45deg);
    }

    span:nth-child(2) {
      opacity: 0;
      transform: translateX(70%);
    }

    span:nth-child(3) {
      transform: translateY(-7px) rotate(-45deg);
    }
  }
}

.header__drop-menu-wrap {
  position: relative;
}

.header__drop-menu {
  position: absolute;
  top: calc(100% + 50px);
  right: 0;

  display: flex;
  flex-direction: column;
  gap: ac(20px, 10px);
  align-items: end;

  padding: 12px 0;
  border-radius: 12px;

  background-color: rgba(0, 0, 0, 0.8);

  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);

  transition: all 0.3s ease-in-out;

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

.header__drop-menu-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 13.5px;

  width: 100%;

  padding: 12px 20px 12px 12px;
  /* margin-right: -12px; */
  border-radius: 6px;

  color: var(--cl-white);

  font-size: 20px;
  font-weight: 400;
  text-align: right;
  line-height: 1.219;

  transition: color 0.3s ease-in-out;

  &:hover {
    color: var(--cl-green);

    span {
      &::before {
        height: 14px;
        background: var(--cl-green);
      }

      &::after {
        transform: translate(-50%, -50%) scaleY(1);
      }
    }
  }

  span {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 24px;
    height: 24px;

    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      width: 5px;
      height: 10px;

      background: rgba(34, 35, 37, 1);

      transition: all 0.3s ease-in-out;
    }

    &::after {
      content: '';
      position: absolute;

      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scaleY(0);

      width: 1px;
      height: 22px;

      background: var(--cl-green);

      transition: transform 0.3s ease-in-out;
    }
  }
}

.header__socials-icon {
  border-radius: 4px;
}

.lang-selector {
  position: relative;

  width: fit-content;

  &.active {
    .lang-selector__selected {
      border-radius: 6px 6px 0 0;

      &::after {
        transform: rotate(180deg);
      }
    }
    .lang-selector__dropdown {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
}

.lang-selector__selected {
  position: relative;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: ac(84px, 72px);

  padding: ac(8px, 6px) ac(10px, 8px);
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.2);

  background: #1d2023;
  color: var(--cl-white);

  font-size: ac(18px, 16px);
  line-height: 1.22;

  transition: all 0.3s ease-in-out;

  &::after {
    content: '';
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent rgba(255, 255, 255, 0.5) transparent;
    transform: rotate(0deg);

    transition: all 0.3s ease-in-out;
  }
}

.lang-selector__dropdown {
  position: absolute;
  top: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  width: 100%;

  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0 0 6px 6px;

  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);

  transition: all 0.3s ease-in-out;
}

.lang-selector__dropdown-item {
  width: 100%;

  padding: 8px 10px;

  background: #08090a;
  color: rgba(255, 255, 255, 0.5);

  font-size: 18px;
  line-height: 1.22;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
}

.header__content-mob {
  display: none;

  @mixin media 651 {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    align-items: center;
    justify-content: space-between;
    width: 100%;

    & > *:nth-child(2) {
      justify-self: center;
    }

    & > *:nth-child(3) {
      justify-self: flex-end;
    }
  }

  .lang-selector {
    z-index: 999;
    position: relative;
  }
}

.header__mob-menu {
  position: absolute;
  top: calc(var(--header-height) - 3px);
  left: 0;
  bottom: 0;
  right: 0;

  overflow: auto;

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  padding: 52px 0px 44px;
  height: calc(100vh - var(--header-height) - 3px);

  background: rgba(0, 0, 0, 1);
  z-index: 998;
  opacity: 0;

  transform: translateX(100%);
  transition: all 0.3s ease-in-out;

  &.active {
    transform: translateX(0);
    opacity: 1;
  }

  .header__bottom-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: auto;
  }

  .header__mob-phone {
    margin: 0 auto 24px;

    color: #ffffff;

    font-size: 20px;

    transition: all 0.3s ease-in-out;

    &:hover {
      color: var(--cl-green);
    }
  }

  .header__socials-wrap {
    gap: 36px;
  }
}

.header__mob-menu-link {
  color: #ffffffb2;

  font-size: 20px;
  font-weight: 400;

  transition: all 0.3s ease-in-out;

  &:hover {
    color: #ffffff;
    font-weight: 600;
  }
}
