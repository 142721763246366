.contact-us-section {
}

.contact-form {
  position: relative;

  padding: ac(80px, 32px) ac(80px, 24px);
  border-radius: ac(36px, 16px);

  background: linear-gradient(
    216deg,
    #ffffff 25.97%,
    rgba(255, 255, 255, 0) 36.63%,
    rgba(255, 255, 255, 0) 67.82%,
    #ffffff 78.13%
  );

  &::before {
    content: '';
    position: absolute;
    inset: ac(2px, 1px, 1280);
    background: linear-gradient(
      180deg,
      rgba(22, 24, 27, 1) 0%,
      rgba(8, 9, 10, 1) 100%
    );
    border-radius: ac(36px, 16px);
  }

  & > * {
    position: relative;
    z-index: 1;
  }
}

.contact-form__inner-wrap {
  width: fit-content;
  margin: 0 auto;
}

.contact-us-section__title {
  margin-bottom: 8px;

  font-size: ac(100px, 48px);
  text-align: center;
}

.contact-us_description {
  margin-bottom: ac(60px, 24px);

  color: rgba(255, 255, 255, 0.7);

  font-size: ac(16px, 14px);
  line-height: 1.219;
  text-align: center;
}

.contact-form__row {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: ac(20px, 12px);

  margin-bottom: ac(30px, 12px);

  &:last-child {
    margin-bottom: ac(60px, 32px);
  }

  &.row-1 {
    grid-template-columns: 1fr;
  }

  @mixin media 769 {
    grid-template-columns: 1fr;
  }
}

.contact-form__btn {
  margin: 0 auto;
}
