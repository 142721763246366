.inside-profit {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  gap: ac(140px, 30px, 1440);

  @mixin media 1281 {
    justify-items: center;
    grid-template-columns: 1fr;
    grid-template-areas:
      'title'
      'text'
      'video';
  }

  @mixin media 701 {
    justify-items: start;
  }
}

.inside-profit__title {
  font-family: var(--font-title);
  font-size: ac(101px, 98px);
  line-height: 1;
  text-transform: uppercase;

  @mixin media 1281 {
    grid-area: title;
  }
}

.inside-profit__video-wrap {
  position: relative;

  padding: ac(30px, 14px);
  border-radius: ac(32px, 16px);

  background: linear-gradient(
    57deg,
    #e83334 0%,
    rgba(232, 51, 52, 0) 59.75%,
    rgba(232, 51, 52, 0) 75.3%,
    #e83334 100%
  );
  box-shadow: 0px 4px 250px 0px rgba(255, 73, 75, 0.4);

  @mixin media 1281 {
    grid-area: video;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 2px;

    background: #000000;
    border-radius: inherit;
  }

  @mixin media 1281 {
    max-width: 900px;
  }
}

.inside-profit__play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.inside-profit__video-preview {
  position: relative;
  z-index: 1;

  border-radius: 24px;
}

.inside-profit__text-wrap {
  font-size: ac(18px, 14px);

  color: rgba(255, 255, 255, 0.7);

  strong {
    font-weight: 500;
    color: #ffffff;
  }

  @mixin media 1281 {
    max-width: 700px;
    grid-area: text;
  }
}

.inside-profit__logo {
  @mixin media 1281 {
    max-width: 339px;
  }
}
