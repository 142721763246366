@font-face {
  font-family: 'Bebas Neue Cyrillic';
  src: url(../../assets/fonts/bebasneuecyrillic.ttf);
}

:root {
  --cl-black: #000000;
  --cl-black-rgb: 0 0 0;

  --cl-white: #ffffff;
  --cl-green: #2edc80;
  --cl-red: #fd494b;

  --font-main: Montserrat, sans-serif;
  --font-title: 'Bebas Neue Cyrillic', sans-serif;

  --vh: calc(100vh / 100);
}
