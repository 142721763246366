.reviews-section__title {
  text-align: center;
}

.reviews {
  position: relative;

  display: flex;

  min-height: ac(1100px, 700px, 1024);

  margin-top: -70px;
  padding-top: ac(200px, 100px, 1024);

  @mixin media 1025 {
    margin-top: -60px;

    padding-top: 130px;
  }
}

.reviews__phone-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
  max-width: ac(555px, 400px, 1280);

  margin: 0 auto;

  @mixin media 769 {
    max-width: 340px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    filter: blur(110px);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    opacity: 0.5;

    width: 706px;
    height: 910px;

    border-radius: 50%;

    background: #ffffff26;

    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
  }
}

.reviews__content {
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
}

.reviews__slider-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;

  width: 100%;

  margin: 0 auto;

  @mixin media 1025 {
    justify-content: center;
  }
}

.reviews-slider {
  max-width: ac(335px, 220px);
  /* max-width: ac(1230px, 830px); */
  height: ac(650px, 480px, 1440);

  margin-bottom: 20px;

  @mixin media 769 {
    max-width: 214px;
    height: 400px;
    margin-bottom: 0;
  }

  .swiper-slide {
    display: flex;
    align-items: center;

    opacity: 0;
    filter: blur(3px);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;

    transition: all 0.2s;

    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;

    &.swiper-slide-active {
      filter: none;

      .review {
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px) !important;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
      }
    }

    &.swiper-slide-prev,
    &.swiper-slide-next,
    &.swiper-slide-active {
      opacity: 1;
    }
  }
}

.reviews-slider__btn {
  z-index: 2;

  flex-shrink: 0;

  @mixin media 1025 {
    &:not(&.mob) {
      display: none;
    }
  }
}

.review {
  position: relative;

  padding: ac(23px, 12px) ac(25px, 12px) ac(23px, 12px);
  border: 1px solid #2af895;
  border-radius: 16px 16px 16px 0px;

  background: #001e0e80;
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px) !important;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;

  transition: all 0.3s 0.1s;
}

.review__name {
  margin-bottom: 4px;

  font-family: 'Inter', sans-serif;
  font-size: ac(14px, 12px);
  font-weight: 600;
}

.review__text {
  overflow: auto;

  max-height: ac(420px, 300px);

  margin-bottom: ac(8px, 2px);

  color: #ffffffcc;

  font-family: 'Inter', sans-serif;
  font-size: ac(16px, 12px);
  font-weight: 300;
  line-height: 1.4;
  letter-spacing: 0.01em;

  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.review__reactions {
  width: fit-content;

  padding: ac(6px, 4px) ac(10px, 6px);
  border-radius: 999px;

  color: var(--cl-white);
  background: rgba(255, 255, 255, 0.1);

  font-size: ac(14px, 10px);
}

.review__more-btn {
  padding: ac(20px, 12px, 1280) ac(32px, 24px, 1280);
  border-radius: ac(12px, 8px, 1024);

  .btn__text {
    font-size: ac(16px, 12px, 1024);
  }
}

.review__slider-nav-mob {
  display: none;

  @mixin media 1025 {
    display: flex;
    gap: 48px;

    margin-top: 88px;
  }
}
