.scalping-effectivity-section {
  @mixin media 551 {
    padding-top: 120px;
  }
}

.scalping-effectivity-section__bg {
  position: absolute;
  left: 0;
  top: -300px;
  z-index: -1;
  opacity: 0.7;
}

.scalping-effectivity-section__content {
  /* display: grid;
  grid-template-columns: 683fr 988fr;
  gap: ac(27px, 12px); */

  /* @mixin media 1025 {
    max-width: 800px;
    grid-template-columns: 1fr;

    margin: 0 auto;
  }

  @mixin media 769 {
    max-width: 470px;
  } */
}

.scalping-effectivity-slider__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 37px;
}

.scalping-effectivity-slider__btn {
  flex-shrink: 0;
}

.scalping-effectivity-section__image-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;

  @mixin media 769 {
    grid-template-columns: 1fr;
  }
}
