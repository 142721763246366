.what-you-get {
  --columns: 4;
  --gap-x: ac(30px, 16px);
  --gap-y: ac(70px, 30px);

  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: var(--gap-y) var(--gap-x);
  flex-wrap: wrap;

  .what-you-get__item {
    --width: calc(
      (100% - (var(--columns) - 1) * var(--gap-x)) / var(--columns)
    );
    width: var(--width);
  }

  @mixin media 1561 {
    --columns: 3;
  }

  @mixin media 1281 {
    --columns: 2;
  }

  @mixin media 769 {
    --columns: 1;
    --gap-y: 42px;
  }
}

.what-you-get-section__title {
  margin-bottom: 77px;
}

.what-you-get__item {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  aspect-ratio: 402 / 205;

  padding: 5px 37px 45px;
  border-radius: 30px;

  background: linear-gradient(180deg, rgba(0, 255, 203, 0) 0%, #00ffcb 100%);

  @mixin media 769 {
    aspect-ratio: 343 / 148;

    max-width: 550px;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 1px;
    z-index: 1;

    border-radius: 30px;
    background: #151515;
    /* background: radial-gradient(
      264.92% 100% at 50% 0%,
      rgba(255, 255, 255, 0.105) 0%,
      rgba(255, 255, 255, 0.045) 29.88%
    ); */
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    width: 191px;
    height: 59px;
    border-radius: 50%;

    background: #00ffcb4d;

    filter: blur(50px);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
  }
}

.what-you-get__item-icon,
.what-you-get__item-title {
  z-index: 1;
}

.what-you-get__item-icon {
  max-width: ac(134px, 93px);
  height: ac(72px, 52px);

  margin-top: -17px;
  margin-bottom: ac(47px, 20px);
}

.what-you-get__item-title {
  font-size: ac(20px, 18px);
  font-weight: 500;
  line-height: 1.22;
  text-align: center;
}
