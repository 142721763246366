.footer {
  padding-top: ac(150px, 15px);
  padding-bottom: ac(50px, 32px);
}

.footer__nav-wrap {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 10px;

  margin-bottom: 40px;

  @mixin media 1025 {
    grid-template-columns: 1fr;
  }

  @mixin media 451 {
    gap: 20px;
  }
}

.footer__nav {
  display: flex;
  align-items: center;
  gap: ac(50px, 20px, 1440);

  &:last-child {
    justify-self: end;

    @mixin media 1025 {
      justify-self: center;
    }
  }

  &:first-child {
    @mixin media 1025 {
      justify-self: center;
      grid-row: 2;
    }
  }

  @mixin media 451 {
    flex-direction: column;
    gap: 20px;
  }
}

.footer__nav-socials {
  display: flex;
  align-items: center;
  gap: 16px;

  @mixin media 1025 {
    display: none;
  }
}

.footer__logo {
  width: 100%;
  max-width: 115px;
  justify-self: center;

  img {
    width: 100%;
  }

  @mixin media 769 {
    margin-bottom: 24px;
  }
}

.footer__nav-item {
  color: rgba(255, 255, 255, 0.7);

  font-size: ac(16px, 14px, 1440);
  line-height: 1.219;

  transition: color 0.3s;

  &:hover {
    color: var(--cl-green);
  }
}

.footer__nav-socials-item {
  overflow: hidden;

  border-radius: 4px;

  color: rgba(0, 179, 85, 1);

  transition: color 0.3s;

  &:hover {
    .footer__nav-socials-icon {
      color: var(--cl-white);
    }
  }
}

.footer__nav-socials-icon {
  border-radius: 4px;

  color: rgba(0, 179, 85, 1);

  transition: color 0.3s;
}

.footer__contacts {
  display: flex;
  flex-direction: column;
}

.footer__phone {
  margin: 0 auto ac(16px, 12px);

  color: var(--cl-white);

  transition: color 0.3s;

  &:hover {
    color: var(--cl-green);
  }

  @mixin media 451 {
    font-size: 20px;
  }
}

.footer__bottom-wrap {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: ac(30px, 10px);
  place-items: start;

  font-size: ac(16px, 14px);
  line-height: 1.219;

  @mixin media 1025 {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}

.footer__bottom-item {
  display: flex;
  align-items: center;
  gap: 10px ac(30px, 10px, 1440);
  flex-wrap: wrap;

  color: rgba(255, 255, 255, 0.5);

  font-size: ac(16px, 14px, 1440);

  &:first-child {
    @mixin media 1025 {
      grid-row: 3;
    }
  }

  &:last-child {
    justify-self: end;

    @mixin media 1025 {
      justify-self: center;
      grid-row: 2;
    }
  }

  @mixin media 1025 {
    justify-self: center;
    text-align: center;
  }
}

.footer__address {
  justify-self: center;

  text-align: center;
  color: rgba(255, 255, 255, 0.7);

  @mixin media 769 {
    /* margin-bottom: 28px; */
  }

  @mixin media 451 {
    text-wrap: balance;
  }
}

.footer__credentials-link {
  display: block;

  margin-left: auto;
  border: none;

  color: rgba(255, 255, 255, 0.5);
  background: transparent;

  font-size: ac(16px, 14px, 1440);
  text-align: right;
  text-decoration: underline;

  transition: color 0.3s;

  &:hover {
    color: var(--cl-green);
  }

  @mixin media 1025 {
    text-decoration: underline;
    text-align: center;
  }

  @mixin media 551 {
    width: 100%;

    margin-left: unset;
  }
}

.notify {
  position: fixed;
  z-index: 9999;
  right: 20px;
  bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: ac(300px, 200px);
  border: 1px solid rgba(255, 255, 255, 0.1);

  border-radius: ac(10px, 5px);
  padding: ac(20px, 10px);

  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px) !important;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;

  font-size: 14px;

  transition: ease 0.3s;

  transform: translateX(110%);
  opacity: 0;

  &.error {
    border-color: rgba(255, 0, 0, 0.2);
  }

  &.active {
    transform: translateX(0);
    opacity: 1;
  }
}
