.profit-what-you-get {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    'frst title title'
    'scnd thrd  frth'
    'fth  sxth  svnth';
  gap: ac(100px, 20px) ac(61px, 20px);
  align-items: center;

  @mixin media 769 {
    grid-template-columns: 1fr;
    grid-template-areas:
      'title'
      'frst'
      'scnd'
      'thrd'
      'frth'
      'fth'
      'sxth'
      'svnth';
  }
}

.profit-what-you-get__bg {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;

  @mixin media 769 {
    min-width: 1070px;
    left: 0;
    transform: translate(0%, -35%);
  }

  @mixin min-media 1920 {
    mask-image: linear-gradient(
      to right,
      transparent 0%,
      black 3%,
      black 97%,
      transparent 100%
    );
  }
}

.profit-what-you-get__title {
  z-index: 1;

  grid-area: title;

  font-family: var(--font-title);
  text-align: end;
  font-size: ac(148px, 48px);
  line-height: 1;

  @mixin media 769 {
    text-align: center;
  }
}

.you-get-card {
  position: relative;
  /* overflow: hidden; */

  height: 100%;

  padding: ac(44px, 28px) ac(20px, 12px);
  border-bottom: 2px solid #ffffff;

  transition: all ease 500ms;

  @mixin media 769 {
    min-height: 95px;
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    transform: scaleY(0);
    transform-origin: bottom;

    background: radial-gradient(
      60% 90% at 50.15% 115%,
      rgba(232, 51, 52, 0.5) 0%,
      #0000 100%
    );

    opacity: 0;

    transition: all ease 500ms;
  }

  &:hover {
    border-color: #e83334;

    &::after {
      opacity: 1;
      transform: scaleY(1);
    }

    .you-get-card__num {
      color: rgba(255, 73, 75, 0.2);
    }
  }

  &:nth-of-type(1) {
    grid-area: frst;
  }

  &:nth-of-type(2) {
    grid-area: scnd;
  }

  &:nth-of-type(3) {
    grid-area: thrd;
  }

  &:nth-of-type(4) {
    grid-area: frth;
  }

  &:nth-of-type(5) {
    grid-area: fth;
  }

  &:nth-of-type(6) {
    grid-area: sxth;
  }

  &:nth-of-type(7) {
    grid-area: svnth;
  }
}

.you-get-card__title {
  font-size: ac(22px, 16px);
  font-weight: 600;
  text-transform: uppercase;
}

.you-get-card__num {
  position: absolute;
  bottom: 0;
  right: -2px;

  color: rgba(255, 255, 255, 0.12);

  font-family: var(--font-title);
  font-size: ac(160px, 121px);
  line-height: 0.76;

  transition: all ease 500ms;
}
