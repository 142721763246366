.achievements__top {
  --gap: ac(80px, 24px, 1024);
  display: flex;
  align-items: center;

  gap: var(--gap);

  margin-bottom: ac(30px, 16px);

  & > * {
    flex-basis: calc(50% - var(--gap) / 2);
  }

  @mixin media 769 {
    flex-direction: column-reverse;
  }
}

.achievements__slider-nav {
  display: flex;
  align-items: center;
  gap: ac(60px, 20px, 1280);

  @mixin media 769 {
    display: none;
  }
}

.achievements__title-wrap {
  display: flex;
  justify-content: space-between;
}

.achievements__title {
  margin-bottom: 0;
}

.achievements__bottom {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: ac(80px, 24px, 1024);

  @mixin media 769 {
    display: flex;
    flex-direction: column;
    gap: 54px;
  }
}

.achievements__tabs-slider-wrap {
  max-width: 100%;
  width: 100%;
}

.achievements__tabs-nav {
  display: flex;
  justify-content: space-between;
  gap: ac(5px, 0px);

  padding: ac(12px, 4px);

  border-radius: ac(8px, 6px);
  border: 1px solid transparent;
  background: linear-gradient(var(--cl-black), var(--cl-black)) padding-box,
    linear-gradient(
        200deg,
        #ffffff 35.39%,
        rgba(255, 255, 255, 0) 49.76%,
        rgba(255, 255, 255, 0) 54.23%,
        #ffffff 62.38%
      )
      border-box;
}

.achievements__tabs-nav-item {
  position: relative;
  z-index: 1;

  padding: ac(16px, 6px) ac(24px, 8px);
  border-radius: ac(8px, 4px);
  border: none;
  color: rgba(255, 255, 255, 0.5);
  background: transparent;

  font-size: ac(16px, 14px);
  font-weight: 300;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;

  cursor: pointer;
  transition: 0.5s ease;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;

    border-radius: ac(8px, 4px);

    background: linear-gradient(264.82deg, #16171a 0%, #2d3135 100%);

    opacity: 0;
  }

  &.active {
    color: var(--cl-white);
    font-weight: 600;
    &::before {
      opacity: 1;
    }
  }
}

.achievements__info-tab {
  * {
    font-size: ac(16px, 14px);
    line-height: 1.219;
    text-align: justify;
  }

  p {
    &:not(:last-child) {
      margin-bottom: ac(50px, 20px);

      color: rgba(255, 255, 255, 0.7);
    }
  }

  ul {
    padding-left: 20px;

    &:not(:last-child) {
      margin-bottom: ac(50px, 20px);
    }

    li {
      list-style: disc;

      &:not(:last-child) {
        margin-bottom: ac(17px, 9px);
      }

      span {
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
}

.achievements__screenshot {
  border-radius: ac(8px, 0px);
}

.achievements__screenshot-slider {
  max-width: 100%;

  border-radius: 8px;

  @mixin media 769 {
    max-width: 550px;
  }
}

.achievements__screenshot-slider-nav {
  display: none;
  align-items: center;
  justify-content: center;
  gap: 48px;

  margin-top: 34px;

  @mixin media 769 {
    display: flex;
  }
}

.achievements__screenshot-slider-bullets {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: ac(22px, 8px);

  .swiper-pagination-bullet {
    --swiper-pagination-bullet-horizontal-gap: 6px;

    background: #2d3135;
    opacity: 1;

    width: 100%;
    max-width: ac(29px, 13px);
    height: ac(6px, 3px);
    border-radius: 999px;

    transition: 0.5s ease;

    &.swiper-pagination-bullet-active {
      background: var(--cl-white);
    }
  }
}
