.hero-section {
  display: flex;

  min-height: 100vh;
  padding-bottom: 20px;

  /* background: url('../../assets/images/hero-bg.jpg') no-repeat center center;
  background-size: cover; */

  /* @mixin min-media 1920 {
    background-size: 1920px auto;
  } */

  @mixin media 551 {
    min-height: 815px;

    background: linear-gradient(
      180deg,
      #080a0b 0%,
      #090a0c 33.11%,
      #08090a 57.14%,
      #0d0f12 75.44%,
      #0e1114 100%
    );

    padding-bottom: 45px;
  }
}

.hero-section__bg-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);

  width: 100%;
  height: 100%;

  max-width: 1920px;

  display: block;

  background: rgba(0, 0, 0, 0.5);

  @mixin min-media 1920 {
    mask-image: radial-gradient(black 60%, transparent 80%);
  }

  @mixin media 551 {
    z-index: 0;
    max-height: 574px;

    mask-image: linear-gradient(
      to bottom,
      black 0%,
      black 90%,
      transparent 95%
    );
  }
}

.hero-section__bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero {
  --padding-top: 0px;

  display: flex;

  height: 100%;

  padding-top: var(--padding-top);

  @mixin media 551 {
    padding-top: 280px;

    @mixin media-height 812 {
      /* padding-top: ac(280px, 120px, 551, 812); */
    }
  }
}

.hero__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-height: 100%;
  flex: 1 1 auto;
}

.hero__title-wrap {
  position: relative;
  z-index: 1;

  width: fit-content;
  margin: 0 auto;
  padding: ac(57px, 0px) ac(38px, 0px) ac(47px, 0px);

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;

    display: block;
    border-radius: 12px;

    background: rgba(0, 0, 0, 0.5);

    filter: blur(30px);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
  }
}

.hero__title,
.hero__title-mob {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-family: var(--font-title);
  font-size: ac(96px, 52px);
  font-weight: 400;
  line-height: 1.05;
  letter-spacing: 0.05rem;
  text-transform: uppercase;

  color: var(--cl-white);
}

.hero__title {
  @mixin media 601 {
    display: none;
  }
}

.hero__title-mob {
  display: none;

  @mixin media 601 {
    display: flex;
  }
}

.hero__title-shadowed {
  background: linear-gradient(
    rgba(153, 153, 153, 0.7),
    rgba(255, 255, 255, 0.7)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero__list {
  display: flex;
  justify-content: center;
  align-items: center;

  gap: ac(75px, 5px);
  margin-top: 4px;
  margin-bottom: ac(40px, 10px);

  @mixin media 374 {
    flex-wrap: wrap;
  }
}

.hero__list-item {
  color: var(--cl-green);

  font-size: ac(29px, 11px);
  font-weight: 300;
  letter-spacing: 4px;
  text-transform: uppercase;

  @mixin media 451 {
    letter-spacing: 0.2em;
  }
}

.hero__btn {
  margin: 0 auto;
}

.hero__partners-title {
  position: relative;
  z-index: 1;

  margin-bottom: 20px;

  color: rgba(255, 255, 255, 0.7);

  font-size: ac(34px, 20px);
  font-family: var(--font-title);
  letter-spacing: 2%;
  text-align: center;
}

.hero__partner-list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;

  width: 100%;

  margin-top: auto;

  @mixin media 1440 {
    gap: 12px 4px;
  }
}

.hero__partner-item {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: ac(12px, 6px);

  background: linear-gradient(
    150deg,
    #ffffff 26.09%,
    rgba(255, 255, 255, 0) 44.42%,
    rgba(255, 255, 255, 0) 56.94%,
    #ffffff 72.14%
  );

  width: ac(237px, 111px);
  height: ac(71px, 36px);

  @mixin media 1280 {
    padding: 9px 24px;
  }

  @mixin media 551 {
    padding: 9px 12px;

    &.okx {
      img {
        max-height: 12px;
      }
    }
  }

  &:nth-child(n + 4) {
    background: linear-gradient(
      -150deg,
      #ffffff 26.09%,
      rgba(255, 255, 255, 0) 44.42%,
      rgba(255, 255, 255, 0) 56.94%,
      #ffffff 72.14%
    );
  }

  &::before {
    content: '';
    position: absolute;
    inset: 1px;

    display: block;
    border-radius: ac(12px, 6px);

    background: rgba(29, 32, 35, 1);
  }

  img {
    position: relative;
    z-index: 1;

    @mixin media 1440 {
      max-width: 80%;
    }

    @mixin media 1280 {
      width: 100%;
      max-width: 100%;

      max-height: ac(30px, 20px, 769, 1280);
    }

    @mixin media 551 {
      max-height: 100%;
    }
  }
}

.hero__additional-info {
  margin-top: ac(36px, 13px);

  color: rgba(255, 255, 255, 0.5);

  font-size: ac(16px, 11px);
  font-style: italic;
  line-height: 1.219;
  text-align: center;
}

canvas {
  width: 350px;
  height: 350px;
}
