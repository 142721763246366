.popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.5s ease;

  &.is-open {
    visibility: visible;
    opacity: 1;
  }

  background: rgba(0, 0, 0, 0.5);
}

.popup__content-wrap {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 90%;
  /* height: 100%; */
  height: unset;
  max-height: min(90%, 700px);
  min-width: 300px;
  max-width: 1200px;

  padding: ac(50px, 20px);
  border-radius: 20px;

  background: var(--cl-black);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);

  @mixin media 769 {
    height: auto;

    padding-top: 40px;
  }
}

.popup__content {
  width: 100%;
  height: 100%;
}

.popup__close-btn {
  position: absolute;
  top: 0;
  right: 20px;
  z-index: 1;
  transform: translateY(-50%);

  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border: none;
  border-radius: 999px;

  color: var(--cl-white);
  background: linear-gradient(-264.82deg, #16171a 0%, #2d3135 100%);

  transition: all 0.3s ease;

  &:hover {
    color: var(--cl-red);

    &::before,
    &::after {
      background: var(--cl-red);
    }
  }

  @mixin media 769 {
    right: 10px;

    width: 30px;
    height: 30px;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 15px;
    height: 2px;

    transition: all 0.3s ease;
  }

  &::before {
    background: var(--cl-white);
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    background: var(--cl-white);
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.popup__close-icon {
  display: none;
  width: 15px;
  height: 15px;

  @mixin media 769 {
    width: 10px;
    height: 10px;
  }
}

@keyframes popupSlide {
  from {
    transform: translateY(var(--pp-default-translate-y-from))
      translateX(var(--pp-default-translate-x-from));
    opacity: var(--pp-opacity-from);
  }

  to {
    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    opacity: var(--pp-opacity-to);
  }
}

@keyframes opacityPopupBg {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes gradientPopupBtn {
  0% {
    transform: translateX(-100%) skew(45deg);
  }

  100% {
    transform: translateX(100%) skew(45deg);
  }
}

.credentials-popup {
  height: 100%;
  overflow: auto;

  text-align: justify;

  h2 {
    font-size: 22px;
    font-weight: 700;
    text-align: center;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  h3 {
    font-size: 20px;
    font-weight: 700;

    &:not(:first-child) {
      margin-top: 20px;
    }
  }

  a {
    color: var(--cl-green);

    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  ul > li > ul > li {
    padding-left: 20px;
  }
}

#public-offer-popup,
#privacy-policy-popup {
  .popup__content-wrap {
    background: #fff;
    color: #000;

    font-family: Tinos, serif;
  }

  .popup__content-wrap {
    aspect-ratio: 21/9;

    @mixin media 769 {
      aspect-ratio: unset;
      width: 95%;
      height: 100%;
    }
  }

  a {
    color: blue;
  }
}

.right-text {
  text-align: right;
}
