.how-join-section__bg {
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;

  mask-image: linear-gradient(
    to right,
    transparent,
    #fff 3%,
    #fff,
    #fff 97%,
    transparent
  );

  @mixin media 1441 {
    top: 55%;
  }

  @mixin media 769 {
    min-width: 1700px;
    opacity: 0.6;
    transform: translate(-32%, -56%);
  }
}

.how-join-section__title {
  margin-bottom: 54px;

  text-align: center;
}

.how-join {
  display: grid;
  gap: 100px;
  grid-template-areas:
    'frst frst frst thrd thrd thrd fth fth fth'
    'scnd scnd scnd scnd scnd frth frth frth frth';

  @mixin media 1025 {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: unset;
    gap: 24px;
  }

  .how-join-card {
    &:nth-child(1) {
      grid-area: frst;

      padding-top: 180px;
      height: ac(850px, 750px, 1280);

      @mixin media 1025 {
        padding-top: 0;
        height: unset;
        grid-area: unset;
      }
    }
    &:nth-child(2) {
      grid-area: scnd;
      place-content: center;
      padding-top: 130px;
      margin-top: ac(-150px, -100px);

      .how-join-card__num-wrap {
        position: relative;

        &::before {
          position: absolute;
          bottom: calc(100% + 20px);
          height: 100%;

          background: linear-gradient(
            180deg,
            rgba(255, 73, 75, 0) 0%,
            #ff494b 100%
          );
        }

        &::after {
          position: absolute;
          bottom: calc(100% + 20px);
        }
      }

      @mixin media 1025 {
        margin-top: unset;
        padding-top: unset;
        grid-area: unset;

        .how-join-card__num-wrap {
          &::before,
          &::after {
            position: static;
          }
          &::before {
            background: linear-gradient(
              0deg,
              rgba(255, 73, 75, 0) 0%,
              #ff494b 100%
            );
          }
        }
      }
    }
    &:nth-child(3) {
      grid-area: thrd;
      padding-top: 90px;

      height: 400px;

      @mixin media 1025 {
        padding-top: 0;
        height: unset;
        grid-area: unset;
      }
    }
    &:nth-child(4) {
      grid-area: frth;
      place-content: center;
      margin-top: ac(-150px, -100px, 1280);

      .how-join-card__num-wrap {
        position: relative;

        &::before {
          position: absolute;
          bottom: calc(100% + 20px);
          height: 100%;

          background: linear-gradient(
            180deg,
            rgba(255, 73, 75, 0) 0%,
            #ff494b 100%
          );
        }

        &::after {
          position: absolute;
          bottom: calc(100% + 20px);
        }
      }

      @mixin media 1025 {
        margin-top: unset;
        grid-area: unset;

        .how-join-card__num-wrap {
          &::before,
          &::after {
            position: static;
          }
          &::before {
            background: linear-gradient(
              0deg,
              rgba(255, 73, 75, 0) 0%,
              #ff494b 100%
            );
          }
        }
      }
    }
    &:nth-child(5) {
      grid-area: fth;
      height: 460px;

      @mixin media 1025 {
        height: unset;
        grid-area: unset;
      }
    }

    @mixin media 1025 {
      place-content: center;
    }
  }
}

.how-join-card {
  display: flex;
  gap: 12px;

  /* min-height: 700px; */
}

.how-join-card__num-wrap {
  display: flex;
  align-items: center;
  flex-direction: column;

  color: #ff494b;

  font-size: 26px;
  line-height: 1.2;
  font-weight: 500;

  &::before {
    content: '';
    /* position: absolute; */
    /* top: 100%; */
    display: block;
    width: 2px;
    height: 10px;
    flex: 1;
    order: 1;

    background: linear-gradient(0deg, rgba(255, 73, 75, 0) 0%, #ff494b 100%);

    @mixin media 769 {
      width: 1px;
    }
  }
  &::after {
    content: '';
    /* position: absolute; */
    /* top: 100%; */
    display: block;
    width: 10px;
    height: 10px;
    background: #ff494b;
    border-radius: 999px;
  }
}

.how-join-card__num {
  margin-bottom: 16px;
}

.how-join-card__title {
  margin-bottom: 8px;

  font-size: 26px;
  font-weight: 600;
  line-height: 1.2;
  text-transform: uppercase;
}

.how-join-card__text {
  margin-bottom: 20px;

  color: rgba(255, 255, 255, 0.7);

  font-size: 16px;
  line-height: 1.5;

  strong {
    color: #ffffff;

    font-weight: 500;
  }
}

.how-join-card__link-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.how-join-card__link {
  display: flex;
  gap: 8px;
  align-items: center;

  width: fit-content;

  padding: 12px 20px;
  border-radius: 999px;

  color: #ffffff;

  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;

  background: #ffb80033;
  border: 1px solid #ffb800;

  transition: background 0.3s;

  &:hover {
    background: #ffb800;
  }

  &::after {
    content: '';

    display: block;

    width: 24px;
    height: 24px;

    background: url('../../assets/images/arrow-top-right.svg');
  }

  &.red {
    background: #ff494b33;
    border: 1px solid #ff494b;

    &:hover {
      background: #ff494b;
    }
  }

  &.grey {
    background: #26282ecc;
    border: 1px solid #444752;

    &:hover {
      background: #444752;
    }
  }
}

.how-join-card__info-wrap {
  max-width: 354px;
  /* max-width: 447px; */
}
