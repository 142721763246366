.section-thank-you {
  height: 100%;

  .container {
    height: 100%;
  }
}

.thank-you {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  height: 100%;
}

.thank-you__title {
  font-family: var(--font-title);
  font-size: ac(64px, 24px);
  font-weight: 700;
}

.thank-you__text {
  font-size: ac(22px, 16px);
}
