.profit-hero-section {
  padding-top: ac(155px, 80px);
}

.profit-hero-section__decor {
  position: absolute;
  left: 0;

  opacity: 0.6;
  z-index: -1;

  @mixin media 1025 {
    top: -30%;
  }

  @mixin media 551 {
    top: -25%;

    width: 140%;
  }

  @mixin min-media 1921 {
    top: -70%;
  }
}

.profit-hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ac(20px, 10px);

  margin-bottom: 30px;

  @mixin media 1025 {
    flex-direction: column;

    margin-bottom: 0;
  }
}

.profit-hero__left-block {
  max-width: ac(654px, 400px, 1440);

  @mixin media 1025 {
    max-width: unset;
  }
}

.profit-hero__logo {
  max-width: ac(380px, 270px, 1440);

  margin-bottom: ac(30px, 20px);

  @mixin media 1025 {
    max-width: ac(225px, 118px, 550, 1025);
    margin-inline: auto;
  }

  @mixin media 550 {
    margin-inline: unset;
  }
}

.profit-hero__text {
  margin-bottom: ac(35px, 20px);

  color: rgba(255, 255, 255, 0.7);

  font-size: ac(16px, 14px);
  text-align: justify;

  @mixin media 769 {
    margin-bottom: 0;
  }
}

.profit-hero__right-block {
  display: flex;
  gap: ac(85px, 41px, 1280);

  @mixin media 1025 {
    width: 100%;
  }
}

.profit-hero__stats {
  display: flex;
  flex-direction: column;
  gap: ac(20px, 8px);

  padding-top: ac(43px, 19px);

  @mixin media 1025 {
    width: 100%;
    align-items: end;
  }
}

.profit-stat {
  position: relative;
  display: flex;
  gap: ac(21px, 6px, 1024);
  justify-content: space-between;
  overflow: hidden;

  background: radial-gradient(
    264.92% 100% at 50% 0%,
    rgba(255, 255, 255, 0.105) 0%,
    rgba(255, 255, 255, 0.045) 29.88%
  );
  border-radius: ac(30px, 12px);
  backdrop-filter: blur(75px);
  -webkit-backdrop-filter: blur(75px) !important;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  max-width: 387px;
  padding: ac(19px, 7px) ac(13px, 9px) ac(22px, 10px) ac(19px, 7px);

  &::before {
    content: '';
    position: absolute;
    top: 45%;
    left: 0;
    width: 37px;
    height: 37px;
    filter: blur(31px);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    border-radius: 999px;
    background: rgba(42, 248, 149, 0.8);
    /* transform: translateX(-50%); */
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    filter: blur(50px);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    border-radius: 999px;
    background: rgba(42, 248, 149, 0.8);
    /* transform: translateX(50%); */
  }

  &:nth-child(1) {
    left: ac(-150px, -100px, 1280);

    @mixin media 1025 {
      left: 0;
    }
  }

  &:nth-child(2) {
    @mixin media 1025 {
      left: 28px;
    }
  }

  &:nth-child(3) {
    left: ac(150px, 100px, 1280);

    @mixin media 1025 {
      left: 56px;
    }
  }

  &[data-aiv] {
    opacity: 0;

    .profit-stat__chart {
      scale: 1 0;
      transform-origin: bottom;

      transition: scale 0.5s 0.25s;
    }

    .profit-stat__info {
      opacity: 0;
      transform: translateY(20px);
      transition: opacity 0.5s, transform 0.5s;
    }

    &.in-view {
      opacity: 1;
      /* transform: translateY(0px); */

      .profit-stat__chart {
        scale: 1 1;
      }

      .profit-stat__info {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

.profit-stat__info {
  max-width: 209px;

  @mixin media 1025 {
    max-width: ac(200px, 100px, 400, 1025);
  }
}

.profit-stat__user-info {
  display: flex;
  align-items: center;
  gap: 8px;

  margin-bottom: ac(13px, 5px);
}

.profit-stat__user-name {
  font-weight: 500;

  font-size: ac(14px, 10px);
}

.profit-stat__user-position {
  opacity: 0.5;

  font-size: ac(12px, 5px);
}

.profit-stat__result {
  font-size: ac(30px, 16px);
  font-weight: 700;
  line-height: 1.218;
  color: rgba(42, 248, 149, 1);
}

.profit-stat__text {
  font-size: ac(12px, 5px);
}

.profit-stat__user-image {
  display: flex;
  align-items: center;
  justify-content: center;

  width: ac(36px, 20px);
  height: ac(36px, 20px);

  background: rgba(72, 72, 72, 1);
  border-radius: 999px;

  padding: 4px;
}

.profit-stat__chart {
  align-self: end;

  @mixin media 1025 {
    max-width: ac(70px, 44px, 400, 1025);
  }
}

.profit-hero__phone-wrap {
  max-width: ac(400px, 300px);

  @mixin media 550 {
    max-width: 200px;
  }
}

.profit-hero__btn {
  &.mob {
    display: none;

    margin-top: 20px;

    @mixin media 769 {
      display: flex;
    }
  }

  &.desktop {
    @mixin media 769 {
      display: none;
    }
  }
}
