.live-trading-hero {
  display: flex;

  /* height: 100vh; */
  /* min-height: 800px; */
  /* max-height: 900px; */

  padding-top: ac(144px, 89px);

  @mixin media 769 {
    .container {
      min-height: 100%;
    }

    min-height: 700px;
    max-height: 850px;

    padding-bottom: 30px;
  }
}

.live-trading-hero__btn-start {
  @mixin media 769 {
    margin-top: 30px;

    .btn__text {
      font-size: 20px;
    }
  }
}

.live-trading-hero__bg-wrap {
  position: absolute;
  top: ac(144px, 100px);
  left: ac(0px, -200px, 1281);
  z-index: -1;

  max-width: ac(1332px, 1000px);
  max-height: 100%;

  mask-image: linear-gradient(
    to bottom,
    transparent,
    black 5%,
    black 95%,
    transparent
  );

  @mixin media 769 {
    top: 90px;
    left: -150px;
  }

  @mixin media 551 {
    width: 164%;
  }
}

.live-trading-hero__content {
  display: flex;
  flex-direction: column;
  align-items: end;
  height: 100%;

  @mixin media 769 {
    height: 100%;
  }
}

.live-trading-hero__title {
  max-width: ac(1048px, 600px);

  margin-bottom: 30px;

  font-family: var(--font-title);
  font-size: ac(96px, 39px, 551);
  font-weight: 400;
  text-align: right;
  line-height: 0.95;
  letter-spacing: 0.04rem;
  text-wrap: balance;
}

.scalping-effectivity-section__main-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-bottom: 30px;
}

.scalping-effectivity-section__video {
  overflow: hidden;

  border: 1px solid #444752;
  border-radius: 12px;
}

.live-trading-hero__info-block {
  margin-bottom: ac(24px, 12px);

  @mixin media 769 {
    margin-top: auto;
  }

  @mixin media 501 {
    margin: auto auto 12px;
  }
}

.live-trading-hero__btn-wrap {
  display: flex;
  align-items: center;

  border: none;

  background: transparent;

  &:hover {
    .live-trading-hero__btn-play {
      transform: scale(1.1);
      filter: drop-shadow(12px 8px 80px #7a269c);
    }
  }

  @mixin media 501 {
    margin: 0 auto;
  }

  @mixin media 450 {
    width: 100%;
  }
}

.live-trading-hero__btn-play {
  position: relative;
  z-index: 1;

  width: ac(176px, 100px);
  height: ac(176px, 100px);

  flex-shrink: 0;

  margin-right: ac(-93px, -60px);

  transition: 0.3s ease;
}

.live-trading-hero__btn-text-wrap {
  display: flex;
  flex-direction: column;

  padding: ac(18px, 11px) ac(79px, 20px) ac(18px, 11px) ac(128px, 70px);
  border-radius: ac(12px, 8px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: #050506;

  @mixin media 450 {
    width: 100%;
  }
}

.live-trading-hero__btn-text {
  color: var(--cl-white);

  font-size: ac(22px, 14px);
  font-weight: 600;
  text-align-last: left;
  text-transform: uppercase;

  &.highlight {
    color: #00ffcb;
  }
}
