.project-section {
  @mixin media 551 {
    .container {
      width: 92%;
    }
  }
}

.projects__title {
  text-align: center;
}

.projects__card-list {
  display: grid;
  grid-template-columns: repeat(2, minmax(300px, 1fr));
  gap: ac(40px, 24px, 1280);

  margin-bottom: ac(50px, 20px);

  @mixin media 1024 {
    grid-template-columns: 1fr;
    margin-bottom: 0;
  }
}

.project-card {
  --bg-blur-color: #0a3c22;

  position: relative;

  padding: ac(30px, 16px) ac(50px, 16px);
  border: ac(2px, 1px) solid rgba(255, 255, 255, 0.3);
  border-radius: ac(24px, 12px);

  cursor: pointer;

  transition: 0.5s ease;

  background: #040404;

  background-size: contain;

  &:hover {
    border-color: rgba(46, 218, 127, 1);

    &::before {
      opacity: 1;
    }
  }

  &.red {
    --bg-blur-color: #520001;

    @mixin media 551 {
      background-size: 100%;
    }

    &::after {
      background: url('../../assets/images/project-red-bg.jpg') no-repeat top
        center;
    }

    &:hover {
      border-color: rgba(255, 82, 84, 1);
    }
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    /* z-index: -1; */

    border-radius: 24px;

    background: radial-gradient(
      76.81% 28.66% at 50% 100%,
      var(--bg-blur-color) 0%,
      #040404 100%
    );

    opacity: 0;
    transition: 0.5s ease;
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 1;

    border-radius: 24px;

    background: url('../../assets/images/project-green-bg.jpg') no-repeat top
      center;
  }
}

.project-card__top-wrap {
  position: relative;
  z-index: 2;

  display: flex;
  justify-content: space-between;
  gap: ac(20px, 17px, 1280);

  margin-bottom: ac(30px, 12px, 1024);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.project-card__top-left {
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding-bottom: 10px;
}

.project-card__logo {
  position: relative;
  z-index: 2;

  max-width: ac(231px, 97px);
  aspect-ratio: 176 / 68;
}

.project-card__title {
  font-family: var(--font-title);
  font-size: ac(64px, 30px, 1024);
  font-weight: 400;
  line-height: 1;
}

.project-card__top-right {
  padding-right: ac(50px, 10px, 1280);

  @mixin media 551 {
    max-width: 114px;
  }
}

.project-card__info {
  position: relative;
  z-index: 2;

  margin-bottom: ac(30px, 18px, 1024);

  color: rgba(255, 255, 255, 0.7);

  font-size: ac(16px, 14px);
  line-height: 1.219;
  text-align: justify;
}

.project-card__more-btn {
  position: relative;
  z-index: 2;

  margin-top: auto;
}
