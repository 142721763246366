.fp-watermark {
  display: none;
}

html {
  scrollbar-width: thin;
  scrollbar-color: var(--cl-red) transparent;
  scroll-behavior: smooth;
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;
  background: #08090a;

  color: var(--cl-white);
  font-family: var(--font-main);

  overflow-x: hidden;

  min-height: calc(100 * var(--vh));
  display: flex;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--cl-light-grey);
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--cl-red);
    border-radius: 0;
  }

  &.no-scroll {
    overflow: hidden;
  }
}

.swiper-wrapper {
  left: 0 !important;
}

#site {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  width: 100%;
}

.main {
  flex-grow: 1;
}

.section {
  position: relative;

  padding-top: ac(155px, 15px);
  padding-bottom: ac(150px, 100px);
}

.section__title {
  margin-bottom: 30px;

  font-family: var(--font-title);
  font-size: ac(100px, 48px);
  line-height: 1;
  letter-spacing: 0.04rem;

  &.center {
    text-align: center;
  }

  @mixin media 769 {
    text-align: center;
  }
}

.container {
  margin: 0 auto;
  max-width: 1700px;
  width: perc(1700);

  @mixin media 769 {
    width: perc(343, 375);
  }
}

.logo {
  transition: 0.5s ease;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:hover {
    transform: scale(1.06);
  }
}

#site.cookie-terms-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  .main {
    flex-grow: 1;
    padding: 0;
  }
}

.cookie-terms-section {
  padding: ac(250px, 140px) 0 ac(150px, 50px);
  p {
    padding: 0;
    margin-bottom: 10px;
  }
  ul,
  ol {
    padding-left: 20px;
    li {
      p {
        margin-bottom: 0;
      }
    }
  }

  ul {
    list-style: circle;
  }

  ol {
    list-style: decimal;
  }

  a {
    display: inline;
    text-decoration: underline;
    transition: 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }
}

.single-page-404 {
  min-height: 100vh;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1,
  p {
    margin-bottom: 15px;
    padding: 0;
  }

  a {
    display: inline-block;
    margin-top: 20px;
  }
}

.info-block {
  max-width: ac(550px, 350px);

  padding: ac(30px, 16px, 1280) ac(32px, 16px, 1280);
  border-radius: ac(12px, 8px);
  border: 1px solid rgba(255, 255, 255, 0.3);

  background: rgba(0, 0, 0, 0.5);

  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px) !important;

  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

.info-block__title {
  margin-bottom: 4px;

  font-family: var(--font-title);
  font-size: ac(64px, 36px);
  line-height: 1;
}

.info-block__descr {
  margin-bottom: ac(18px, 16px, 1440);

  font-size: ac(18px, 14px);
  line-height: 1.222;
}

.info-block__list {
  padding-left: 2ch;
  padding-top: 13px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);

  list-style-type: disc;

  color: rgba(255, 255, 255, 0.7);
}

.info-block__list-item {
  font-size: ac(16px, 12px);
  line-height: 1.22;
}

.form__title {
  margin-bottom: 8px;

  font-family: var(--font-title);
  font-size: ac(72px, 48px);
  line-height: 1;
  text-align: center;
}

.form__description {
  max-width: 800px;

  margin: 0 auto ac(60px, 24px);

  color: rgba(255, 255, 255, 0.7);

  font-size: ac(16px, 15px);
  line-height: 1.219;
  text-align: center;
  text-wrap: balance;
}

.form__fields {
  display: grid;
  gap: ac(16px, 12px);

  &:last-of-type:not(:last-child) {
    margin-bottom: ac(60px, 32px);
  }
}

.form__row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  gap: ac(16px, 12px);

  @mixin media 769 {
    grid-template-columns: 1fr;
  }
}

/* .pswp__img {
  width: fit-content !important;
  height: fit-content !important;
} */

[data-plyr] {
  --plyr-video-control-background-hover: var(--cl-red);
  --plyr-range-fill-background: var(--cl-red);
  max-width: 100%;
  max-height: 100%;
}

.plyr.plyr--stopped .plyr__controls {
  display: none;
}

#call-me-popup {
  .popup__content-wrap {
    padding-left: 12px;
    padding-right: 12px;
  }
  .form__description {
    color: var(--cl-white);

    font-size: 18px;
    font-weight: 500;
  }
}

.contact-fixed {
  position: fixed;
  z-index: 2;
  right: 40px;
  bottom: 20px;

  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  gap: 10px;

  @mixin media 769 {
    right: 15px;
    bottom: 15px;

    align-items: center;
  }
}

.telegram-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 92px;
  height: 92px;

  background: #111111;
  border: 1px solid #039be5;
  border-radius: 999px;

  transition: all ease 0.3s;

  @mixin media 769 {
    width: 56px;
    height: 56px;
  }

  &:hover {
    background: #039be5;

    .telegram-btn__icon {
      filter: brightness(0) invert(1);

      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
    }
  }
}

.telegram-btn__icon {
  width: 50px;

  transition: all ease 0.3s;

  @mixin media 769 {
    width: 28px;
    height: 28px;
  }
}

.call-me {
  display: flex;
  align-items: center;

  width: 120px;

  padding: 27px;
  border-radius: 999px;

  transition: all ease 0.3s 0.3s;

  @mixin media 769 {
    width: fit-content;

    padding: 10px;
  }

  &.active {
    width: 592px;

    background: #08090a;

    transition: all ease 0.3s;

    .call-me__btn-close {
      opacity: 1;

      transition: all ease 0.3s 0.3s;
    }

    .call-me__form {
      opacity: 1;

      transition: all ease 0.3s 0.3s;
    }

    .call-me__submit-btn {
      opacity: 1;

      transition: all ease 0.3s 0.3s;
    }
  }

  &.en {
    textPath {
      font-size: 11.5px;
    }
  }

  &.ru {
    textPath {
      font-size: 8.4px;
    }
  }
}

.call-me__btn {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  width: 92px;
  height: 92px;

  border: 1px solid rgba(255, 255, 255, 1);
  background: #111111;

  border-radius: 50%;

  transition: all ease 0.3s;

  &:hover {
    background: #25282b;
  }

  @mixin media 769 {
    width: 56px;
    height: 56px;
  }
}

.call-me__btn-icon {
  width: 56px;
  height: 56px;

  @mixin media 769 {
    width: 28px;
    height: 28px;
  }
}

.call-me__btn-text {
  position: absolute;
  left: 50%;
  top: 50%;
  /* transform: translate(-50%, -50%); */

  width: 124px;
  height: 124px;

  animation: rotate 30s linear infinite;

  @mixin media 769 {
    width: 80px;
    height: 80px;
  }

  path {
    fill: transparent;
  }

  textPath {
    font-family: Inter;
    font-size: 9.9px;
    text-transform: uppercase;
    fill: rgba(255, 255, 255, 0.3);
  }
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.call-me__btn-close {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 56px;
  height: 56px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;

  background: #25282b;

  opacity: 0;
  transition: all ease 0.3s;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40%;
    height: 2px;
    background: #fff;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.call-me__form {
  margin-left: 44px;

  font-size: 16px;

  opacity: 0;

  transition: all ease 0.3s;

  @mixin media 769 {
    display: none;
  }

  .iti .iti__selected-dial-code {
    font-size: 16px;
  }
}

.circle-btn.call-me__submit-btn {
  width: 92px;
  height: 92px;

  flex-shrink: 0;

  margin-left: 29px;

  opacity: 0;

  @mixin media 769 {
    display: none;
  }
}

.call-me__form-descr {
  margin-bottom: 12px;

  height: 2rem;
}

.call-me__form-input {
  width: 100%;
  max-width: 278px;

  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 6px;

  color: #ffffff;
  background: transparent;

  &:not(:last-child) {
    margin-bottom: 6px;
  }
}

[data-aiv^='fade'] {
  opacity: 0 !important;

  transition: all ease 400ms;

  &[data-aiv$='top'] {
    translate: 0 50% !important;
  }
  &[data-aiv$='bottom'] {
    translate: 0 -50% !important;
  }
  &[data-aiv$='left'] {
    translate: 50% 0 !important;
  }
  &[data-aiv$='right'] {
    translate: -50% 0 !important;
  }

  &.in-view {
    opacity: 1 !important;

    &[data-aiv$='top'] {
      translate: 0 0 !important;
    }
    &[data-aiv$='bottom'] {
      translate: 0 0 !important;
    }
    &[data-aiv$='left'] {
      translate: 0 0 !important;
    }
    &[data-aiv$='right'] {
      translate: 0 0 !important;
    }
  }
}
